
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
    components: {
    },
    props: {
        tmpBlock: {
            type: Object
        },
    },
    emits: ["submit"],
    data() {
        return {
            label: '',
            placeholder: '',
            submitted: false,
        };
    },
    watch: {
        tmpBlock: {
            handler: function (val) {
                if (val) {
                    this.label = '';
                    this.placeholder = '';
                }
            },
            deep: true,
        },
    },
    computed: {
        type: function () {
            if (!this.tmpBlock ||
                !this.tmpBlock.typeConfig ||
                !this.tmpBlock.typeConfig.FORM ||
                !this.tmpBlock.typeConfig.FORM.getParams ||
                !this.tmpBlock.typeConfig.FORM.getParams.type) {
                return '';
            }

            if (this.tmpBlock.typeConfig.FORM.getParams.type === 'input') {
                return 'Single Text Field';
            } else if (this.tmpBlock.typeConfig.FORM.getParams.type === 'picture-upload-field') {
                return 'Image Upload Field';
            } else if (this.tmpBlock.typeConfig.FORM.getParams.type === 'file-upload-field') {
                return 'File Upload Field';
            } else if (this.tmpBlock.typeConfig.FORM.getParams.type === 'rich-editor') {
                return 'Long Text field';
            }
            return '';
        },
        showPlaceholder: function () {
            if (!this.tmpBlock ||
                !this.tmpBlock.typeConfig ||
                !this.tmpBlock.typeConfig.FORM ||
                !this.tmpBlock.typeConfig.FORM.getParams ||
                !this.tmpBlock.typeConfig.FORM.getParams.type) {
                return false;
            }

            if (this.tmpBlock.typeConfig.FORM.getParams.type === 'input') {
                return true;
            } else if (this.tmpBlock.typeConfig.FORM.getParams.type === 'picture-upload-field') {
                return false;
            } else if (this.tmpBlock.typeConfig.FORM.getParams.type === 'file-upload-field') {
                return false;
            } else if (this.tmpBlock.typeConfig.FORM.getParams.type === 'rich-editor') {
                return true;
            }
            return '';
        },
    },
    methods: {
        submit: async function () {
            this.submitted = true;

            this.$emit('submit', {
                type: 'task',
                taskType: this.tmpBlock.type,
                weight: this.tmpBlock.weight,
                typeConfig: {
                    FORM: {
                        formId: this.tmpBlock.typeConfig.FORM.formId,
                        getParams: {
                            type: this.tmpBlock.typeConfig.FORM.getParams.type,
                            label: this.label,
                            placeholder: this.placeholder,
                        },
                    }
                }
            });

            this.submitted = false;
            const el = this.$refs["closeBtn"] as any;
            el.click();
        },
    },
});
