
//import router from "@/router";
import { store } from "@/store";
import { show as showApi } from "@smace-technologies/project/src/template";
import { useRoute } from "vue-router";

import { defineComponent } from "vue";
import ArrowIcon from "@/views/components/ArrowIcon.vue";

export default defineComponent({
  components: { ArrowIcon },
  props: {},
  data() {
    return {
      templateId: "",
      name: "",
    };
  },
  watch: {},
  computed: {
    menuItems: function (): any {
      return [
        {
          label: "General",
          to: `/settings/project-template/${this.templateId}`,
        },
        {
          label: "Jobs",
          to: `/settings/project-template/${this.templateId}/jobs`,
        },
      ];
    },
  },
  methods: {
    load: async function () {
      const route = useRoute();
      this.templateId = route.params.templateId as string;

      const res = await showApi(
        store.getters["getBaseWorkspaceId"],
        this.templateId,
        store.getters["getJWT"]
      );
      this.name = res.name;
    },
  },
  mounted() {
    this.load();
  },
});
