

import AddVariable from "@/views/components/VariableSelection/AddVariable.vue";
import EditVariable from "@/views/components/VariableSelection/EditVariable.vue";
import RemoveVariable from "@/views/components/VariableSelection/RemoveVariable.vue";
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
    components: {
        AddVariable,
        EditVariable,
        RemoveVariable
    },
    data() {
        return {
            selectedVar: {},
            selecteVarName: '',
            selecteVarId: '',
            createVariable: false,
            updateVariable: false,
            deleteVariable: false,
        };
    },
    computed: {
        variables: function () {
            return store.getters["process/getVariables"];
        },
    },
});
