
import { store } from "@/store";
import { defineComponent } from "vue";
import ClientView from "./ClientView.vue";
import ProjectView from "./ProjectView.vue";
import PlaceholderView from "./PlaceholderView.vue";
import VariablesView from "./VariablesView.vue";
import DatesView from "./DatesView.vue";
import { show as getProcessApi } from '@smace-technologies/process/src/process';
import ProcessView from "./ProcessView.vue";

export default defineComponent({
    props: {
        resetTrigger: {
            type: String,
            default: "",
        },
    },
    components: {
        PlaceholderView,
        VariablesView,
        ClientView,
        ProjectView,
        ProcessView,
        DatesView,
    },
    data() {
        return {
            step: 1,
            client: "",
            name: "",
            submit: false,
            processTemplateId: "",

            // 
            clientData: {} as any,
            projectData: {} as any,
            processData: {} as any,
            datesData: {} as any,
            rolesData: {} as any,
            placeholders: [] as any[],
            variables: {} as any,
            placeholderMatching: {} as any[]
        };
    },
    watch: {
        step: function () {
            this.$emit('update:step', this.step);
        },
        resetTrigger: function () {
            this.step = 1;
            this.clientData = {};
            this.projectData = {};
            this.processData = {};
            this.datesData = {};
            this.rolesData = {};
            this.$emit('update:step', this.step);
        }
    },
    computed: {
        canContinue: function (): boolean {
            return this.clientData?.clientId && this.projectData?.projectId && this.processData?.processTemplateId && this.datesData?.startDate && this.datesData?.endDate;
        },
        hasVariables: function (): boolean {
            return Object.keys(this.variables).length > 0;
        }
    },
    methods: {
        submitCall() {
            this.$emit('submit', {
                clientData: this.clientData,
                projectData: this.projectData,
                processData: this.processData,
                datesData: this.datesData,
                rolesData: this.rolesData,
                placeholderMatching: this.placeholderMatching,
                variables: this.variables
            });
        },
        loadPlaceholder: async function () {
            this.placeholders = [];
            let processId = this.processData.processTemplateId;
            const p1 = await getProcessApi(store.getters.getBaseWorkspaceId, 'TEMPLATE', processId, store.getters.getJWT);
            this.placeholders = p1.metaData?.placeholders || [];
        },
        loadVariables: async function () {
            this.variables = [];
            let processId = this.processData.processTemplateId;
            const p1 = await getProcessApi(store.getters.getBaseWorkspaceId, 'TEMPLATE', processId, store.getters.getJWT);
            this.variables = p1.metaData?.variables || {};
        }
    },
});
