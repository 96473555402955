
import { defineComponent } from "vue";
import { getAll as getMilestonesApi } from "@smace-technologies/process/src/step";
import { store } from "@/store";

export default defineComponent({
  components: {},
  emits: ["added"],
  data() {
    return {
      submitted: false,
      selectedStepId: "",
      selectedProcessId: "",
      selectedSubProcessId: "",
      milestones: [],
      name: "",
      aiPrompt: "",
      showAiWaitingPrompt: false,
    };
  },
  computed: {
    subprocesses: function () {
      return store.getters["settingsProcesses/getSubTypes"]
        .filter((item: any) => item.processId !== this.selectedProcessId)
        .filter((item: any) => item.typeId === this.selectedProcessId);
    },
    processes: function () {
      return store.getters["settingsProcesses/getTypes"];
    },
  },
  watch: {
    selectedProcessId: function (v: any) {
      if (v) {
        this.loadMilestones();
      }
    }
  },
  methods: {
    loadMilestones: async function () {
      this.milestones = await getMilestonesApi(
        store.getters["getBaseWorkspaceId"],
        this.selectedProcessId,
        store.getters["getJWT"]
      );
    },
    reset: function () {
      this.showAiWaitingPrompt = false;
      this.name = "";
      this.aiPrompt = "";
      this.selectedStepId = "";
      this.selectedProcessId = "";
      this.selectedSubProcessId = "";
      this.milestones = [];
    },
    submit: async function () {
      this.submitted = true;
      this.showAiWaitingPrompt = false;


      await store.dispatch("module/addModule", {
        title: this.name,
        stepId: this.selectedStepId,
        aiPrompt: this.aiPrompt,
        processId: this.selectedSubProcessId ? this.selectedSubProcessId : this.selectedProcessId,
        projectId: 'TEMPLATE',
        weight: -1
      });

      this.submitted = false;
      
      store.dispatch("settingsProcesses/load");

      if (!this.aiPrompt) {
        const el = this.$refs["closeBtn"] as any;
        el.click();
      } else {
        this.aiPrompt = "";
        this.showAiWaitingPrompt = true;
      }

      setTimeout(() => {
        this.reset();
      }, 5000);
    },
  },
});
