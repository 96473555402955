import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03a1f5a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "initials initials-row-view d-flex align-items-center justify-content-center m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersWithDefault, (user, i) => {
      return (_openBlock(), _createElementBlock("span", {
        class: "avatars-wrapper",
        key: i
      }, [
        (user.avatar)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: user.avatar,
              alt: "avatar",
              class: _normalizeClass({ 'small': _ctx.smallAvatarSize, 'middle': _ctx.middleAvatarSize })
            }, null, 10, _hoisted_2))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["initials-avatar-wrapper d-flex align-items-center justify-content-center", [{ 'is-placeholder': _ctx.isPlaceholder(user) }, { 'small': _ctx.smallAvatarSize }, { 'middle': _ctx.middleAvatarSize }]])
            }, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.getInitials(user)), 1)
            ], 2))
      ]))
    }), 128))
  ]))
}