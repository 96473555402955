
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        link: {
            type: String,
        }
    },
    data() {
        return {
            height: 650,
        }
    },
    emits: ['done'],
    methods: {
        addPreFillParameter(){
            //TODO
        },
        receiveMessage(event: any): void {
            if (!this.isHubSpot(event)) {
                return;
            }
            if (event.data.meetingBookSucceeded) {
                console.log('Mark Task as done');
                this.$emit("done");
            }
        },
        isHubSpot(e: any) {
            return e.origin.includes('hubspot') && e.origin.includes('https://meetings-');
        }
    },
    mounted() {
        window.addEventListener("message", this.receiveMessage, false);
    },
    beforeUnmount() {
        window.removeEventListener("message", this.receiveMessage);
    },
});
