
import { PropType, defineComponent } from 'vue';
import { store } from '@/store';

export default defineComponent({
    props: {
        content: {
            type: Array as PropType<any[]>,
            required: true
        },
        blockId: {
            type: String,
            required: true
        },
        moduleId: {
            type: String,
            required: true
        },
        isReadOnly: {
            type: Boolean,
        }
    },
    data() {
        return {
            adobeApiReady: false,
            previewFilePromise: null
        }
    },
    emits: ['update:content'],
    watch: {
        adobeApiReady: {
            handler () {
                this.renderPdf()
            }
        }
    },
    computed: {
        computedContent: {
            get(): any {
                return this.content;
            },
            set(v: any) {
                this.$emit('update:content', v)
            },
        }
    },
    methods: {
        renderPdf() {
            if (!this.adobeApiReady) {
                return
            }

            const previewConfig = {
                defaultViewMode: 'FIT_PAGE',
                showAnnotationTools: false,
                embedMode: 'SIZED_CONTAINER',
            }

            const pdfContainer = this.$refs.pdfContainer as HTMLElement;
            pdfContainer.innerHTML = "";

            let viewer = document.createElement("div");
            viewer.id = this.computedContent[0].handle;
            
            pdfContainer.appendChild(viewer);
            
            let adobeDCView = new (window as any).AdobeDC.View({
                clientId: process.env.VUE_APP_ADOBE_EMBED_API_KEY,
                divId: this.computedContent[0].handle,
            });
            this.previewFilePromise = adobeDCView.previewFile({
                content: {
                    location: {
                        url: this.computedContent[0].url,
                    }
                },
                metaData: {
                    fileName: this.computedContent[0].filename,
                    id: this.computedContent[0].handle
                },
            }, previewConfig)
        }
    },
    mounted() {
        if ((window as any).AdobeDC) {
            this.adobeApiReady = true
        } else {
            document.addEventListener('adobe_dc_view_sdk.ready', () => {
                this.adobeApiReady = true
            })
        }
    },
});
