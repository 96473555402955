
import { defineComponent } from "vue";
import { store } from "@/store";
import { update as updateApi } from "@smace-technologies/workspace/src/workspace";
import { uploadFile } from "@smace-technologies/workspace/src/workspace";

export default defineComponent({
  components: {},
  props: {},
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      errorMsg: "",
      org: {
        name: "",
        config: {
          displayName: "",
          images: {} as any,
          texts: {} as any,
          style: {} as any,
        },
      },
    };
  },
  computed: {
    workspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
    workspaceConfig: function () {
      return store.getters["getBaseWorkspaceConfig"];
    },
  },
  watch: {
    workspaceConfig: {
      handler() {
        this.org.config = JSON.parse(
          JSON.stringify(this.workspaceConfig)
        ) as any;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    clearStyles: function () {
      this.org.config.style.headerBackgroundColor = "";
      this.org.config.images.logo = "";
      this.org.config.images.headerBackground = "";
      this.submit();
    },
    handleFileUpload: async function (event: any, type: string) {
      this.loading = true;
      this.errorMsg = "";
      let formData = new FormData();
      formData.append("file", event.target.files[0]);

      const data = await uploadFile(
        store.getters["getBaseWorkspaceId"],
        type,
        formData,
        store.getters["getJWT"]
      ).catch((error: any) => {
        this.errorMsg = "Images must be less than 4 MB in size";
      });

      this.loading = false;
      this.org.config.images[type] = data;
    },
    submit: async function () {
      this.submitted = true;
      await updateApi(
        store.getters["getBaseWorkspaceId"],
        {
          config: {
            ...this.org.config,
            style:{},
          },
        } as any,
        store.getters["getJWT"]
      );
      this.submitted = false;
      store.dispatch("reloadBaseWorkspace");
    },
  },
});
