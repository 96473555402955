
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
    emits: ["submitted"],
    data() {
        return {
            submitted: false,
        };
    },
    computed: {
        module() {
            return store.getters['processModules/getSelectedModule'];
        },
    },
    methods: {
        submit: async function () {
            this.submitted = true;
            setTimeout(() => {
                this.$emit("submitted");
                this.submitted = false;
                const el = this.$refs["closeBtn"] as any;
                el.click();
            }, 200);
        },
    },
});
