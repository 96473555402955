import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/user-icon.svg'


const _withScopeId = n => (_pushScopeId("data-v-13d89a32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block block-task" }
const _hoisted_2 = { class: "position-relative" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["for"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "task-cards-wrapper d-flex justify-content-between align-items-center" }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "task-card border me-2 d-flex" }
const _hoisted_10 = {
  key: 0,
  class: "task-card deadline-card d-flex me-2"
}
const _hoisted_11 = { class: "d-flex align-items-center" }
const _hoisted_12 = {
  key: 1,
  src: _imports_0,
  alt: "user-icon",
  width: "24",
  height: "24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoAndName = _resolveComponent("LogoAndName")!
  const _component_user_selection = _resolveComponent("user-selection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("input", {
          type: "checkbox",
          id: `checkbox${_ctx.blockId}`,
          class: "checkbox",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDone && _ctx.toggleDone(...args)))
        }, null, 40, _hoisted_3),
        _createElementVNode("label", {
          for: `checkbox${_ctx.blockId}`,
          class: "custom-checkbox cursor-pointer"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", {
        class: "inline-headline-edit h6 cursor-pointer mb-1",
        innerHTML: _ctx.computedtHeadline,
        "data-bs-toggle": "offcanvas",
        "data-bs-target": "#taskSidebar",
        placeholder: "Task headline"
      }, null, 8, _hoisted_5)
    ]),
    _createElementVNode("div", {
      innerHTML: _ctx.computedtDescription,
      "data-bs-toggle": "offcanvas",
      class: "description-text cursor-pointer mb-3",
      "data-bs-target": "#taskSidebar"
    }, null, 8, _hoisted_6),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", {
          class: _normalizeClass(`task-card status-card d-flex me-2 ${_ctx.content.status}`)
        }, _toDisplayString(_ctx.content.status), 3),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.content.taskType), 1),
        (_ctx.content.dueDate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.format(_ctx.content.dueDate)), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_user_selection, {
          selectedUsers: _ctx.computedAccountablePerson,
          "onUpdate:selectedUsers": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedAccountablePerson) = $event)),
          label: "Select Accountable User",
          exactlyOne: true
        }, {
          default: _withCtx(() => [
            (_ctx.computedAccountablePerson[0])
              ? (_openBlock(), _createBlock(_component_LogoAndName, {
                  key: 0,
                  user: _ctx.computedAccountablePerson[0],
                  rowView: true,
                  middleAvatarSize: true
                }, null, 8, ["user"]))
              : _createCommentVNode("", true),
            (!_ctx.computedAccountablePerson[0])
              ? (_openBlock(), _createElementBlock("img", _hoisted_12))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["selectedUsers"])
      ])
    ])
  ]))
}