
import { store } from '@/store';
import { debounce } from 'lodash';
import { defineComponent } from 'vue';
import draggable from 'vuedraggable';
import FilestackAddImageModal from '@/views/pages/settings/media/modals/FilestackAddImageModal.vue';
import TextBlock from './blocks/TextBlock.vue';
import ImageBlock from './blocks/ImageBlock.vue';
import TaskLinkBlock from './blocks/TaskLinkBlock.vue';
import DeleteBlockModal from '../modals/DeleteBlockModal.vue';
import CalendarModal from "./AddMenu/CalendarModal.vue"
import SingleFieldFormModal from "./AddMenu/SingleFieldFormModal.vue"
import AddFormModal from "./AddMenu/AddFormModal.vue";
import AddMenu from "./AddMenu/AddMenu.vue";
import VideoBlock from './blocks/VideoBlock.vue';
import VideoUrlModal from './AddMenu/VideoUrlModal.vue';
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import PDFBlock from '@/views/pages/module/components/blocks/PDFBlock.vue';

export default defineComponent({
    components: {
        draggable,
        TextBlock,
        TaskLinkBlock,
        DeleteBlockModal,
        CalendarModal,
        AddMenu,
        AddFormModal,
        SingleFieldFormModal,
        ImageBlock,
        FilestackAddImageModal,
        VideoBlock,
        VideoUrlModal,
        PDFBlock,
        LoadingSpinner,
    },
    data() {
        return {
            drag: false,
            selectedBlock: {} as any,
            tmpBlock: {} as any,
            showActions: [],
            timeoutIds: {} as any,
            refreshTimestamp: 0,
            loaded: false,
            delay: 0,
            apiKey: 'AxwBZGrdSoGPZuATpnWLTz',
            setFilestackOpen: false,
            fileStackOptions: {
                fileType: '',
                accept: ['*'],
                allowConcurrent: 1,
            },
        }
    },
    computed: {
        isTemplate: () => {
            return store.getters["process/getProject"].projectId === 'TEMPLATE';
        },
        showDebugHelpers: function () {
            return Object.prototype.hasOwnProperty.call(this.$route.query, 'showDebugHelpers');
        },
        moduleLoaded: function () {
            return !!store.getters["module/getModule"].moduleId;
        },
        computedBlocks: {
            get() {
                return store.getters['moduleBlocks/getBlocks'];
            },
            set(blocks: any) {
                store.dispatch('moduleBlocks/updateAllBlockWeights', blocks);
            }
        }
    },
    watch: {
        computedBlocks: function (v: any) {
            if (!this.loaded && v.length && this.$route.hash) {
                const el = document.getElementById(this.$route.hash.replace('#', ''));
                if (el) {
                    setTimeout(() => {
                        el.scrollIntoView();
                    }, 350);
                    el.scrollIntoView();
                }
                this.loaded = true;
            }
        }
    },
    methods: {
        reRender: function () {
            this.refreshTimestamp = Date.now();
        },
        typeComponent: function (type: string) {
            switch (type) {
                case 'text':
                    return 'TextBlock';
                case 'taskLink':
                    return 'TaskLinkBlock';
                case 'image':
                    return 'ImageBlock';
                case 'video':
                    return 'VideoBlock';
                case 'pdf':
                    return 'PDFBlock';
            }
        },
        addBlock: function (type: string, weight?: number, content?: any) {
            let newWeight = (typeof weight !== 'undefined' && weight !== null) ? weight + 1 : this.computedBlocks.length + 1;

            if (this.computedBlocks.length === weight + 1 && !content && type === 'task') {
                // If the block is added to the end of the list, the last block which is empty should stay at the end
                newWeight = weight + 0.1;
            }

            if (this.computedBlocks.length === weight + 1 && type === 'task') {
                // If the block is added to the end of the list, the last block which is empty should stay at the end
                newWeight = weight + 0.1;
            }

            store.dispatch('moduleBlocks/addBlock', { ...content, type, weight: newWeight });
        },
        openSelectTaskTypeModal: function (data: any, weight: number) {
            store.dispatch('task/setShouldCreateReference', true);
            const el = this.$refs["openSelectTaskTypeModal"] as any;
            el.click();
        },

        updateBlockFromSidebar: function (block: any) {
            this.selectedBlock = block;
            store.dispatch('moduleBlocks/silentUpdateBlock', {
                ...block,
                content: block.content
            });
            this.updateBlock(block, block.content);
        },
        updateBlock: function (block: any, content: any) {
            this.debounceStore(() => {
                store.dispatch('moduleBlocks/updateBlock', {
                    ...block,
                    content
                });
            });
        },
        closeAction: function (id: any) {
            this.timeoutIds[id] = setTimeout(() => {
                this.showActions[id] = false;
            }, this.delay);
        },
        showAction: function (id: any) {
            clearTimeout(this.timeoutIds[id]);
            this.showActions[id] = true;
        },
        debounceStore: debounce(function (fnc) {
            fnc();
        }, 750),
        handleFilestackOpen(event: any) {
            this.fileStackOptions = {
                fileType: event.fileType,
                accept: event.fileType === 'pdf' ? ['application/pdf'] : ['image/*'],
                allowConcurrent: event.allowConcurrent
            }
            this.setFilestackOpen = true;
        },
        onFilestackError(error: any) {
            console.log(error);
        },
        onFilestackSuccess(event: any) {
            this.addBlock(this.fileStackOptions.fileType, this.tmpBlock.weight, { content: event.filesUploaded });
        },
    },
    mounted() {
        store.dispatch("forms/load");
    },
});

