
  import { defineComponent } from "vue";
  import LogoAndName from '@/views/components/LogoAndName.vue';
  import UserSelection from "@/views/components/UserSelection/UserSelection.vue";
  import { store } from '@/store';
  
  export default defineComponent({
    components: {
        LogoAndName,
        UserSelection,
    },
    props: {
      content: {
        type: Object,
      },
    },
    computed: {
      computedFollowers: {
        get(): string[] {
          return this.content.followers || []
        },
        set(v: any) {
          store.dispatch('task/updateTask', {
            moduleId: this.content.moduleId,
            taskId: this.content.taskId,
            payload: {
              ...this.content,
              followers: v,
            }
          });
        },
      }
    },
  })
  
  