
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    config: {
      type: Object
    }
  },
  emits: ["update:config"],
  data() {
    return {
      selectedNumber: 0,
      selectedPeriodType: "",
      periodTypes: [
        "day(s)",
      ],
      projectState: {
        afterModuleActivation: "after module start",
      },
      selectProjectState: "",
      setDeadlineDate: false,
    }
  },
  computed: {
    isDeadlineSelected() {
      return this.selectedNumber && this.selectedPeriodType;
    },
    getSelectedNumber() {
      return Math.abs(this.selectedNumber);
    }
  },
  watch: {
    config: {
      handler(newVal) {
        if (!newVal || !newVal.relative || !newVal.relative.inDays) {
          this.selectedNumber = 0;
          this.selectedPeriodType = "";
          this.setDeadlineDate = false;

          return;
        }
        this.selectedNumber = newVal.relative.inDays;
        this.selectedPeriodType = "day(s)";
        this.selectProjectState = this.projectState.afterModuleActivation;
      },
      immediate: true
    },
  },
  methods: {
    setDeadline: function (index: any) {
      this.selectedNumber = index;
      this.$emit("update:config", {
        relative: {
          inDays: Math.abs(this.selectedNumber),
        }
      });
      this.setDeadlineDate = false;
    },
    setInitialDeadline: function () {
      this.selectedNumber = this.selectedNumber ? this.selectedNumber : 1;
      this.selectedPeriodType = "day(s)";
      this.setDeadlineDate = true;
      this.selectProjectState = this.projectState.afterModuleActivation
    },
    resetDeadline: function () {
      this.selectedNumber = 0;
      this.selectedPeriodType = "";
      this.setDeadlineDate = false;
      this.$emit("update:config", {});
    }
  }
});
