
import { defineComponent } from "vue";
import { store } from "@/store";
import dateFormat from "dateformat";
import DeleteUserModal from "./DeleteUserModal.vue";
import { getAll as getAllApi } from "@smace-technologies/workspace/src/permission";

export default defineComponent({
  components: {
    DeleteUserModal,
  },
  props: {
    updateTrigger: {
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      selectedEmail: "",
      items: [] as any,
    };
  },
  computed: {
    workspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
  },
  watch: {
    workspaceId: function () {
      this.load();
    },
    updateTrigger: function () {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy HH:MM");
    },
    load: async function () {
      this.loading = true;
      const res = await getAllApi(
        store.getters["getBaseWorkspaceId"],
        store.getters["getJWT"]
      );

      this.items = res;
      this.loading = false;
    },
  },
});
