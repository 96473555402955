
//@ts-disable
import { store } from "@/store";
import { defineComponent } from "vue";
import dateFormat from "dateformat";
import EmailCustomization from "./modal/EmailCustomization.vue";
import HelpTooltip from "@/views/components/HelpTooltip.vue";

export default defineComponent({
    components: {
        EmailCustomization,
        HelpTooltip
    },
    data: () => {
        return {
            loading: false,
            selectedItemId: "",
            selectedProcessName: "",
            selectedEmailType: "",
            selectedEmailTypeName: "",
        };
    },
    computed: {
        welcomeTemplates: function () {
            return store.getters["settingsProcesses/getTypes"];
        },
    },
    async mounted() {
        this.load();
    },
    methods: {
        load: async function () {
            await store.dispatch("settingsProcesses/load");
        },
    },
});
