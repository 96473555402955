import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73adfe12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dates-view" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "pe-1" }
const _hoisted_4 = { class: "ps-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_date = _resolveComponent("select-date")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_select_date, {
          initialDate: _ctx.startDate,
          onSelectDate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startDate = $event)),
          hideInputIcon: _ctx.hideInputIcon
        }, null, 8, ["initialDate", "hideInputIcon"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_select_date, {
          initialDate: _ctx.endDate,
          onSelectDate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.endDate = $event)),
          hideInputIcon: _ctx.hideInputIcon
        }, null, 8, ["initialDate", "hideInputIcon"])
      ])
    ])
  ]))
}