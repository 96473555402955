import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8bb2854c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block block-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WYSIWYGEditor = _resolveComponent("WYSIWYGEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WYSIWYGEditor, {
      content: _ctx.computedContent,
      "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedContent) = $event)),
      placeholder: "Write another summary, add a task or schedule a meeting."
    }, null, 8, ["content"])
  ]))
}