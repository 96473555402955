
import { defineComponent } from "vue";
import { store } from "@/store";
import AddVariable from "./AddVariable.vue";
import VariableAccordion from "./VariableAccordion.vue";

export default defineComponent({
    components: {
        AddVariable,
        VariableAccordion
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        selectedVariables: {
            type: Array,
            required: true,
        },
        isExecution: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            mouseOver: false,
            displayType: 'Menu',
            id: Math.floor(Math.random() * 30000),
            dropdownState: null as any,
            dropdownOpened: false,
        };
    },
    emits: ['update:selectedVariables'],
    computed: {
        availableVariables: function () {
            return Object.keys(store.getters['process/getVariables']) || [];
        },
    },
    mounted() {
        this.dropdownState = document.getElementById(`dropdownStep${this.id}`);
        if (this.dropdownState) {
            this.dropdownState.addEventListener("show.bs.dropdown", () => {
                document.getElementById("routerView").style.overflow = 'hidden';
                this.dropdownOpened = true;
            });
            this.dropdownState.addEventListener("hide.bs.dropdown", () => {
                document.getElementById("routerView").style.overflow = 'auto';
                this.dropdownOpened = false;
            });
        }
    },
    methods: {
        deselectAll: function () {
            this.$emit('update:selectedVariables', [])
        },
        close: function () {
            const el = this.$refs["toggleBtn"] as any;
            el.click();
        },
        /////
        remove: function (variableId: string) {
            this.$emit('update:selectedVariables', this.selectedVariables.filter((u: any) => u !== variableId));
        },
        addVariable: function (variableId: any) {
            if (!this.selectedVariables.includes(variableId)) {
                this.$emit('update:selectedVariables', [...this.selectedVariables, variableId]);
            }
        },
    },
});
