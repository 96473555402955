import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b28cca1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "job" }
const _hoisted_2 = { class: "mb-4 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_module_card = _resolveComponent("module-card")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      false
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "add-module-btn position-relative cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addModule', {})))
          }, " Add module "))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_draggable, {
      modelValue: _ctx.computedModules,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedModules) = $event)),
      group: "modules",
      emptyInsertThreshold: 100,
      "item-key": "moduleId",
      onStart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.drag = true)),
      onEnd: _cache[3] || (_cache[3] = ($event: any) => (_ctx.drag = false))
    }, {
      item: _withCtx(({ element }) => [
        _createVNode(_component_module_card, {
          model: element,
          onClick: ($event: any) => (_ctx.$emit('selectedModule', element))
        }, null, 8, ["model", "onClick"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}