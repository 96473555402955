
import { defineComponent } from 'vue';
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import History from '@tiptap/extension-history'
import Heading from '@tiptap/extension-heading'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-3'

export default defineComponent({
    components: {
        EditorContent,
        BubbleMenu,
    },
    props: {
        content: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['update:content'],
    data() {
        return {
            editor: null
        }
    },
    methods: {
        preventEnter: function (event: any) {
            //TODO CREATE A NE BLOCK IN BETWEEN 
            //NEW BLOCK SHOULD ALWAYS BE SELECTED
            
            /*const selection = this.editor.state.selection;
            const avoidPrevention = selection.$head &&
                ['list_item'].includes(selection.$head.parent.type.name);
        
            if (event.key === 'Enter' && !avoidPrevention) {
                event.preventDefault();
                return false;
            }*/
        },
    },
    mounted() {
        this.editor = new Editor({
            extensions: [
                Text,
                Bold,
                Italic,
                Document,
                Paragraph,
                History,
                ListItem,
                BulletList,
                Link,
                Strike,
                Heading.configure({
                    levels: [1, 2, 3],
                }),
                Placeholder.configure({
                    placeholder: this.placeholder
                })
            ],
            editorProps: {
                handleDrop: () => true, // Removing default behavior for drop event
            },
            content: this.content || " ",
            onUpdate: () => {
                this.$emit('update:content', this.editor.getHTML())
            },
            editable: !this.readonly,
        });
    },
});

