
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    icon: {
      type: String,
      default: 'more-horizontal'
    },
    size: {
      default: '18'
    },
    id: {
      required: true,
    },
    links: {
      type: Array,
      required: true,
    }, 
    stroke: {
      type: String,
    },
  },
  data: () => {
    return {
      mouseOver: false,
    };
  },
  methods: {
    navigate: function(params: any){
      if(params){
        this.$router.push(params);
      }
    },
  },
});
