
import { defineComponent } from "vue";
import { store } from "@/store";
import TextBlock from "../components/blocks/TextBlock.vue";
import TaskBlock from "../components/blocks/TaskBlock.vue";
import TaskLink from "../components/blocks/TaskLinkBlock.vue";

export default defineComponent({
  components: {
    TextBlock,
    TaskBlock,
    TaskLink
  },
  props: {
    block: {
      type: Object
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
    };
  },
  computed: {},
  methods: {
    typeComponent: function (type: string) {
      switch (type) {
        case 'text':
          return 'TextBlock';
        case 'task':
          return 'TaskBlock';
        case 'taskLink':
          return 'TaskLink';
      }
    },
    submit: async function () {
      this.submitted = true;
      store.dispatch('moduleBlocks/deleteBlock', this.block.blockId);
      this.$emit("reload");
      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
});
