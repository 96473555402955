import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d43f339"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  id: "moduleDoneWithTasks",
  tabindex: "-1",
  "data-bs-backdrop": "true",
  "aria-labelledby": "moduleDoneWithTasks",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = {
  key: 0,
  class: "modal-content"
}
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = {
  type: "button",
  class: "btn-close",
  "data-bs-dismiss": "modal",
  "aria-label": "Close",
  ref: "closeBtn"
}
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_9 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_10 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.module)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.module.status === 'DONE' ? 'Incomplete' : 'Complete') + " this module", 1),
              _createElementVNode("button", _hoisted_6, null, 512)
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_ctx.module.status !== 'DONE')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Do you really want to mark this \"" + _toDisplayString(_ctx.module.title) + "\" module with all its tasks as completed? ", 1))
                : _createCommentVNode("", true),
              (_ctx.module.status === 'DONE')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Do you really want to mark this \"" + _toDisplayString(_ctx.module.title) + "\" module as incompleted again? ", 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_ctx.module.status !== 'DONE')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-success ms-2",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
                    onDisabled: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submitted && _ctx.submitted(...args)))
                  }, _toDisplayString(_ctx.submitted ? "Updating" : "Complete"), 33))
                : _createCommentVNode("", true),
              (_ctx.module.status === 'DONE')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    class: "btn btn-danger ms-2",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
                    onDisabled: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submitted && _ctx.submitted(...args)))
                  }, _toDisplayString(_ctx.submitted ? "Updating" : "Incomplete"), 33))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}