
import { defineComponent } from "vue";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import CreationWizard from "@/views/pages/projects/index/component/creation/CreationWizard.vue";
import { createProcess } from "./createService";

export default defineComponent({
  components: { LoadingSpinner, CreationWizard },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      name: "",
      client: "",
      error: "",
      step: 1,
      resetTrigger: "",
      percentage: 0,
    };
  },
  computed: {
  },
  watch: {},
  mounted() {
    this.name = "";
  },
  methods: {
    reset: function () {
      this.resetTrigger = Math.random().toString();
    },
    submit: async function (data: any) {
      this.submitted = true;
      let res: any;

      try {
        res = await createProcess(data, (per: any) => { this.percentage = per; });
      } catch (e: any) {
        console.log("e", e, e.response);
        this.error = e;
      }

      const el = this.$refs["closeBtn"] as any;
      el.click();

      return this.$router.push({
        name: "process-show",
        params: {
          processId: res.process.processId,
          projectId: res.project.projectId
        },
      });

    },
  },
});
