import { RouteRecordRaw } from "vue-router";
import ProjectView from '../views/pages/projects/index/IndexView.vue'
import ProjectView2 from '../views/pages/projects/index/IndexView2.vue'
import ProjectShow from '../views/pages/projects/show/ShowProject.vue'
import FormBuilder from '../views/pages/projects/show/FormBuilderView.vue'
import ProcessView from '../views/pages/settings/processes/show/ProcessView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/project/',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'projects',
                alias: '/',
                component: ProjectView2,
                meta: {
                    requiresAuth: true,
                }
            },
          /*  {
                path: '',
                name: 'projects2',
                alias: '/2',
                component: ProjectView,
                meta: {
                    requiresAuth: true,
                }
            },*/
            {
                path: ':projectId',
                name: 'project-show',
                component: ProjectShow,
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: ':projectId/:processId',
                name: 'process-show',
                component: ProcessView,
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: 'task-form/:projectId/:jobId/:taskId',
                name: 'task-form-step',
                component: FormBuilder,
                meta: {
                    requiresAuth: true,
                    subNavType: ''
                }
            }
        ]
    },
];

export default routes;