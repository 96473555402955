import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import {
    getAll as getAllRolesApi,
    store as storeApi,
    update as updateApi,
    remove as removeApi,
} from '@smace-technologies/workspace/src/role';
import { Role } from '@smace-technologies/workspace/types/role';

export const key: InjectionKey<Store<TState>> = Symbol();

type TRolesState = {
    roles: Role[];
};

const initialState = (): TRolesState => ({
    roles: [] as Role[],
});

export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getRoles: (state: TRolesState): Role[] => {
            return state.roles;
        }
    },
    actions: {
        async load(context: ActionContext<TRolesState, TState>) {
            const roles = await getAllRolesApi(store.getters.getBaseWorkspaceId, store.getters.getJWT);
            context.commit('SET_ROLES', roles);
        },
        async add(context: ActionContext<TRolesState, TState>, data: { name: string, internExtern: 'INTERN' | 'EXTERN' }): Promise<Role> {
            const role = await storeApi(store.getters.getBaseWorkspaceId, { name: data.name, internExtern: data.internExtern }, store.getters.getJWT);
            context.commit('ADD_ROLE', role);

            return role;
        },
        async update(context: ActionContext<TRolesState, TState>, data: { roleId: string, name: string, internExtern: 'INTERN' | 'EXTERN', defaultEmailAddress: string }): Promise<void> {
            context.commit('RENAME_ROLE', data);
            await updateApi(store.getters.getBaseWorkspaceId, data.roleId, { name: data.name, internExtern: data.internExtern, defaultEmailAddress: data.defaultEmailAddress }, store.getters.getJWT)
                .catch(() => context.dispatch('load'));
        },
        async delete(context: ActionContext<TRolesState, TState>, roleId: string) {
            context.commit('DELETE_ROLE', roleId);
            await removeApi(store.getters.getBaseWorkspaceId, roleId, store.getters.getJWT)
                .catch(() => context.dispatch('load'));
        },
    },
    mutations: {
        SET_ROLES(state: TRolesState, roles: Role[]) {
            state.roles = roles;
        },
        ADD_ROLE(state: TRolesState, role: Role) {
            state.roles.push(role);
        },
        RENAME_ROLE(state: TRolesState, data: { name: string, roleId: string }) {
            state.roles = state.roles.map((f: Role) => {
                if (f.roleId === data.roleId) {
                    f.name = data.name;
                }
                return f;
            });
        },
        DELETE_ROLE(state: TRolesState, roleId: string) {
            state.roles = state.roles.filter((t: Role) => t.roleId !== roleId);
        },
    },
}
