
import { store } from '@/store';
import ContextMenu from '@/views/components/ContextMenu.vue';
import DeleteModuleModal from "./modals/DeleteModuleModal.vue";
import CloneModuleModal from "./modals/CloneModuleModal.vue";
import ModuleDoneWithTasksModal from "./modals/ModuleDoneWithTasksModal.vue";
import { debounce } from "lodash";
import dateFormat from "dateformat";
import { defineComponent } from 'vue';
import TaskSidebar from './components/sidebars/TaskSidebar.vue';
import WYSIWYGEditor from './components/WYSIWYGEditor.vue';
import AvatarsRow from '@/views/components/AvatarsRow.vue';
import UserSelection from '@/views/components/UserSelection/UserSelection.vue';
import FormEditorModal from "@/views/pages/settings/forms/show/FormEditorModal.vue";
import SelectTaskTypeModal from './components/sidebars/components/SelectTaskTypeModal.vue';

export default defineComponent({
    components: {
        WYSIWYGEditor,
        UserSelection,
        TaskSidebar,
        ContextMenu,
        DeleteModuleModal,
        CloneModuleModal,
        FormEditorModal,
        AvatarsRow,
        ModuleDoneWithTasksModal,
        SelectTaskTypeModal,
    },
    data() {
        return {
            processId: null,
            renderKey: 0,
        }
    },
    computed: {
        computedParticipants: {
            get() {
                return store.getters["module/getModule"].participants || [];
            },
            set(v: any) {
                store.dispatch("module/updateModule", {
                    ...this.module,
                    participants: v,
                });
            },
        },
        module: function () {
            return store.getters["module/getModule"];
        },
        milestoneName: function () {
            return store.getters["module/getMilestoneName"];
        },
        processName: function () {
            return store.getters["process/getProcess"].name;
        },
        getClientName: function () {
            return store.getters["client/getClient"].name;
        },
        isTemplate: function (): boolean {
            return store.getters["process/getProject"].projectId === 'TEMPLATE';
        },
        getTaskStats: function () {
            return store.getters["tasks/getTaskStats"];
        },
        getProject: function () {
            return store.getters["process/getProject"];
        },
        minDuration: function () {
            const minDuration = store.getters["moduleBlocks/getTaskStats"]?.minDuration;
            if (!minDuration) return;
            return dateFormat(minDuration, "dd.mmm");
        },
        userList: function () {
            return store.getters['moduleBlocks/getBlocks']
                .filter((b: any) => b.type === 'task')
                .filter((b: any) => b.content.assignee)
                .map((b: any) => b.content.assignee)
        },
        headline: function () {
            return store.getters["module/getModule"].title || '';
        },
    },
    watch: {
        headline: {
            handler: function (v) {
                this.resizeHeadline();
            },
            immediate: true,
        },
        module: {
            handler: function () {
                this.debounceStore(() => {
                    this.resizeHeadline();
                    this.updateModule();
                });
            },
            deep: true,
        },
    },
    methods: {
        handleModalHidden() {
            this.renderKey++;
        },
        toggleModuleDone() {
            store.dispatch("module/changeModuleStatus", this.module);
        },
        resizeHeadline: function () {
            const el = this.$refs["inlineHeadline"] as any;
            if (!el) {
                return;
            }

            const offset = el.offsetHeight - el.clientHeight;
            el.style.height = 'auto';
            el.style.height = el.scrollHeight + offset + 'px';
        },
        goToProject: function () {
            if (this.isTemplate) {
                this.$router.push({ name: 'settings-processes' });
            }
        },
        goToProcess: function () {
            if (this.isTemplate) {
                this.$router.push({ name: 'settings-process', params: { typeId: this.module.processId } });
            } else {
                this.$router.push({ name: 'process-show', params: { projectId: this.getProject.projectId, processId: this.module.processId } });
            }
        },
        updateModule: function () {
            store.dispatch("module/updateModule", this.module);
        },
        debounceStore: debounce(function (fnc) {
            fnc();
        }, 750),
    },
    mounted() {
        this.processId = this.$route.params.processId;
        this.debounceStore(() => this.resizeHeadline());

        store.dispatch('module/load', {
            moduleId: this.$route.params.moduleId,
            processId: this.$route.params.processId,
        }).then(() => {
            this.debounceStore(() => this.resizeHeadline());
        });
    }
});
