
//@ts-disable
import { store } from "@/store";
import { defineComponent } from "vue";
import dateFormat from "dateformat";
import AddProjectModal from "./modals/AddProjectModal.vue";
import UpdateProjectModal from "./modals/UpdateProjectModal.vue";
import DeleteProjectModal from "./modals/DeleteProjectModal.vue";
import DeleteProcessModal from "./modals/DeleteProcessModal.vue";
import DeleteClientModal from "./modals/DeleteClientModal.vue";
//import ContextMenu from "@/views/components/ContextMenu.vue";
import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net';
import Select from 'datatables.net-select';

DataTable.use(DataTablesLib);
DataTable.use(Select);

export default defineComponent({
  components: {
    AddProjectModal,
    UpdateProjectModal,
    DeleteProjectModal,
    DeleteProcessModal,
    DeleteClientModal,
    //ContextMenu,
    DataTable
  },
  data: () => {
    return {
      options: {
        select: true,
        pageLength: 100,
        language: {
          search: "",
          searchPlaceholder: "Search"
        }
      },
      loading: false,
      selectedProjectId: "",
      selectedProcessId: "",
      selectedClientId: "",
      selectedModuleId: "",
      columns: [
        {},
        {},
        {},
        {},
        {},
        { render: function (data: any) { return `<a class=' small' data-bs-toggle='modal' data-bs-target='#deleteProcessModal'><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 vue-feather__content"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></a>` } }
      ]
    };
  },
  watch: {
    baseWorkspaceId: function () {
      this.load();
    },
  },
  computed: {
    data: function () {
      const result = [] as any[];

      this.clients.forEach((client: any) => {
        this.projects
          .filter((project: any) => project.clientId === client.clientId)
          .forEach((project: any) => {
            const processes = this.processes
              .filter((process: any) => process.projectId === project.projectId);
              
            processes.forEach((process: any) => result.push({
              client: client,
              project: project,
              process: process,
            })
            );

            const singleModules = this.singleModules.filter((module: any) => module.projectId === project.projectId);
            singleModules.forEach((module: any) => result.push({
              client: client,
              project: project,
              singleModule: module,
            })
            )
          });
      });



      return result;
    },
    tableData: function () {
      return this.data.map((item: any) => {
        return [
          item.client.name,
          item.project.name,
          item.process ? `${item.process.name} (Process)` : `${item.singleModule.title} (Module)`,
          item.process && item.process.stats && item.process.stats.donePercentage ? `${item.process.stats.donePercentage.toFixed()}%` : '',
          item.process ? this.format(item.process.createdAt) : this.format(item.singleModule.createdAt),
          'Actions'
        ];
      });

    },
    baseWorkspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
    projects: function () {
      return store.getters["projects/getProjects"].sort((a: any, b: any) => b.createdAt - a.createdAt);
    },
    clients: function () {
      return store.getters["clients/getClients"];
    },
    processes: function () {
      return store.getters["processes/getProcesses"];
    },
    singleModules: function () {
      return store.getters["processes/getSingleModules"];
    },
  },
  async mounted() {
    this.load();
    const table = this.$refs.table as any;

    table.dt.on('select', (e: any, dt: any) => {
      const item = this.data[dt[0][0]];
      this.selectedProcessId = item.process ? item.process.processId : null;
      this.selectedModuleId = item.singleModule ? item.singleModule.moduleId : null;
      this.selectedProjectId = item.project.projectId;
      this.selectedClientId = item.client.clientId;
    });

    table.dt.on('click', (e: any,) => {
      if (e.target.tagName === 'TH') {
        return;
      }

      setTimeout(() => {
        if (!['A', 'svg', 'line'].includes(e.target.tagName)) {
          this.goToProcess(this.selectedProjectId, this.selectedProcessId)
        }
      }, 10);
    });


  },
  methods: {
    getClientName: function (project: any) {
      if (!project.clientId) {
        return;
      }
      const c = this.clients.find((client: any) => client.clientId === project.clientId);
      if (c) {
        return c.name;
      }
    },
    goToProject: function (projectId: any) {
      this.$router.push({
        name: "project-show",
        params: { projectId: projectId },
      });
    },
    goToProcess: function (projectId: string, processId: string) {
      this.$router.push({
        name: "process-show",
        params: { projectId, processId },
      });
    },
    setSelectedProject: function (project: any) {
      this.selectedProjectId = project.projectId;
    },
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy HH:MM");
    },
    load: async function () {
      await store.dispatch("projects/load");
      await store.dispatch("processes/load");
      await store.dispatch("clients/load");
    },
  },
});

//////

function findClosestTableRowIndex(element: HTMLElement): number {
  let closestTr: HTMLTableRowElement | null = null;
  let index = -1;

  while (element && element.tagName !== 'TR') {
    element = element.parentNode as HTMLElement;
  }

  if (element.tagName === 'TR') {
    closestTr = element as HTMLTableRowElement;
  } else {
    // If the clicked element is not a <tr>, let's find the nearest <tr> in its ancestor tree
    const closestTrAncestor = element.closest('tr');
    if (closestTrAncestor) {
      closestTr = closestTrAncestor;
    }
  }

  if (closestTr) {
    index = Array.from(closestTr.parentNode!.children).indexOf(closestTr);
  }

  return index;
}

