
import { store } from '@/store';
import { defineComponent } from 'vue';
import * as cheerio from 'cheerio';

export default defineComponent({
    props: {
        title: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            observer: null,
            block: false,
            highlightedHeadline: "",
        }
    },
    watch: {
        items: {
            handler: function () {
                this.createObservers();
            },
            immediate: true,
        }
    },
    computed: {
        show: function () {
            return this.items.length >= 1;
        },
        blocks: function () {
            return store.getters['moduleBlocks/getBlocks'];
        },
        items: function () {
            const items = [] as any;

            this.blocks.forEach((block: any) => {
                if (block.type === 'text') {
                    if (block.content) {
                        const $ = cheerio.load(block.content);
                        $("h1, h2, h3, h4, h5, h6").map((_, element) => {
                            items.push({
                                blockId: block.blockId,
                                headline: $(element).text(),
                                type: element.name,
                                tasks: []
                            });
                        });
                    }
                } /*else if (block.type === 'task') {
                    const task = {
                        blockId: block.blockId,
                        headline: block.content.headline,
                        done: block.content.status === 'DONE',
                        type: 'task'
                    };
                    if (items.length) {
                        items[items.length - 1].tasks.push(task)
                    }
                }*/
            });
            return items;
        },
        parentTasks: function () {
            const items = [] as any;
            this.blocks
                .every((block: any) => {
                    if (block.type === 'text') {
                        if (block.content) {
                            const $ = cheerio.load(block.content);
                            if ($("h1, h2, h3, h4, h5, h6").length) {
                                return false;
                            }
                        }
                    } /*else if (block.type === 'task') {
                        items.push({
                            blockId: block.blockId,
                            headline: block.content.headline,
                            done: block.content.status === 'DONE',
                            type: 'task'
                        });

                        return true;
                    }*/
                    return true;
                });

            return items;
        },
    },
    methods: {
        increaseType: function (type: string) {
            return `h${Number(type.split('h')[1]) + 1}`;
        },
        scrollToTop: function () {
            const element = document.getElementById('module-show');
            element.scrollIntoView({ behavior: 'smooth' });
            this.highlightedHeadline = '';
        },
        scrollTo: function (item: { blockId: string, headline: string, type: string }) {
            const elements = document.getElementsByTagName(item.type);

            var firstMatching = null;
            for (var i = 0; i < elements.length; i++) {
                if (elements[i].textContent === item.headline) {
                    firstMatching = elements[i];
                    break;
                }
            }

            if (firstMatching) {
                this.block = true;
                firstMatching.scrollIntoView({ behavior: 'smooth' });
                this.highlightedHeadline = item.headline;
                setTimeout(() => {
                    this.block = false;
                }, 1240);

            }
        },
        elementObserved: function (els: any) {
            if (this.block) {
                return;
            }

            els.forEach((el: any) => {
                if (el.isIntersecting) {
                    this.highlightedHeadline = el.target.textContent;
                } else if (this.highlightedHeadline == el.target.textContent) {
                    this.highlightedHeadline = '';
                }
            })
        },
        createObservers: function () {
            if (this.observer) {
                this.observer.disconnect();
            }
            this.observer = new IntersectionObserver(this.elementObserved, {
                threshold: 1.0,
            });
            setTimeout(() => {
                const headlines = document.querySelectorAll('.blocks-wrapper h1, .blocks-wrapper h2, .blocks-wrapper h3');
                headlines.forEach((headline) => {
                    this.observer.observe(headline);
                });
            }, 250);
        },
    },
    mounted() {
        this.createObservers();
    },

});

