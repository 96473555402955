import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b9a60726"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  class: "description-wrapper",
  id: "description"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "actions-wrapper d-flex" }
const _hoisted_5 = { class: "actions d-flex align-items-center" }
const _hoisted_6 = { class: "module-name me-2 cursor-pointer" }
const _hoisted_7 = { class: "progress-line" }
const _hoisted_8 = { class: "progress" }
const _hoisted_9 = ["aria-valuenow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-wrapper", {
    'external': _ctx.model.internExtern === 'EXTERN',
  }])
  }, [
    _createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.model.content.headline), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: "description m-0",
        innerHTML: _ctx.model.content.description
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getModuleTitle(_ctx.model.moduleId)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          class: _normalizeClass(`progress-bar ${_ctx.status}`),
          role: "progressbar",
          "aria-label": "Segment one",
          style: { width: 100 + '%' },
          "aria-valuenow": _ctx.todoProgressLineSize,
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }, null, 10, _hoisted_9)
      ])
    ])
  ], 2))
}