
import { defineComponent } from "vue";
import { store } from "@/store";
import { remove as removeApi } from "@smace-technologies/project/src/type";
import { show as showApi } from "@smace-technologies/project/src/type";

export default defineComponent({
  components: {},
  props: {
    typeId: {
      type: String,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      formName: "",
      name: "",
    };
  },
  computed: {},
  watch: {
    typeId: function () {
      this.load();
    },
  },
  mounted() {
    this.name = "";
    this.load();
  },
  methods: {
    load: async function () {
      this.loading = true;
      const res = await showApi(
        store.getters["getBaseWorkspaceId"],
        this.typeId,
        store.getters["getJWT"]
      );

      this.formName = res.name;
      this.name = "";
      this.loading = false;
    },
    submit: async function () {
      this.submitted = true;
      await removeApi(
        store.getters["getBaseWorkspaceId"],
        this.typeId,
        store.getters["getJWT"]
      );
      this.submitted = false;
      this.name = "";
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$emit("reload");
    },
  },
});
