
//@ts-disable
import ArrowIcon from "@/views/components/ArrowIcon.vue";
import ContextMenu from "@/views/components/ContextMenu.vue";
import HelpTooltip from "@/views/components/HelpTooltip.vue";
import JobTaskElement from "./JobTaskElement.vue";
import { defineComponent } from "vue";
import draggable from "vuedraggable";
import { store } from "@/store";

export default defineComponent({
  components: {
    ArrowIcon,
    ContextMenu,
    JobTaskElement,
    draggable,
    HelpTooltip,
  },
  props: {
    jobs: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  emits: ["setSelectedTask", "update:jobs", "setSelectedJob"],
  data: () => {
    return {
      hoverJob: [] as any,
    };
  },
  computed: {
    computedJobs: {
      get() {
        return this.jobs;
      },
      set(val: any) {
        this.$emit("update:jobs", val);
      },
    },
  },
  methods: {
    goToFormBuilder: function (taskId: string) {
      this.$router.push({
        name: "task-form-step",
        params: {
          taskId: taskId,
          projectId: this.$route.params.projectId,
          stepId: "step1",
        },
      });
    },
    setSelectedTask: function (task: any) {
      this.$emit("setSelectedTask", task);
    },
  },
});
