
import { PropType, defineComponent } from 'vue';
import { store } from '@/store';

export default defineComponent({
    props: {
        content: {
            type: Array as PropType<any[] | undefined>,
            required: true
        },
        blockId: {
            type: String,
            required: true
        },
        moduleId: {
            type: String,
            required: true
        },
        isReadOnly: {
            type: Boolean,
        }
    },
    emits: ['update:content'],
    data() {
        return {
            editor: null,
            flexAmount: [],
            imagesLoaded: 0,
        }
    },
    computed: {
        computedContent: {
            get(): any {
                return this.content;
            },
            set(v: any) {
                this.$emit('update:content', v)
            },
        }
    },
    watch: {
        imagesLoaded() {
            if (this.$props.content && this.$props.content.length === this.imagesLoaded) {
                this.getAspectRatios();
            }
        }
    },
    methods: {
        handleLoad() {
            this.imagesLoaded++;
        },
        getAspectRatios() {
            this.$el.querySelectorAll('img').forEach((img: any) => {
                this.flexAmount.push(img.naturalWidth / img.naturalHeight);
            });
        },
    },
});
