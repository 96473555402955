
import { defineComponent } from "vue";
import { store } from "@/store";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  components: {},
  props: {
    tmpBlock: {
      type: Object
    },
  },
  emits: ['added', 'addBlock'],
  data() {
    return {
      submitted: false,
      name: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.name = '';
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      const res = await store.dispatch("forms/add", {
        name: this.name,
      });

      const content = {
        taskId: uuidv4(),
        moduleId: this.$route.params.moduleId.toString(),
        headline: `Fill ${this.name}`,
        status: 'TODO',
        taskType: 'form',
        typeConfig: {
          FORM: {
            formId: res.formId,
          }
        }
      };

      await store.dispatch('task/addTask', { moduleId: this.$route.params.moduleId, payload: content });
      if (this.tmpBlock) {
        this.$emit('addBlock', { weight: this.tmpBlock.weight, content: { taskId: store.getters['task/getSelectedTask'].taskId } });
      }

      this.submitted = false;
      this.name = "";
      const el = this.$refs["editBtn"] as any;
      el.click();
      const closeModal = this.$refs["closeBtn"] as any;
      closeModal.click();
    },
  },
});
