
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    text: {},
    color: {},
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  mounted() {
    //
  },
});
