
import { defineComponent } from 'vue';
import { store } from '@/store';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
    emits: ['selectTaskType'],
    data() {
        return {
            selectedTaskType: '0',
            selectedForm: '0',
            selectedSchedule: '0',
            scheduleLink: '',
            scheduleType: '',
            submitted: false,
            newTask: {
                taskId: '',
                headline: '',
                taskType: '',
                typeConfig: {},
                status: 'TODO',
            },
        }
    },
    computed: {
        forms(): any {
            return store.getters['forms/getForms'];
        },
        formName(): string {
            const form = store.getters['forms/getForms'].find((f: any) => f.formId === this.selectedForm);
            return form?.name || 'Form Task';
        },
    },
    methods: {
      resetState() {
        this.selectedTaskType = '0';
        this.selectedForm = '0';
        this.selectedSchedule = '0';
        this.scheduleLink = '';
        this.scheduleType = '';
        this.submitted = false;
      },
      createSimpleTask: function () {
        this.$emit('selectTaskType', { taskType: 'task' });
        this.resetState();
      },
      async submit() {
        this.submitted = true;
        this.newTask = {
          ...this.newTask,
          typeConfig: {
            CALENDAR: {
              type: this.scheduleType.toUpperCase(),
              link: this.scheduleLink,
            },
          },
        }
        this.$emit('selectTaskType', this.newTask);
        this.resetState();
      },
    },
    watch: {
      selectedTaskType(newVal) {
        if (newVal === 'simpleTask') {
          this.createSimpleTask();
        }
      },
      selectedForm(newVal) {
        if (newVal === 'addForm') {
          const el = this.$refs["openFormModal"] as any;
          el.click();
        }
        else if (newVal !== '0') {
          this.newTask = {
            ...this.newTask,
            taskId: uuidv4(),
            headline: this.formName,
            taskType: 'form',
            typeConfig: {
              FORM: {
                formId: this.selectedForm,
              }
            },
            status: 'TODO',
          }
          store.dispatch('task/addTask', { moduleId: this.$route.params.moduleId, payload: this.newTask });
        }
      },
      async selectedSchedule(newVal) {
        if (newVal === 'calendly') {
          this.newTask = {
            ...this.newTask,
            taskId: uuidv4(),
            taskType: 'schedule',
            headline: 'Calendly Meeting',
            typeConfig: {
              CALENDAR: {
                type: 'CALENDLY',
              },
            },
          };
          this.scheduleType = 'Calendly';
        } else if (newVal === 'hubspot') {
          this.newTask = {
            ...this.newTask,
            taskId: uuidv4(),
            taskType: 'schedule',
            headline: 'Hubspot Meeting',
            typeConfig: {
              CALENDAR: {
                type: 'HUBSPOT',
              },
            },
          };
          this.scheduleType = 'Hubspot';
        }
      }
    },
});
