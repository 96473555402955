import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import { byWorkspace, byWorkspaceForExternal } from "@smace-technologies/user/src/profilePermissions";
import { byClient } from "@smace-technologies/user/src/profilePermissions";
import { store as storeInternal, destroyByUserId as removeInternaApi } from "@smace-technologies/workspace/src/permission";
import { store as storeExternal, destroyByUserId as removeExternalApi } from "@smace-technologies/client/src/permission";
import { IPermissionTypes, PermissionStorePayload } from '@smace-technologies/workspace/types/permission';
import { Profile } from '@smace-technologies/user/types/profile';
import { TState } from '@/types/TState';
import router from '@/router';
import { AxiosError } from 'axios';

export const key: InjectionKey<Store<TUsersState>> = Symbol()

type TUsersState = {
    users: Profile[],
    userProfile: Profile,
    externalUsers: Profile[],
    clientId: string,
}

const initialState = (): TUsersState => ({
    users: [] as Profile[],
    userProfile: {} as Profile,
    externalUsers: [] as Profile[],
    clientId: "",
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getUsers: (state: TUsersState) => {
            return state.users;
        },
        getExternalUsers: (state: TUsersState) => {
            return state.externalUsers;
        },
        getUserProfileData: (state: TUsersState) => {
            return state.userProfile.data;
        },
        resolveUser: (state: TUsersState) => (userId = 'current') => {
            if (!userId) return;

            if (userId.startsWith('p::')) {
                return store.getters['process/getPlaceholders'].find((p: {placeholderId: string}) => p.placeholderId === userId);
            }

            const fromInternalPool = state.users.find((u: Profile) => u.userId === userId);
            if (fromInternalPool) {
                return fromInternalPool;
            }
            const fromExternalPool = state.externalUsers.find((u: Profile) => u.userId === userId);
            if (fromExternalPool) {
                return fromExternalPool;
            }

            return {};
        },
    },
    actions: {
        async load(context: ActionContext<TUsersState, TState>) {
            if (store.getters["getUserEmail"] === 'anonymous' || !store.getters["getJWT"]) {
                return;
            }

            return byWorkspace(
                store.getters["getBaseWorkspaceId"],
                store.getters["getJWT"]
            ).then((internals) => {
                context.commit('SET_USERS', internals);
            }).catch((e: AxiosError) => {
                if (e.response && e.response.status === 403) {
                    router.push({ name: 'no-permission' });
                }
            });
        },
        async loadExternalUser(context: ActionContext<TUsersState, TState>, clientId?: string) {
            const cId = clientId || store.getters["process/getClientId"];
            context.commit('SET_CLIENT_ID', cId);

            byClient(
                store.getters["getBaseWorkspaceId"],
                cId,
                store.getters["getJWT"]
            ).then((externals) => context.commit('SET_EXTERNAL_USERS', externals))

            if (!context.state.users.length) {
                byWorkspaceForExternal(
                    store.getters["getBaseWorkspaceId"],
                    cId,
                    store.getters["getJWT"]
                ).then((internals) => context.commit('SET_USERS', internals));
            }
        },
        async addInternal(context: ActionContext<TUsersState, TState>, data: { email: string, userProfile: PermissionStorePayload['userProfile'] }): Promise<Profile> {
            return storeInternal(
                store.getters["getBaseWorkspaceId"],
                data.email,
                {
                    type: IPermissionTypes.ADMIN,
                    userProfile: {
                        gender: data.userProfile.gender,
                        firstName: data.userProfile.firstName,
                        lastName: data.userProfile.lastName,
                    },
                },
                store.getters["getJWT"]
            ).then((res: Profile) => {
                context.commit('ADD_USER', res);
                return res;
            });
        },
        async addExternal(context: ActionContext<TUsersState, TState>, data: { email: string, userProfile: PermissionStorePayload['userProfile'], inviteToProcess?: {processId: string, projectId: string} }): Promise<Profile> {
            return storeExternal(
                store.getters["getBaseWorkspaceId"],
                context.state.clientId,
                data.email,
                {
                    type: IPermissionTypes.ADMIN,
                    inviteToProcessId: data.inviteToProcess?.processId,
                    inviteToProjectId: data.inviteToProcess?.projectId,
                    userProfile: {
                        gender: data.userProfile.gender,
                        firstName: data.userProfile.firstName,
                        lastName: data.userProfile.lastName,
                    },
                },
                store.getters["getJWT"]
            ).then((res: Profile) => {
                context.commit('ADD_EXTERNAL_USER', res);
                return res;
            })
        },
        async removeExternal(context: ActionContext<TUsersState, TState>, userId: string): Promise<boolean> {
            context.commit('REMOVE_EXTERNAL_USER', userId);
            return removeExternalApi(store.getters["getBaseWorkspaceId"], context.state.clientId, userId, store.getters["getJWT"]);
        },
        async removeInternal(context: ActionContext<TUsersState, TState>, userId: string): Promise<boolean> {
            context.commit('REMOVE_USER', userId);
            return removeInternaApi(store.getters["getBaseWorkspaceId"], userId, store.getters["getJWT"]);
        },
    },
    mutations: {
        RESET_STATE(state: TUsersState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_USERS(state: TUsersState, users: Profile[]) {
            state.users = users;
        },
        ADD_USER(state: TUsersState, user: Profile) {
            state.users.push(user);
        },
        REMOVE_USER(state: TUsersState, userId: string) {
            state.users = state.users.filter((user: Profile) => user.userId !== userId);
        },
        //
        SET_CLIENT_ID(state: TUsersState, clientId: string) {
            state.clientId = clientId;
        },
        SET_EXTERNAL_USERS(state: TUsersState, users: Profile[]) {
            state.externalUsers = users;
        },
        ADD_EXTERNAL_USER(state: TUsersState, user: Profile) {
            state.externalUsers.push(user);
        },
        REMOVE_EXTERNAL_USER(state: TUsersState, userId: string) {
            state.externalUsers = state.externalUsers.filter((user: Profile) => user.userId !== userId);
        },
    },
}
