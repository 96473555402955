
import { defineComponent } from 'vue';
import LogoAndName from '@/views/components/LogoAndName.vue';
import UserSelection from '@/views/components/UserSelection/UserSelection.vue';
import dateFormat from "dateformat";

export default defineComponent({
    components: {
        LogoAndName,
        UserSelection
    },
    props: {
        content: {
            type: Object,
            required: true
        },
        blockId: {
            type: String,
            required: true
        }
    },
    emits: ['update:content'],
    data() {
        return {
        }
    },
    methods: {
        format: function (time: string) {
            return dateFormat(time, "dd.mmm");
        },
        toggleDone: function () {
            this.$emit('update:content', {
                ...this.content,
                status: this.content.status != "DONE" ? "DONE" : "TODO"
            })
        },
    },
    computed: {
        computedtHeadline: {
            get() {
                return this.content.headline || {};
            },
            set(v: any) {
                this.$emit('update:content', {
                    ...this.content,
                    headline: v
                })
            },
        },
        computedtDescription: {
            get() {
                return this.content.description || {};
            },
            set(v: any) {
                this.$emit('update:content', {
                    ...this.content,
                    description: v
                })
            },
        },
        computedAccountablePerson: {
            get() {
                return this.content.assignee ? [this.content.assignee] : [];
            },
            set(v: any) {
                this.$emit('update:content', {
                    ...this.content,
                    assignee: v[0] ? String(v[0]) : null
                })
            },
        },
        computedFollowers: {
            get() {
                return this.content.followers || []
            },
            set(v: any) {
                this.$emit('update:content', {
                    ...this.content,
                    followers: v.map((u: any) => String(u))
                })
            },
        }
    }
});

