
import { defineComponent } from "vue";
import { store } from "@/store";
import { update as updateApi } from "@smace-technologies/workspace/src/workspace";
import { uploadFile } from "@smace-technologies/workspace/src/workspace";

export default defineComponent({
  components: {},
  props: {},
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      errorMsg: "",
      org: {
        name: "",
        config: {
          displayName: "",
          images: {} as any,
          texts: {} as any,
          styles: {} as any
        },
      },
    };
  },
  computed: {
    workspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
    workspaceConfig: function () {
      return store.getters["getBaseWorkspaceConfig"];
    },
  },
  watch: {
    workspaceConfig: {
      handler() {
        this.org.config = JSON.parse(
          JSON.stringify(this.workspaceConfig)
        ) as any;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      await updateApi(
        store.getters["getBaseWorkspaceId"],
        {
          config: this.org.config as any,
        } as any,
        store.getters["getJWT"]
      );
      this.submitted = false;
      store.dispatch("reloadBaseWorkspace");
    },
  },
});
