
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    roleId: {
      type: String,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
    };
  },
  computed: {
    role: function () {
      return store.getters["settingsRoles/getRoles"].find((r: any) => r.roleId === this.roleId)
    },
  },
  watch: {
    role: function () {
      this.name = this.role?.name;
    }
  },
  mounted() {
    this.name = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;

      store.dispatch("settingsRoles/update", { name: this.name, roleId: this.roleId, internExtern: this.role.internExtern, defaultEmailAddress: this.role.defaultEmailAddress });

      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;

      el.click();
      this.$emit("reload");
    },
  },
});
