import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae945eea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  id: "formEditorModal",
  tabindex: "-1",
  "data-bs-backdrop": "true",
  "aria-labelledby": "formEditorModal",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  type: "button",
  class: "btn-close",
  "data-bs-dismiss": "modal",
  "aria-label": "Close",
  ref: "closeBtn"
}
const _hoisted_5 = { id: "form-builder" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("button", _hoisted_4, null, 512), [
          [_vShow, false]
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createElementBlock("iframe", {
            style: {},
            src: _ctx.iframeLink,
            key: _ctx.iframeLink
          }, null, 8, _hoisted_6))
        ])
      ])
    ])
  ]))
}