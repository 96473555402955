
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
    components: {},
    data: () => {
        return {
            errMsg: '',
            name: '',
            type: '',
            value: '',
            label: '',
            creating: false,
        };
    },
    emits: ['cancel', 'addVariable'],
    mounted() {
        this.reset();
    },
    computed: {
        canStore: function () {
            return this.name &&
                this.type;
        }
    },
    methods: {
        reset: function () {
            this.errMsg = '';
            this.name = '';
            this.type = '';
            this.value = '';
            this.label = '';
        },
        submit: async function () {
            this.creating = true;
            await store.dispatch('process/addVariable', {
                name: this.name,
                type: this.type,
                value: this.value,
                label: this.label
            }).then((res: any) => {
                this.$emit('addVariable', res);
                this.reset();
            }).catch((err: any) => {
                this.errMsg = err.message;
            });
            this.creating = false;
        }
    },
});
