
import { store } from "@/store";
import SelectDate from "@/views/pages/settings/processes/show/sidebar/components/SelectDate.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        SelectDate
    },
    props: {
        hideInputIcon: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            startDate: null,
            endDate: null,
        };
    },
    watch: {
        endDate: function () {
            this.$emit('update', {
                startDate: this.startDate,
                endDate: this.endDate,
            });
        },
        startDate: function () {
            this.$emit('update', {
                startDate: this.startDate,
                endDate: this.endDate,
            });
        }
    },
    computed: {
        canNext: function (): boolean {
            return true;
        },
    },
    methods: {
        dateInDays: function (days: number) {
            const today = new Date();
            const tomorrow = new Date();
            tomorrow.setDate(today.getDate() + days);
            const timestamp = (new Date(tomorrow).getTime());
            return timestamp;
        },
        next: async function () {
            this.$emit('next');
        },
    },
    mounted: async function () {
        this.startDate = this.dateInDays(0);
        this.endDate = this.dateInDays(62);
    },
});
