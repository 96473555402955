
//@ts-disable
import { store } from "@/store";
import { defineComponent } from "vue";
import dateFormat from "dateformat";
import AddRoleModal from "./modal/AddRoleModal.vue";
import DeleteRoleModal from "./modal/DeleteRoleModal.vue";
import RenameRoleModal from "./modal/RenameRoleModal.vue";
import ContextMenu from "@/views/components/ContextMenu.vue";
import HelpTooltip from "@/views/components/HelpTooltip.vue";
import { Role } from "@smace-technologies/workspace/types/role";

export default defineComponent({
    components: {
        AddRoleModal,
        DeleteRoleModal,
        RenameRoleModal,
        ContextMenu,
        HelpTooltip
    },
    data: () => {
        return {
            loading: false,
            selectedItemId: "",
            type: "INTERN"
        };
    },
    computed: {
        roles: function () {
            return store.getters["settingsRoles/getRoles"];
        },
        internRoles: function () {
            return store.getters["settingsRoles/getRoles"].filter((r: any) => r.internExtern == 'INTERN');
        },
        externRoles: function () {
            return store.getters["settingsRoles/getRoles"].filter((r: any) => r.internExtern == 'EXTERN');
        },
    },
    async mounted() {
        this.load();
    },
    methods: {
        updateDefaultEmail: function(role: Role, $event: any){
            store.dispatch("settingsRoles/update", { name: role.name, roleId: role.roleId, internExtern: role.internExtern, defaultEmailAddress: $event.target.value });
        },
        load: async function () {
            await store.dispatch("settingsRoles/load");
        },
    },
});
