
import { get, generate } from "@smace-technologies/workspace/src/apiKey";
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  data() {
    return {
      apiKey: "",
    };
  },
  computed: {
    workspaceId: function (): string {
      return store.getters["getBaseWorkspaceId"];
    },
  },
  methods: {
    generaeApiKey: async function () {
      this.apiKey = await generate(this.workspaceId, store.getters["getJWT"]) as any;
    },
    getApiKey: async function () {
      const res = await get(this.workspaceId, store.getters["getJWT"]) as any;
      this.apiKey = res.value;
    },
  },
  async mounted() {
    this.getApiKey();
  },
});
