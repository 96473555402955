
import { defineComponent } from "vue";
import { store } from "@/store";
import WYSIWYGEmailEditor from "./WYSIWYGEmailEditor.vue";

export default defineComponent({
  components: {
    WYSIWYGEmailEditor
  },
  props: {
    typeId: {
      type: String,
      required: true,
    },
    processName: {
      type: String,
      required: true,
    },
    emailType: {
      type: String,
      required: true,
    },
    emailTypeName: {
      type: String,
      required: true,
    },
  },
  emits: ["added", "update:type"],
  data() {
    return {
      loading: false,
      submitted: false,
      subject: "",
      headline: "",
      buttonText: "",
      body: "",
    };
  },
  computed: {
  },
  watch: {
    emailType: function () {
      this.load();
    },
    typeId: function () {
      this.load();
    }
  },
  methods: {
    setDefault: async function () {
      this.subject = 'A new process - {{projectName}} : {{workspaceName}}';
      this.headline = 'A new process';
      this.buttonText = 'View Process';
      this.body = `<p>A new process has been created: <a target="_blank" rel="noopener noreferrer nofollow" href="{{actionUrl}}">{{processName}}</a> </p><p></p><p><em>Client</em> </p><p><strong>{{clientName}}</strong> </p><p><em>Project</em> </p><p><strong>{{projectName}}</strong></p>`;
    },
    load: async function () {
      this.loading = true;
      this.setDefault();

      await store.dispatch("process/load", { projectId: 'TEMPLATE', processId: this.typeId });
      const res = store.getters["process/getProcess"];

      if (res && res.emails && res.emails[this.emailType]) {
        this.subject = res.emails[this.emailType].subject;
        this.headline = res.emails[this.emailType].headline;
        this.body = res.emails[this.emailType].body;
        this.buttonText = res.emails[this.emailType].buttonText;
      }

      if (!this.body || !this.subject || !this.headline || !this.buttonText) {
        this.setDefault();
      }

      this.loading = false;
    },
    reset: async function () {
      this.setDefault();
      return store.dispatch("process/updateEmailObj", {
        emailType: this.emailType, payload: {
          subject: this.subject,
          headline: this.headline,
          buttonText: this.buttonText,
          body: this.body
        }
      });
    },
    submit: async function () {
      this.submitted = true;

      await store.dispatch("process/updateEmailObj", {
        emailType: this.emailType, payload: {
          subject: this.subject,
          headline: this.headline,
          buttonText: this.buttonText,
          body: this.body
        }
      }).catch((err: any) => {
        alert('Error ' + err);
      }).then(() => {
        const el = this.$refs["closeBtn"] as any;
        el.click();
      });

      this.submitted = false;
    },
  },
});
