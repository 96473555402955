import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25a6603e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-form-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        placeholder: "E-Mail",
        name: "email",
        type: "email",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
        class: "otp-input"
      }, null, 512), [
        [_vModelText, _ctx.email]
      ]),
      (_ctx.codeSent)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            placeholder: "Enter your code",
            name: "customChallengeAnswer",
            type: "text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customChallengeAnswer) = $event)),
            class: "otp-input"
          }, null, 512)), [
            [_vModelText, _ctx.customChallengeAnswer]
          ])
        : _createCommentVNode("", true),
      (!_ctx.codeSent)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            onClick: _cache[2] || (_cache[2] = (event) => _ctx.sendLoginCode(event)),
            class: "amplify-button amplify-button--primary amplify-field-group__control amplify-authenticator__font d-flex"
          }, " Send Login Code "))
        : _createCommentVNode("", true),
      (_ctx.codeSent)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            onClick: _cache[3] || (_cache[3] = (event) => _ctx.answerCode(event)),
            class: "amplify-button amplify-button--primary amplify-field-group__control amplify-authenticator__font d-flex"
          }, " Sign In "))
        : _createCommentVNode("", true)
    ])
  ]))
}