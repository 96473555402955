import { TState } from '@/types/TState';
import { store } from '@/store';
import { v4 as uuid } from "uuid";
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { show as getProcess, update as updateProcessApi } from "@smace-technologies/process/src/process"
import { Process, ProcessStorePayload } from '@smace-technologies/process/types/process';
import { Project } from '@smace-technologies/project/types/project';
import { show as getProjectApi } from "@smace-technologies/project/src/project"
import { Step } from '@smace-technologies/process/types/step';
import { v4 as uuidv4 } from 'uuid';

export const key: InjectionKey<Store<TState>> = Symbol();

type TProcessState = {
    project: {
        name: string;
    };
    process: Process;
    parentProcess: Process;
    projectId: string;
    processId: string;
    loading: boolean;
    steps?: Step[];
};
const initialState = (): TProcessState => ({
    project: {
        name: ''
    },
    process: {
        name: '',
        typeId: '',
        processId: '',
        metaData: {
            placeholders: [],
            placeholderMatching: [],
        },
        workspaceIdProjectId: '',
        workspaceId: '',
        projectId: '',
        stats: {},
        followers: [],
        followersConfig: {} as Process['followersConfig'],
        emails: {},
        timestamp: 0,
        createdAt: 0,
        jwt: 0,
    },
    parentProcess: {
        metaData: {
            placeholders: [],
            placeholderMatching: [],
        },
        name: '',
        emails: {},
        followers: [],
        followersConfig: {} as Process['followersConfig'],
        processId: '',
        projectId: '',
        typeId: '',
        workspaceIdProjectId: '',
        workspaceId: '',
        stats: {},
        timestamp: 0,
        createdAt: 0,
        jwt: 0,
    },
    projectId: 'TEMPLATE',
    processId: '',
    loading: true,
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getProject: (state: TProcessState) => {
            return state.project;
        },
        getPlaceholders: (state: TProcessState) => {
            if (state.process.projectId === 'TEMPLATE' && state.process.typeId !== state.process.processId) {
                return state.parentProcess?.metaData?.placeholders || [];
            }

            return state.process?.metaData?.placeholders || [];
        },
        getVariables: (state: TProcessState) => {
            if (state.process.projectId === 'TEMPLATE' && state.process.typeId !== state.process.processId) {
                return state.parentProcess?.metaData?.variables || {};
            }
            return state.process?.metaData?.variables || {};
        },
        isLoading: (state: TProcessState) => {
            return state.loading;
        },
        getProcess: (state: TProcessState) => {
            return state.process;
        },
        getFollowers: (state: TProcessState) => {
            return state.process.followers || [];
        },
        getProcessTypeId: (state: TProcessState) => {
            return state.process.typeId;
        },
        getProcessId: (state: TProcessState) => {
            return state.processId;
        },
        getProjectId: (state: TProcessState) => {
            return state.projectId;
        },
        getSteps: (state: TProcessState) => {
            return state.steps;
        },
    },
    actions: {
        async loadWithModules(context: ActionContext<TProcessState, TState>, data: { projectId: string, processId: string }) {
            store.commit("processModules/SET_MILESTONES", []);
            const res = await context.dispatch('load', data);
            store.dispatch("processModules/load", { typeId: res.typeId, processId: data.processId });
        },
        async load(context: ActionContext<TProcessState, TState>, data: { projectId: string, processId: string }): Promise<Process> {
            context.commit("SET_LOADING", true);
            context.commit('SET_PARENT_PROCESS', {});

            const res = await getProcess(
                store.getters.getBaseWorkspaceId,
                data.projectId,
                data.processId,
                store.getters.getJWT
            ).catch((err) => {
                throw err;
            });

            if (res.projectId === 'TEMPLATE' && res.processId !== res.typeId) {
                const parentProcess = await getProcess(
                    store.getters.getBaseWorkspaceId,
                    data.projectId,
                    res.typeId,
                    store.getters.getJWT
                );
                context.commit('SET_PARENT_PROCESS', parentProcess);
            }

            context.commit('SET_PROCESS', res);

            if (data.projectId !== 'TEMPLATE') {
                await getProjectApi(
                    store.getters.getBaseWorkspaceId,
                    data.projectId,
                    store.getters.getJWT
                ).then((res) => {
                    context.commit('SET_PROJECT', res);
                    store.dispatch('client/load', res.clientId);
                });
            } else {
                context.commit('SET_PROJECT', { name: 'TEMPLATE', projectId: 'TEMPLATE' });
            }

            context.commit("SET_LOADING", false);

            return res;
        },
        storeProcess(context: ActionContext<TProcessState, TState>) {
            return updateProcessApi(
                store.getters.getBaseWorkspaceId,
                context.state.projectId,
                context.state.processId,
                {
                    name: context.state.process.name,
                    emails: context.state.process.emails,
                    followers: context.state.process.followers,
                    followersConfig: context.state.process.followersConfig,
                    metaData: {
                        placeholders: context.state.process.metaData.placeholders as ProcessStorePayload['metaData']['placeholders'],
                        variables: context.state.process.metaData.variables as ProcessStorePayload['metaData']['variables'],
                    },
                },
                store.getters.getJWT
            );
        },
        storeParentProcess(context: ActionContext<TProcessState, TState>) {
            updateProcessApi(
                store.getters.getBaseWorkspaceId,
                context.state.parentProcess.projectId,
                context.state.parentProcess.processId,
                {
                    name: context.state.parentProcess.name,
                    emails: context.state.parentProcess.emails,
                    followers: context.state.parentProcess.followers,
                    followersConfig: context.state.parentProcess.followersConfig,
                    metaData: {
                        placeholders: context.state.parentProcess.metaData.placeholders as ProcessStorePayload['metaData']['placeholders'],
                        variables: context.state.parentProcess.metaData.variables as ProcessStorePayload['metaData']['variables'],
                    },
                },
                store.getters.getJWT
            );
        },
        /// Variable
        async addVariable(context: ActionContext<TProcessState, TState>, variable: { id?: string, name: string, type: string, value: string, label: string }) {
            variable['id'] = uuidv4();

            if (context.state.parentProcess.processId) {
                await context.commit('ADD_PARENT_VARIABLE', variable);
                await context.dispatch('storeParentProcess');
            } else {
                await context.commit('ADD_VARIABLE', variable);
                await context.dispatch('storeProcess');
            }
            return variable;
        },
        async updateVariable(context: ActionContext<TProcessState, TState>, variable: { id: string, name: string, type: string, value: string, label: string }) {
            if (context.state.parentProcess.processId) {
                await context.commit('UPDATE_PARENT_VARIABLE', variable);
                await context.dispatch('storeParentProcess');
            } else {
                await context.commit('UPDATE_VARIABLE', variable);
                await context.dispatch('storeProcess');
            }
            return variable;
        },
        deleteVariable(context: ActionContext<TProcessState, TState>, variable: { id: string }) {
            if (context.state.parentProcess.processId) {
                context.commit('DELETE_PARENT_VARIABLE', variable);
                context.dispatch('storeParentProcess');
            } else {
                context.commit('DELETE_VARIABLE', variable);
                context.dispatch('storeProcess');
            }
            return variable;
        },
        /// Placeholders
        addPlaceholder(context: ActionContext<TProcessState, TState>, placeholder: { name: string, description: string }) {
            const newPlaceholder = {
                placeholderId: `p::${uuid()}`,
                ...placeholder
            };

            if (context.state.parentProcess.processId) {
                context.commit('ADD_PARENT_PLACEHOLDER', newPlaceholder);
                context.dispatch('storeParentProcess');
            } else {
                context.commit('ADD_PLACEHOLDER', newPlaceholder);
                context.dispatch('storeProcess');
            }

            return newPlaceholder;
        },
        updatePlaceholder(context: ActionContext<TProcessState, TState>, placeholder: { placeholderId: string, name: string, description: string }) {
            context.commit('UPDATE_PLACEHOLDER', placeholder);
            context.dispatch('storeProcess');
        },
        updateEmailObj(context: ActionContext<TProcessState, TState>, data: { emailType: string, payload: { headline: string, subject: string, body: string } }) {
            context.commit('UPDATE_EMAIL', data);
            context.dispatch('storeProcess');
        },
        removePlaceholder(context: ActionContext<TProcessState, TState>, placeholderId: string) {
            context.commit('REMOVE_PLACEHOLDER', placeholderId);
            context.dispatch('storeProcess');
        },
        setFollowers(context: ActionContext<TProcessState, TState>, followers: string[]) {
            context.commit('SET_FOLLOWERS', followers);
            context.dispatch('storeProcess');
        }
    },
    mutations: {
        SET_PROCESS(state: TProcessState, process: Process) {
            state.process = process;
            state.processId = process.processId;
            state.projectId = process.projectId;
        },
        SET_PARENT_PROCESS(state: TProcessState, process: Process) {
            state.parentProcess = process;
        },
        SET_PROJECT(state: TProcessState, project: Project) {
            state.project = project;
        },
        SET_LOADING(state: TProcessState, loading: boolean) {
            state.loading = loading;
        },
        SET_FOLLOWERS(state: TProcessState, followers: string[]) {
            state.process.followers = followers;
        },
        RESET_STATE(state: TProcessState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        ADD_PLACEHOLDER(state: TProcessState, placeholder: { placeholderId: string, name: string, description: string }) {
            if (!state.process.metaData) {
                state.process.metaData = {} as Process['metaData'];
            }
            if (!state.process.metaData.placeholders) {
                state.process.metaData.placeholders = [];
            }

            state.process.metaData.placeholders.push(placeholder);
        },
        ADD_PARENT_PLACEHOLDER(state: TProcessState, placeholder: { placeholderId: string, name: string, description: string }) {
            if (!state.parentProcess.metaData) {
                state.parentProcess.metaData = {} as Process['metaData'];
            }
            if (!state.parentProcess.metaData.placeholders) {
                state.parentProcess.metaData.placeholders = [];
            }

            state.parentProcess.metaData.placeholders.push(placeholder);
        },
        UPDATE_EMAIL(state: TProcessState, data: { emailType: string, payload: { headline: string, subject: string, body: string } }) {
            if (!state.process.emails) {
                state.process.emails = {} as Process['emails'];
            }
            if (!state.process.emails[data.emailType]) {
                state.process.emails[data.emailType] = {};
            }

            state.process.emails[data.emailType] = data.payload;
        },
        UPDATE_PLACEHOLDER(state: TProcessState, placeholder: { placeholderId: string, name: string, description: string }) {
            state.process.metaData.placeholders = state.process.metaData.placeholders.map((p: { placeholderId?: string, name: string, description: string }) => {
                if (p.placeholderId === placeholder.placeholderId) {
                    return placeholder;
                }
                return p;
            });
        },
        REMOVE_PLACEHOLDER(state: TProcessState, placeholderId: string) {
            state.process.metaData.placeholders = state.process.metaData.placeholders.filter((p: { placeholderId?: string, name: string, description: string }) => {
                return p.placeholderId !== placeholderId;
            }).filter((p: { placeholderId?: string, name: string, description: string }) => p);
        },
        //// Variables
        ADD_VARIABLE(state: TProcessState, variable: { id: string, name: string, type: any, value: string, label: string }) {
            if (!state.process.metaData) {
                state.process.metaData = {} as Process['metaData'];
            }
            if (!state.process.metaData.variables) {
                state.process.metaData.variables = {};
            }

            if (Object.prototype.hasOwnProperty.call(state.process.metaData.variables, variable.id)) {
                throw new Error('Variable already exists.');
            }

            state.process.metaData.variables[variable.id] = { type: variable.type, value: variable.value, label: variable.label, name: variable.name };
        },
        ADD_PARENT_VARIABLE(state: TProcessState, variable: { id: string, name: string, type: any, value: string, label: string }) {
            if (!state.parentProcess.metaData) {
                state.parentProcess.metaData = {} as Process['metaData'];
            }
            if (!state.parentProcess.metaData.variables) {
                state.parentProcess.metaData.variables = {};
            }

            if (Object.prototype.hasOwnProperty.call(state.parentProcess.metaData.variables, variable.id)) {
                throw new Error('Variable already exists.');
            }

            state.parentProcess.metaData.variables[variable.id] = { type: variable.type, value: variable.value, label: variable.label, name: variable.name };
        },
        UPDATE_VARIABLE(state: TProcessState, variable: { id: string, name: string, type: any, value: string, label: string }) {
            state.process.metaData.variables[variable.id] = { ...state.process.metaData.variables[variable.name], value: variable.value, label: variable.label, type: variable.type, name: variable.name };
        },
        UPDATE_PARENT_VARIABLE(state: TProcessState, variable: { id: string, name: string, type: any, value: string, label: string }) {
            state.parentProcess.metaData.variables[variable.id] = { ...state.parentProcess.metaData.variables[variable.name], value: variable.value, label: variable.label, type: variable.type, name: variable.name };
        },
        DELETE_VARIABLE(state: TProcessState, variable: { id: string }) {
            delete state.process.metaData.variables[variable.id];
        },
        DELETE_PARENT_VARIABLE(state: TProcessState, variable: { id: string }) {
            delete state.parentProcess.metaData.variables[variable.id];
        },
    },
}
