
import { defineComponent } from 'vue';
import WYSIWYGEditor from '../WYSIWYGEditor.vue'

export default defineComponent({
    components: {
        WYSIWYGEditor
    },
    props: {
        content: {
            type: String,
        },
        isReadOnly: {
            type: Boolean,
        }
    },
    emits: ['update:content'],
    data() {
        return {
            editor: null,
        }
    },
    computed: {
        computedContent: {
            get(): any {
                return this.content;
            },
            set(v: any) {
                this.$emit('update:content', v)
            },
        }
    },
});

