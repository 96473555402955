
import { store } from "@/store";
import { defineComponent } from "vue";
import LogoAndName from "@/views/components/LogoAndName.vue";
import UserSelection from '@/views/components/UserSelection/UserSelection.vue';

export default defineComponent({
    components: {
        UserSelection,
        LogoAndName
    },
    data() {
        return {
            selectedItemId: null,
            selectedPlaceholder: {} as any,
        };
    },
    props: {
        placeholders: {
            type: Array,
        },
        clientId: {
            type: String,
        },
    },
    computed: {
    },
    watch: {
        placeholders: {
            handler: function () {
                this.load();
            },
            deep: true,
        },
        selectedPlaceholder: {
            handler: function () {
                this.$emit('update', this.selectedPlaceholder);
            },
            deep: true,
        }
    },
    methods: {
        load: async function () {
            this.placeholders.forEach((placeholder: any) => {
                this.selectedPlaceholder[placeholder.placeholderId] = [];
            });
        },
        updateVals: function () {
            this.$emit('update', {

            });
        },
        next: async function () {
            this.$emit('next');
        },
    },
    async mounted() {
        this.load();
    },
});
