
//import router from "@/router";
//import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {},
  data() {
    return {
      menuItems: [
        {
          label: "Templates",
          links: [
            {
              label: "Processes",
              to: "/settings/processes",
            },
            {
              label: "Forms",
              to: "/settings/forms",
            },
            {
              label: "Emails",
              to: "/settings/emails",
            },
          ],
        },
        {
          label: "Settings",
          links: [
            {
              label: "Workspace Logo",
              to: "/settings/style",
            },
            {
              label: "Domain settings",
              to: "/settings/url",
            },
            {
              label: "Update workspace name",
              to: "/settings/update-name",
            },
            {
              label: "Delete workspace",
              to: "/settings/delete",
            },
          ],
        },
        {
          label: "Users",
          links: [
            {
              label: "View Users",
              to: "/settings/users",
            },
            {
              label: "Invite User",
              to: "/settings/user-invite",
            },
          ],
        },
        {
          label: "Billings",
          links: [
            {
              label: "Invoices",
              externalLink:
                "https://billing.stripe.com/p/login/test_bIYcPE4n510c9fG5kk",
              to: "https://billing.stripe.com/p/login/test_bIYcPE4n510c9fG5kk",
            },
          ],
        },
        {
          label: "Integrations",
          links: [
            {
              label: "API Key",
              to: "/settings/api-key",
            },
            {
              label: "Automations",
              to: "/settings/automations",
            },
          ],
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    isOneRouteActive: function (routes: []) {
      return routes.some(
        (route: { to: string }) => route.to === this.$route.path
      );
    },
    init: function () {
      this.menuItems = this.menuItems.map((item: any) => {
        return {
          ...item,
          active: this.isOneRouteActive(item.links),
        };
      });
    },
    resetActive: function () {
      this.menuItems = this.menuItems.map((item: any) => {
        return {
          ...item,
          active: false,
        };
      });
    },
  },
  mounted() {
    this.init();
  },
});
