
import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
    };
  },
  computed: {
    isLoggedIn: function () {
      return store.getters['getUserSession'];
    },
    email: function () {
      return store.getters['getUserEmail'];
    },
    profile: function() {
      return store.getters["user/getProfile"];
    },
    fullName: function(){
      return store.getters["user/getUserFullName"];
    },
  },
  methods: {
    logout: async function () {
      await store.dispatch("logout");
      this.$router.push({ name: "login" });
    },
  },
  async mounted() {
    await store.dispatch("users/load");
    await store.dispatch("user/load");
  },
});
