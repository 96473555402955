import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7213bd12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block block-image" }
const _hoisted_2 = { class: "image-row" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedContent, (image, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: image.uploadId,
          class: "image-wrapper",
          style: _normalizeStyle({flex: _ctx.flexAmount[index]})
        }, [
          (_openBlock(), _createElementBlock("img", {
            src: image.url,
            class: "img",
            key: index,
            onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLoad && _ctx.handleLoad(...args)))
          }, null, 40, _hoisted_3))
        ], 4))
      }), 128))
    ])
  ]))
}