
import { defineComponent } from "vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import type { DatePickerInstance } from "@vuepic/vue-datepicker"
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
  components: {
    VueDatePicker
  },
  emits: ["selectDate", "clearDate"],
  props: {
    initialDate: {
      default: null,
    },
    hideInputIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: null,
      datepicker: null as DatePickerInstance
    }
  },
  watch: {
    initialDate: {
      handler(newVal) {
        this.date = newVal;
      },
      immediate: true
    }
  },
  mounted() {
    this.datepicker = this.$refs.datepicker as DatePickerInstance;
  },
  computed: {
    format() {
      return 'dd.MM.yyyy';
    }
  },
  methods: {
    selectDate: function() {
      if (this.datepicker) {
        this.datepicker.selectDate();
        const timestamp = (new Date(this.date).getTime());
        this.$emit("selectDate", timestamp);
      }
    },
  cancelSelection: function() {
    if (this.datepicker) {
      this.datepicker.closeMenu();
    }
  }
  }
})
