
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    text: {},
  },
  data: () => {
    return {};
  },
  methods: {},
});
