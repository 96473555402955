
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    typeId: {
      type: String
    }
  },
  emits: ["added"],
  data() {
    return {
      submitted: false,
      name: "",
      processTypeId: "" as any
    };
  },
  computed: {
    processTemplates() {
      return store.getters["settingsProcesses/getTypes"];
    }
  },
  watch: {
    typeId: function (val) {
      this.processTypeId = val;
    }
  },
  methods: {
    submit: async function () {
      this.submitted = true;

      const res = await store.dispatch("settingsProcesses/addSubProcess", { name: this.name, typeId: this.processTypeId });

      this.submitted = false;
      this.name = "";

      const el = this.$refs["closeBtn"] as any;
      el.click();

      this.$emit("added", res.processId);
    },
  },
});
