import { createApp } from 'vue'
import App from './App.vue'
import AmplifyVue from '@aws-amplify/ui-vue';
import "@aws-amplify/ui-vue/styles.css";
import '@/assets/scss/style.scss'
import { Amplify } from "aws-amplify";
import { getCognitoConfiguration } from '@smace-technologies/auth/customer';
import VueFeather from 'vue-feather';
import "bootstrap/dist/js/bootstrap.js"

import router from './router'
import { store, key } from './store'

// language translator for amplify-ui
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-vue';
I18n.putVocabularies(translations);
I18n.setLanguage('en');

const url = window.location.hostname;
const segments = url.split('.');
const subdomainAndTopLevelDomain = segments.slice(-3).join('.');

Amplify.configure({
    Auth: {
        region: getCognitoConfiguration().CognitoRegion,
        userPoolId: getCognitoConfiguration().CognitoUserPoolId,
        userPoolWebClientId: getCognitoConfiguration().CognitoUserPoolClientId,
        clientMetadata: { platformName: 'smace-technologies' },
        cookieStorage: {
            domain: subdomainAndTopLevelDomain,
            secure: false,
            path: '/',
            expires: 5,
        },
        oauth: {
            domain: `${getCognitoConfiguration().CognitoUserPoolDomain}.auth.${getCognitoConfiguration().CognitoRegion}.amazoncognito.com`,
            scope: ['email', 'openid'],
            redirectSignIn: location.origin,
            redirectSignOut: `${location.origin}#logout`,
            responseType: 'code'
        }
    }
});


createApp(App)
    .use(store, key)
    .use(router)
    .use(AmplifyVue)
    .component('FeatherIcon', VueFeather)
    .mount('#app');
