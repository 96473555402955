
import { defineComponent } from "vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import type { DatePickerInstance } from "@vuepic/vue-datepicker"
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
  components: {
    VueDatePicker
  },
  emits: ["update:date"],
  props: {
    date: {
      default: null,
    },
  },
  data() {
    return {
      tmpDate: "",
      datepicker: null as DatePickerInstance
    }
  },
  watch: {
    date: {
      handler(newVal: any) {
        this.tmpDate = newVal;
      },
      immediate: true
    },
  },
  mounted() {
    this.datepicker = this.$refs.datepicker as DatePickerInstance;
  },
  computed: {
    format() {
      return 'dd.MM.yyyy';
    }
  },
  methods: {
    reset: function(){
      this.$emit("update:date", null);
      this.cancelSelection();
    },
    selectDate: function () {
      if (this.datepicker) {
        this.datepicker.selectDate();
        const timestamp = (new Date(this.tmpDate).getTime());
        this.$emit("update:date", timestamp);
      }
    },
    cancelSelection: function () {
      if (this.datepicker) {
        this.datepicker.closeMenu();
      }
    }
  }
})
