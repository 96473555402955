
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  emits: ["added"],
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  methods: {
  },
});
