
import { v4 as uuid } from "uuid";
import { defineComponent } from "vue";
import { store } from "@/store";
import { getAll } from "@smace-technologies/task/src/task";

export default defineComponent({
  components: {},
  props: {
    randomReload: {},
  },
  emits: ["added"],
  data() {
    return {
      name: "",
      description: "",
      actionButton: {
        text: "Do it now",
        href: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    submit: async function () {
      const el = this.$refs["closeBtn"] as any;
      this.$emit("added", {
        taskId: uuid(),
        type: "TASK",
        config: {
          active: true,
          userAddable: false,
          mandatory: true,
        },
        name: this.name,
        description: this.description,
        actionButton: this.actionButton,
      });
      el.click();
      this.name = "";
      this.description = "";
      this.actionButton = { href: "", text: "Do it now" };
    },
  },
});
