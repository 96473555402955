import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b59ec84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "job" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-body p-4" }
const _hoisted_5 = ["onMouseleave", "onMouseover"]
const _hoisted_6 = ["data-bs-target"]
const _hoisted_7 = { class: "subtitle" }
const _hoisted_8 = ["id"]
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = { class: "mt-2" }
const _hoisted_11 = { class: "form-check form-switch" }
const _hoisted_12 = ["id", "onUpdate:modelValue"]
const _hoisted_13 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_context_menu = _resolveComponent("context-menu")!
  const _component_arrow_icon = _resolveComponent("arrow-icon")!
  const _component_help_tooltip = _resolveComponent("help-tooltip")!
  const _component_JobTaskElement = _resolveComponent("JobTaskElement")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_draggable, {
      modelValue: _ctx.computedJobs,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedJobs) = $event)),
      group: "jobs",
      "item-key": "jobId",
      onStart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.drag = true)),
      onEnd: _cache[4] || (_cache[4] = ($event: any) => (_ctx.drag = false))
    }, {
      item: _withCtx(({ element, index }) => [
        _createElementVNode("div", {
          class: "mb-4",
          onClick: ($event: any) => (_ctx.$emit('setSelectedJob', element))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                onMouseleave: ($event: any) => (_ctx.hoverJob[index] = false),
                onMouseover: ($event: any) => (_ctx.hoverJob[index] = true)
              }, [
                (_ctx.hoverJob[index])
                  ? (_openBlock(), _createBlock(_component_context_menu, {
                      key: 0,
                      class: "me-4",
                      icon: "edit",
                      size: "13",
                      id: index,
                      onClick: ($event: any) => (_ctx.selectedJob = element),
                      links: [
                    {
                      text: 'Rename',
                      icon: 'edit',
                      bsTarget: '#jobUpdateModal',
                      click: _ctx.$emit('setSelectedJob', element),
                    },
                    {
                      text: 'Delete',
                      icon: 'trash-2',
                      bsTarget: '#jobDeleteModal',
                      click: _ctx.$emit('setSelectedJob', element),
                    },
                  ]
                    }, null, 8, ["id", "onClick", "links"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "cursor-pointer collapsed",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": `#jobMore${element.jobId}`
                }, [
                  _createElementVNode("h6", _hoisted_7, [
                    _createVNode(_component_arrow_icon, { class: "mt-2 float-end arrow-icon" }),
                    _createTextVNode(" " + _toDisplayString(element.name), 1)
                  ])
                ], 8, _hoisted_6),
                _createElementVNode("div", {
                  class: "collapse",
                  id: `jobMore${element.jobId}`
                }, [
                  _withDirectives(_createElementVNode("textarea", {
                    class: "mt-2",
                    "onUpdate:modelValue": ($event: any) => ((element.description) = $event),
                    placeholder: "Job description"
                  }, null, 8, _hoisted_9), [
                    [_vModelText, element.description]
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "checkbox",
                        id: `user-addable-${element.jobId}`,
                        "onUpdate:modelValue": ($event: any) => ((element.config.userAddable) = $event)
                      }, null, 8, _hoisted_12), [
                        [_vModelCheckbox, element.config.userAddable]
                      ]),
                      _createElementVNode("label", {
                        class: "form-check-label",
                        for: `user-addable-${element.jobId}`
                      }, [
                        _createTextVNode("User addable "),
                        _createVNode(_component_help_tooltip, { text: "User addable means this job can be added (multiple times) to a project by anyone." })
                      ], 8, _hoisted_13)
                    ])
                  ])
                ], 8, _hoisted_8),
                _createVNode(_component_JobTaskElement, {
                  class: "mt-4",
                  type: _ctx.type,
                  jobId: element.jobId,
                  tasks: element.tasks,
                  "onUpdate:tasks": ($event: any) => ((element.tasks) = $event),
                  onMouseover: _withModifiers(($event: any) => (_ctx.hoverJob[index] = false), ["stop"]),
                  onRemoveTask: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeTask(_ctx.job, $event))),
                  onSelectedTask: _ctx.setSelectedTask
                }, null, 8, ["type", "jobId", "tasks", "onUpdate:tasks", "onMouseover", "onSelectedTask"]),
                _createElementVNode("div", {
                  class: "btn btn-outline-primary w-100",
                  "data-bs-toggle": "modal",
                  "data-bs-target": "#addTaskToProjectModal",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedJob = _ctx.job))
                }, " Add task ")
              ], 40, _hoisted_5)
            ])
          ])
        ], 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}