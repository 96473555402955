import IndexFlows from '../views/pages/settings/automation/index/IndexFlows.vue'
import ShowFlow from '../views/pages/settings/automation/show/ShowFlow.vue'
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/settings/automations',
        name: 'settings-automations-index',
        component: IndexFlows,
        meta: {
            requiresAuth: true,
            subNavType: 'ACCOUNT_SETTINGS'
        }
    },
    {
        path: '/settings/automations/:flowId',
        name: 'settings-automations-show',
        component: ShowFlow,
        meta: {
            requiresAuth: true,
            subNavType: 'ACCOUNT_SETTINGS'
        }
    },
];

export default routes;