
import { store } from "@/store";
import { defineComponent } from "vue";
import UserAccordion from "./UserAccordion.vue";

export default defineComponent({
    components: {
        UserAccordion
    },
    props: {
        hidePlaceholder: {
            type: Boolean,
            default: false,
        },
        id: {
            type: Number,
            required: true
        },
        externalUsers: {
            type: Array,
            required: true
        },
        internalUsers: {
            type: Array,
            required: true
        },
        placeholders: {
            type: Array,
            required: true
        },
    },
    data: () => {
        return {};
    },
    methods: {
        removePlaceholder: function (item: any) {
            this.$emit('remove', item.placeholderId);
            store.dispatch('process/removePlaceholder', item.placeholderId);
        },
        removeInternal: function (item: any) {
            this.$emit('remove', item.userId);
            store.dispatch('users/removeInternal', item.userId);
        },
        removeExternal: function (item: any) {
            this.$emit('remove', item.userId);
            store.dispatch('users/removeExternal', item.userId);
        },
    },
    emits: ['addPlaceholder', 'addUser', 'create', 'remove'],
    computed: {
        workspaceName() {
            return store.getters["getBaseWorkspaceName"];
        },
        isTemplate() {
            return this.$route.name != 'projects' && store.getters["process/getProject"].projectId === 'TEMPLATE';
        },
        clientName() {
            return store.getters["client/getClientName"];
        },
    },
});
