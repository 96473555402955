import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { TState } from '@/types/TState';
import settingsProcesses from './settings/processes';
import settingsRoles from './settings/roles';
import process from './process/process';
import processTasks from './process/processTasks';
import processModules from './process/modules';
import client from './client/client';
import processes from './processes/processes';
import projects from './projects/projects';
import clients from './clients/clients';
import module from './module/module';
import user from './user/user';
import users from './users/users';
import moduleBlocks from './module/moduleBlocks';
import forms from './forms/forms';
import tasks from './tasks/tasks';
import task from './task/task';
import activities from './activities/activities';
import { Auth } from "aws-amplify";
import { getAllByUser } from "@smace-technologies/workspace/src/workspace";
import { warmUp } from "@smace-technologies/workspace/src/workspace";
import { byDomain } from "@smace-technologies/workspace/src/workspace";
import { checkForValidSession } from '@/router';
import { Workspace } from '@smace-technologies/workspace/types/workspace';

// define injection key
export const key: InjectionKey<Store<TState>> = Symbol()
const WORKSPACE_CACHE_KEY = 'smace-workspace';

const initialState = (): TState => ({
  baseWorkspaceId: "",
  baseWorkspaceName: "",
  baseWorkspaceConfig: "",
  baseWorkspaceDomain: "",
  workspace: {} as Workspace,
  workspaces: [] as Workspace[],
  userSession: null as any,
});

export const store: Store<TState> = createStore<TState>({
  plugins: [createPersistedState()],
  state: {
    ...initialState(),
  },
  getters: {
    getUserSession(state) {
      return state.userSession
    },
    getJWT(state) {
      return state.userSession ? state.userSession.signInUserSession?.idToken.jwtToken : '';
    },
    getBaseWorkspaceId(state) {
      return state.baseWorkspaceId;
    },
    getBaseWorkspaceName(state) {
      return state.baseWorkspaceName;
    },
    getWorkspaces(state) {
      return state.workspaces
    },
    getBaseWorkspace(state) {
      return state.workspace;
    },
    getBaseWorkspaceConfig(state) {
      return state.baseWorkspaceConfig;
    },
    getBaseWorkspaceDomain(state) {
      return state.baseWorkspaceDomain;
    },
    getUserEmail(state) {
      return state.userSession?.attributes?.email || 'anonymous';
    },
    getStoredWorkspaceId() {
      return localStorage.getItem(WORKSPACE_CACHE_KEY);
    },
  },
  mutations: {
    RESET_STATE(state: TState) {
      const initial = initialState();
      Object.assign(state, initial);
    },
    SET_WORKSPACE(state, data) {
      localStorage.setItem(WORKSPACE_CACHE_KEY, data.workspaceId);
      state.baseWorkspaceId = data.workspaceId;
      state.baseWorkspaceName = data.name;
      state.workspace = data;
    },
    setBaseWorkspaceConfig(state, data) {
      state.baseWorkspaceConfig = data;
    },
    setBaseWorkspaceDomain(state, data) {
      state.baseWorkspaceDomain = data;
    },
    setUserSession(state, session) {
      state.userSession = session;
    },
    SET_WORKSPACES(state, workspaces) {
      state.workspaces = workspaces;
    },
    setUserSignInToken(state, session) {
      state.userSession = { ...state.userSession, signInUserSession: session }
    },
    RESET_DATA() {
      store.commit('client/RESET_STATE');
      store.commit('processTasks/RESET_STATE');
      store.commit('clients/RESET_STATE');
      store.commit('process/RESET_STATE');
      store.commit('processes/RESET_STATE');
      store.commit('projects/RESET_STATE');
      store.commit('module/RESET_STATE');
      store.commit('moduleBlocks/RESET_STATE');
      store.commit('forms/RESET_STATE');
      store.commit('user/RESET_STATE');
      store.commit('users/RESET_STATE');
      store.commit('task/RESET_STATE');
      store.commit('tasks/RESET_STATE');
    },
    LOGOUT() {
      store.commit('processTasks/RESET_STATE');
      store.commit('client/RESET_STATE');
      store.commit('clients/RESET_STATE');
      store.commit('process/RESET_STATE');
      store.commit('processes/RESET_STATE');
      store.commit('projects/RESET_STATE');
      store.commit('module/RESET_STATE');
      store.commit('moduleBlocks/RESET_STATE');
      store.commit('forms/RESET_STATE');
      store.commit('user/RESET_STATE');
      store.commit('users/RESET_STATE');
      store.commit('task/RESET_STATE');
      store.commit('tasks/RESET_STATE');
      store.commit('RESET_STATE');
      localStorage.removeItem(WORKSPACE_CACHE_KEY);
      clearAllCookies();
    }
  },
  actions: {
    async LOAD_BY_ORIGIN({ dispatch, commit }) {
      const domain = window.location.hostname;

      if (!domain.startsWith('v2.smace.io') && !domain.startsWith('eu.smace.io') && domain !== 'localhost') {
        const res = await byDomain(domain);
        commit('SET_WORKSPACE', res);
        commit('setBaseWorkspaceConfig', res.config);
      }

      commit('RESET_DATA');
      dispatch("loadAll");
    },
    async logout({ commit }) {
      await Auth.signOut();
      commit('LOGOUT');
    },
    async loadAll({ dispatch }) {
      if (!store.getters["getJWT"] || !store.getters["getUserEmail"]) {
        return;
      }
      await store.dispatch("users/load");
      await store.dispatch('user/load');
      await checkForValidSession();
      warmUp();

      await dispatch('loadWorkspaces');
    },
    async loadWorkspaces({ commit }) {
      const workspaces = await getAllByUser(
        store.getters["getUserEmail"],
        store.getters["getJWT"]
      );

      const workspace = workspaces.find((w: Workspace) => w.workspaceId === localStorage.getItem(WORKSPACE_CACHE_KEY)) || workspaces[0];

      if (workspace) {
        commit('SET_WORKSPACE', workspace);
        commit('setBaseWorkspaceConfig', workspace.config);
      }

      commit("SET_WORKSPACES", workspaces);
    },
    async setBaseWorkspace({ commit }, data) {
      const domain = window.location.hostname;

      commit('SET_WORKSPACE', data);
      commit('setBaseWorkspaceConfig', data.config || {});
      commit('setBaseWorkspaceDomain', data.domain);

      if (domain !== 'localhost') {
        return window.location.href = `https://${data.domain}/#/`;
      } else {
        return window.location.href = `${window.location.origin}/#/`;
      }
    },
  },
  modules: {
    settingsProcesses,
    settingsRoles,
    processModules,
    processes,
    process,
    processTasks,
    projects,
    clients,
    client,
    module,
    moduleBlocks,
    forms,
    user,
    users,
    tasks,
    task,
    activities,
  }
})

export function useStore() {
  return baseUseStore(key)
}

export function clearAllCookies() {
  const domains = [
    '.smace.io',
    '.eu.smace.io',
    '.v2.smace.io',
  ];

  domains.forEach(domain => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=" + domain;
    }
  });
}