
import { store } from "@/store";
import { defineComponent } from "vue";
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from "aws-amplify";

export default defineComponent({
  props: {
    redirectTo: String,
  },
  components: {},
  emits: ["added"],
  data() {
    return {
      email: '',
      code: '',
      codeSent: false,
      cognitoUser: CognitoUser,
      errorMessage: '',
    };
  },
  computed: {
    workspace() {
      return store.getters['getBaseWorkspaceName'];
    },
    config: function (): any {
      return store.getters["getBaseWorkspaceConfig"];
    },
    logo: function (): any {
      return this.config && this.config.images ? this.config.images.logo : "";
    },
    userEmail: function (): any {
      return store.getters["getUserEmail"];
    },
  },
  methods: {
    submitEmail: async function () {
      if (this.email && !this.codeSent) {
        this.codeSent = true;
        this.cognitoUser = await Auth.signIn(this.email);
      }
    },
    submitCode: async function () {
      this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, this.code);
      try {
        await Auth.currentSession();
        store.dispatch("LOAD_BY_ORIGIN");
        this.$router.replace(`${this.$props.redirectTo}`);
        document.body.classList.remove('login-code-modal-open');
        const el = this.$refs["closeBtn"] as any;
        el.click();
      } catch {
        this.errorMessage = 'You have entered an invalid code. Please try again.'
      }
    },
  },
});
