
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { store } from "@/store";
import { getFormViewerUrl } from "@/services/serviceUrls";

export default defineComponent({
  components: {},
  props: {},
  emits: [""],
  data() {
    return {
      route: null,
    };
  },
  computed: {
    iframeLink: function () {
      const route = useRoute();
      const taskId = route.params.taskId as string;
      const formId = route.params.formId as string;
      const projectId = route.params.projectId as string;
      const moduleId = route.params.formId as string;
      return getFormViewerUrl(store.getters["getBaseWorkspaceId"], projectId, formId, moduleId, taskId, {});
    },
  },
  watch: {},
  methods: {
    receivePreviewMessage(event: any) {
      if (event.data.type == "GO_BACK") {
        this.$router.push({
          name: "settings-tasks",
        });
      }/* else if (event.data.type == "ON_PREVIEW") {
          this.stepId = event.data.stepId;
          const el = this.$refs["previewModal"] as any;
          el.click();
        }*/
    },
  },
  mounted() {
    this.route = useRoute();
    window.addEventListener("message", this.receivePreviewMessage, false);
  },
});
