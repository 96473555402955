
import { defineComponent } from "vue";
import { store } from "@/store";
import {
  getAutomationBaseUrl,
} from "@/services/serviceUrls";

export default defineComponent({
  components: {},
  data() {
    return {
      windowHeight: 700
    };
  },
  computed: {
    workspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
    flowId: function () {
      return this.$route.params.flowId as string;
    },
    iframeSrc: function (): string {
      return `${getAutomationBaseUrl()}/${this.workspaceId}/designer/${this.flowId}`;
    },
  },
  methods: {
    onResize: function () {
      this.windowHeight = window.innerHeight;
    }
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

});
