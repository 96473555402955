
import { defineComponent } from "vue";
import { store } from "@/store";
import { store as addUserApi } from "@smace-technologies/workspace/src/permission";
import { IPermissionTypes } from "@smace-technologies/workspace/types/permission";

export default defineComponent({
  components: {},
  props: {},
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      email: "",
      type: "ADMIN" as IPermissionTypes,
    };
  },
  computed: {
    workspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
  },
  watch: {
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      await addUserApi(
        store.getters["getBaseWorkspaceId"],
        this.email,
        { type: this.type },
        store.getters["getJWT"]
      );
      
      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;
      this.$router.push("/settings/users");
    },
  },
});
