
import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    props: {
        id: {
            type: Number,
            required: true
        },
    },
    data: () => {
        return {
            name: '',
            description: '',
        };
    },
    emits: ['cancel', 'addPlaceholder'],
    computed: {
    },
    methods: {
        reset: function () {
            this.name = '';
            this.description = '';
        },
        submit: async function () {
            const newPlaceholder = await store.dispatch('process/addPlaceholder', { name: this.name, description: this.description });
            this.$emit('addPlaceholder', newPlaceholder);
            this.reset();
        }
    },
});
