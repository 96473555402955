
import { store } from '@/store';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    user: {
    },
    rowView: {
      type: Boolean,
      default: false,
    },
    smallAvatarSize: {
      type: Boolean,
      default: false,
    },
    middleAvatarSize: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isPlaceholder: function () {
      return this.computedUser.placeholderId;
    },
    computedUser: function () {
      if (typeof this.user === 'string') {
        return store.getters["users/resolveUser"](this.user);
      }
      return this.user;
    }
  },
  methods: {
    getInitials: function (user: any) {
      if (this.computedUser.placeholderId) {
        return this.computedUser.name.substring(0, 1).toUpperCase()
      }

      if (this.computedUser.data?.firstName || this.computedUser.data?.lastName) {
        return (this.computedUser.data?.firstName.substring(0, 1).toUpperCase() || "") + (this.computedUser.data?.lastName.substring(0, 1).toUpperCase() || "");
      } else {
        return this.computedUser.email?.substring(0, 1).toUpperCase();
      }
    }
  }
});
