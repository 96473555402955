
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  emits: [],
  data() {
    return {
      submitted: false,
      name: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.name = '';
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      const res = await store.dispatch("forms/add", {
        name: this.name,
      });

      this.submitted = false;
      this.name = "";
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$router.push({ name: 'settings-form-editor', params: { formId: res.formId } });
    },
  },
});
