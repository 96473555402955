
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {
  },
  props: {
    module: {
      type: Object
    },
  },
  emits: [""],
  data() {
    return {
      submitted: false,
    };
  },
  computed: {},
  methods: {
    submit: async function () {
      this.submitted = true;
      store.dispatch('module/deleteModule', this.module);
      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$router.push({ name: "settings-process", params: { typeId: this.module.processId } });
    },
  },
});
