
import { defineComponent } from 'vue';
import { store } from '@/store';

export default defineComponent({
    components: {
    },
    emits: ['addBlock', 'tmpBlock', 'setDelay', 'openFilestack', 'addTask'],
    data() {
        return {
            hidden: true,
            isActive: false,
            timeoutIds: {} as any,
        }
    },
    computed: {
        forms: function () {
            return store.getters["forms/getForms"];
        },
        tasks: function () {
            return store.getters["tasks/getTasks"];
        },
    },
    methods: {
        onMouseEnter(event: any, id: string) {
            event.target.classList.add('active');
            if (id === "main") {
                this.$emit("setDelay", 270);
            }
            if (this.timeoutIds[id]) {
                clearTimeout(this.timeoutIds[id]);
            }
        },
        onMouseLeave(event: any, id: any) {
            if (id === "main") {
                this.$emit("setDelay", 0);
            }
            return;
            /*clearTimeout(this.timeoutIds[id]);
            this.timeoutIds[id] = setTimeout(() => {
                event.target.classList.remove('active');
                this.timeoutIds[id] = null;
            }, 270);*/
        },
        clearTimeoutAndReset(event: any, id: any, elToClose: string[]) {
            clearTimeout(this.timeoutIds[id]);
            elToClose.forEach(el => {
                document.getElementById(el).classList.remove('active');
            });
            this.onMouseEnter(event, id);
        },
        addForm(form: any) {
            this.$emit('addTask', {
                headline: form.name,
                taskType: 'form',
                typeConfig: {
                    FORM: {
                        formId: form.formId,
                    }
                }
            });
        },
        openFilestack(fileType: string, allowConcurrent = 1) {
            this.$emit('openFilestack', { fileType, allowConcurrent });
        },
    },
});

