import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import { Profile, ProfileUpdatePayload } from '@smace-technologies/user/types/profile';
import { update } from "@smace-technologies/user/src/profile";

export const key: InjectionKey<Store<TState>> = Symbol()

type TUserState = {
    user: Profile;
};

const initialState = (): TUserState => ({
    user: {} as Profile,
});

export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getUserId: (state: TUserState) => {
            return state.user.userId;
        },
        getUserProfile: (state: TUserState) => {
            return state.user;
        },
        getUserFullName: (state: TUserState) => {
            if (!state.user) return '';
            return state.user.data?.firstName + ' ' + state.user.data?.lastName;
        },
        getUserEmail: (state: TUserState) => {
            if (!state.user) return '';
            return state.user.email;
        },
    },
    actions: {
        async load(context: ActionContext<TUserState, TState>) {
            await store.dispatch('users/load');
            const userPromise = new Promise<Profile | undefined>((resolve) => {
                const user = store.getters['users/getUsers'].find((u: Profile) => u.email === store.getters['getUserEmail']);
                resolve(user);
              });
            const user = await userPromise;
            context.commit('SET_USER', user);
        },
        async updateUser(context: ActionContext<TUserState, TState>, data: { userId: string, profile: ProfileUpdatePayload }) {
            await update(data.profile, store.getters["getJWT"]);
            context.dispatch('load');
        },
    },
    mutations: {
        SET_USER(state: TUserState, user: Profile) {
            state.user = user;
        },
        RESET_STATE(state: TUserState) {
            Object.assign(state, initialState());
        },
    },
}
