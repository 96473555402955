import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fbeb5cd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "job" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_task_card = _resolveComponent("task-card")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_draggable, {
      modelValue: _ctx.computedTasks,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedTasks) = $event)),
      group: "tasks",
      "item-key": "blockId",
      onStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.drag = true)),
      onEnd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.drag = false))
    }, {
      item: _withCtx(({ element }) => [
        _createVNode(_component_task_card, {
          model: element,
          onClick: ($event: any) => (_ctx.goTo(element)),
          status: _ctx.status
        }, null, 8, ["model", "onClick", "status"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}