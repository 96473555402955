import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/icons/icon-info.svg'


const _hoisted_1 = { id: "help-tooltip" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  class: "cursor-pointer icon",
  alt: "icon-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.noIcon)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["custom-tooltip", `custom-tooltip-${_ctx.position}`])
    }, _toDisplayString(_ctx.text), 3)
  ]))
}