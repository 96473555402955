
import { defineComponent } from "vue";
import draggable from "vuedraggable";
import HelpTooltip from "@/views/components/HelpTooltip.vue";
import ContextMenu from "@/views/components/ContextMenu.vue";
import ArrowIcon from "@/views/components/ArrowIcon.vue";

export default defineComponent({
  components: {
    draggable,
    HelpTooltip,
    ContextMenu,
    ArrowIcon,
  },
  props: {
    tasks: {} as any,
    jobId: {} as any,
    type: {
      type: String,
      required: true,
    },
  },
  emits: ["update:tasks", "removeTask", "selectedTask"],
  data() {
    return {
      selectedTask: "",
      hoverTask: [],
    };
  },
  computed: {
    computedTasks: {
      get() {
        return this.tasks;
      },
      set(value: any) {
        this.$emit("update:tasks", value);
      },
    },
  },
  watch: {},
  methods: {
    removeTask: function (index: any) {
      delete this.computedTasks[index];
      this.computedTasks = this.computedTasks.filter((i: any) => i);
    },
    transformTaskTypes: function (type: string) {
      return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
    },
  },
});
