
import { v4 as uuid } from "uuid";
import { defineComponent } from "vue";
import { store } from "@/store";
import { getAll } from "@smace-technologies/task/src/task";

export default defineComponent({
  components: {},
  props: {
    randomReload: {},
  },
  emits: ["added"],
  data() {
    return {
      forms: [] as any,
      form: "preselect" as any,
    };
  },
  computed: {},
  watch: {
    randomReload: function () {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      this.form = "preselect";
      this.forms = await getAll(
        store.getters["getBaseWorkspaceId"],
        store.getters["getJWT"]
      );
    },
    submit: async function () {
      if (this.form === "_addBasicTask") {
        this.form = "preselect";
        const el = this.$refs["addBasicTask"] as any;
        return el.click();
      }

      const el = this.$refs["closeBtn"] as any;

      const newTask = {
        taskId: uuid(),
        type: "FORM",
        config: {
          active: true,
          userAddable: false,
          mandatory: true,
        },
        name: `New Form`,
      } as any;

      if (this.form !== "_addEmptyForm") {
        newTask["template"] = {
          templateId: this.form.templateId,
          name: this.form.name,
        };
        newTask.name = this.form.name;
      }

      this.$emit("added", newTask);
      this.form = "preselect";
      el.click();
    },
  },
});
