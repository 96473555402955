
import { defineComponent } from "vue";
import { store } from "@/store";
import CreateInternalUser from "./CreateInternalUser.vue";
import CreateExternalUser from "./CreateExternalUser.vue";
import CreatePlaceholder from "./CreatePlaceholder.vue";
import AddUserToModuleParticipants from "./AddUserToModuleParticipants.vue";
import UserList from "./UserList.vue";
import UserAccordion from "./UserAccordion.vue";


export default defineComponent({
    components: {
        CreateInternalUser,
        CreateExternalUser,
        CreatePlaceholder,
        AddUserToModuleParticipants,
        UserList,
        UserAccordion
    },
    props: {
        exactlyOne: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        checkAgainstModule: {
            type: Boolean,
            default: false,
        },
        selectedUsers: {
            type: Array,
        },
        hidePlaceholder: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            mouseOver: false,
            displayType: 'Menu',
            id: Math.floor(Math.random() * 30000),
            dropdownState: null as any,
            dropdownOpened: false,
            tmpUserId: "",
            userSearch: "",
        };
    },
    emits: ['update:selectedUsers'],
    computed: {
        workspaceName() {
            return store.getters["getBaseWorkspaceName"];
        },
        clientName() {
            return 'Buyer Comp';
        },
        module() {
            return store.getters["module/getModule"];
        },
        externalUsers() {
            if (this.userSearch) {
                return store.getters["users/getExternalUsers"].filter((u: any) => {
                    const searchStr = this.userSearch.toLowerCase();
                    const email = u.email.toLowerCase();
                    const firstName = u.data.firstName.toLowerCase();
                    const lastName = u.data.lastName.toLowerCase();

                    return email.includes(searchStr) || firstName.includes(searchStr) || lastName.includes(searchStr);
                });
            }
            return store.getters["users/getExternalUsers"];
        },
        internalUsers() {
            if (this.userSearch) {
                return store.getters["users/getUsers"].filter((u: any) => {
                    const searchStr = this.userSearch.toLowerCase();
                    const email = u.email.toLowerCase();
                    const firstName = u.data.firstName.toLowerCase();
                    const lastName = u.data.lastName.toLowerCase();

                    return email.includes(searchStr) || firstName.includes(searchStr) || lastName.includes(searchStr);
                });
            }

            return store.getters["users/getUsers"];
        },
        placeholders() {
            if (this.userSearch) {
                return store.getters["process/getPlaceholders"].filter((p: any) => {
                    const searchStr = this.userSearch.toLowerCase();
                    const name = p.name.toLowerCase();

                    return name.includes(searchStr);
                });
            }

            return store.getters["process/getPlaceholders"];
        },
    },
    mounted() {
        this.dropdownState = document.getElementById(`dropdownStep${this.id}`);
        if (this.dropdownState) {
            this.dropdownState.addEventListener("show.bs.dropdown", () => {
                document.getElementById("routerView").style.overflow = 'hidden';
                this.dropdownOpened = true;
            });
            this.dropdownState.addEventListener("hide.bs.dropdown", () => {
                document.getElementById("routerView").style.overflow = 'auto';
                this.dropdownOpened = false;
            });
        }
    },
    methods: {
        deselectAll: function () {
            this.$emit('update:selectedUsers', [])
        },
        close: function () {
            const el = this.$refs["toggleBtn"] as any;
            el.click();
        },
        remove: function (userId: string) {
            this.$emit('update:selectedUsers', this.selectedUsers.filter((u: any) => u !== userId));
        },
        addUser: function (userId: any) {
            if (this.checkAgainstModule && !this.module.participants.includes(userId)) {
                this.tmpUserId = userId;
                this.displayType = 'AddUserToModuleParticipants';
                return this.selectedUsers || [];
            }

            this.displayType = 'Menu';
            if (this.exactlyOne) {
                this.$emit('update:selectedUsers', [userId]);
                return this.close();
            }

            if (!this.selectedUsers.includes(userId)) {
                this.$emit('update:selectedUsers', [...this.selectedUsers, userId]);
            }
        },
    },
});
