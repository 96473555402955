
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
    components: {},
    props: {
        id: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
    },
    data: () => {
        return {
            errMsg: '',
            creating: false,
        };
    },
    emits: ['cancel', 'deleted'],
    mounted() {
        this.reset();
    },
    computed: {
        canStore: function () {
            return true;
        }
    },
    methods: {
        reset: function () {
            this.errMsg = '';
        },
        submit: async function () {
            this.creating = true;
            await store.dispatch('process/deleteVariable', {
                id: this.id
            }).then((res: any) => {
                this.$emit('deleted', res);
                this.reset();
            }).catch((err: any) => {
                if (!err.response || !err.response.status) {
                    this.errMsg = 'Something went wrong';
                    return;
                }
                switch (err.response.status) {
                    case 409:
                        this.errMsg = 'Variable already exists';
                        break;
                    case 422:
                        this.errMsg = `Invalid input: ${err.response.data}`;
                        break;
                    default:
                        this.errMsg = 'Something went wrong';
                        break;
                }
            });
            this.creating = false;
        }
    },
});
