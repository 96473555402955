
import { defineComponent } from 'vue';
import ContextMenu from "@/views/components/ContextMenu.vue";

export default defineComponent({
  components: {
    ContextMenu,
  },
  emits: ["addModule"],
  props: {
    stepName: {
      type: String,
    },
    stepId: {
      type: String,
    },
    stats: {
      type: Object,
    }
  },
  computed: {
    isTemplateBuilder() {
      const name = this.$route.name as string;
      return name.includes('settings');
    },
  },  
  methods: {
  },
});
