
//@ts-disable
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  data: () => {
    return {};
  },
  watch: {
    baseWorkspaceId: function () {
      this.load();
    },
  },
  computed: {},
  async mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      //store.dispatch("settingsProjectTemplates/PRELOAD_TEMPLATES");
      //store.dispatch("settingsFormTemplates/PRELOAD_TEMPLATES");
    },
  },
});
