import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import { getTaskHistoryByTaskId } from '@smace-technologies/activity-stream/task'
import { Task } from '@smace-technologies/modules/types/task';

export const key: InjectionKey<Store<TState>> = Symbol()

type ActivitiesState = {
    activityByTask: any;
    activityByModule: any;
};

const initialState = (): ActivitiesState => ({
    activityByTask: {} as any,
    activityByModule: [] as any,
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getActivitiesByTask: (state: ActivitiesState) => {
            return state.activityByTask;
        },
        getActivitiesByModule: (state: ActivitiesState) => {
            return state.activityByModule;
        },
    },
    actions: {
        async loadByTaskId(context: ActionContext<ActivitiesState, TState>, taskId: string) {
            const activities = await getTaskHistoryByTaskId(taskId, store.getters.getJWT);

            context.commit('SET_ACTIVITY_BY_TASK', activities);
        },
        async loadByModule(context: ActionContext<ActivitiesState, TState>) {
            const tasks = store.getters['tasks/getTasks'];
            const promises = [] as any;
            tasks.forEach((task: Task) => {
                promises.push(getTaskHistoryByTaskId(task.taskId, store.getters.getJWT));
            });
            const activities = await Promise.all(promises);

            const nonEmptyActivities = activities.filter(activity => activity.length > 0);

            context.commit('SET_ACTIVITY_BY_MODULE', nonEmptyActivities.flat());
        }
    },
    mutations: {
        RESET_STATE(state: ActivitiesState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_ACTIVITY_BY_TASK(state: ActivitiesState, activity: any) {
            state.activityByTask = activity;
        },
        SET_ACTIVITY_BY_MODULE(state: ActivitiesState, activities: any) {
            state.activityByModule = activities;
        }
    },
}
