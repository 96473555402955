
import { defineComponent } from "vue";
import { store } from "@/store";
import { getAll } from "@smace-technologies/task/src/task";

export default defineComponent({
  components: {},
  props: {
    randomReload: {},
  },
  emits: ["added"],
  data() {
    return {
      name: "",
      description: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    submit: function () {
      this.$emit("added", {
        name: this.name,
        description: this.description,
        weight: 0,
        done: false,
        tasks:[]
      });

      this.name = "";
      this.description = "";

      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
});
