
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    text: {},
    noIcon: {},
    position: {
      type: String,
      default: "right",
    }
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  mounted() {
    //
  },
});
