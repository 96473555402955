
import { defineComponent } from "vue";
import { store } from "@/store";
import dateFormat from "dateformat";

export default defineComponent({
  components: {
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    subTasks: function () {
      return store.getters['process/getSubTasksForParent'][this.model.taskId] || [];
    },
    subTasksStats: function () {
      return store.getters['processModules/getModuleStats'](this.model.moduleId);
    },
    totalModuleItems: function () {
      return this.subTasksStats.TOTAL;
    },
    todoProgressLineSize: function () {
      const itemsCount = this.subTasksStats.STATUS?.TODO;
      return (itemsCount / this.totalModuleItems) * 100;
    },
    inProgressProgressLineSize: function () {
      const itemsCount = this.subTasksStats.STATUS?.IN_PROGRESS;
      return (itemsCount / this.totalModuleItems) * 100;
    },
    doneProgressLineSize: function () {
      const itemsCount = this.subTasksStats.STATUS?.DONE;
      return (itemsCount / this.totalModuleItems) * 100;
    },
    stuckedProgressLineSize: function () {
      const itemsCount = this.subTasksStats.STATUS?.STIUCKED;
      return (itemsCount / this.totalModuleItems) * 100;
    }
  },
  mounted() {
    const textBox = document.getElementById('description');
    if(!textBox){
      return;
    }
    const textContent = textBox.querySelector('p');

    const lineHeight = parseInt(window.getComputedStyle(textContent).lineHeight);
    const containerHeight = textBox.clientHeight;
    const numberOfLines = Math.floor(containerHeight / lineHeight);

    if (numberOfLines > 2) {
      textBox.classList.add('show-gradient');
    }
  },
  methods: {
    getModuleTitle: function(moduleId: string){
      return store.getters["processModules/getTitle"](moduleId);
    },
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy");
    },
    toggleInternExtern() {
      store.dispatch("process/updateSingleJobField", {
        jobId: this.model.jobId,
        fieldName: 'internExtern',
        value: this.model.internExtern === 'EXTERN' ? 'INTERN' : 'EXTERN',
      });
    },
  },
});
