
import { defineComponent } from 'vue';
import { store } from '@/store';

export default defineComponent({
    props: {
        content: {
            type: Object,
            required: true,
        },
        blockId: {
            type: String,
            required: true
        },
        moduleId: {
            type: String,
            required: true
        },
        isReadOnly: {
            type: Boolean,
        }
    },
    emits: ['update:content'],
    data() {
        return {
            submittedLink: '',
            submitted: false,
            embedLink: '',
            error: '',
        }
    },
    computed: {
        computedContent: {
            get(): any {
                return this.content;
            },
            set(v: any) {
                this.$emit('update:content', v)
            },
        },
    },
});
