
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    roleId: {
      type: String,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
    };
  },
  computed: {
    role: function () {
      return store.getters["settingsRoles/getRoles"].find((r: any) => r.roleId == this.roleId);
    },
    roles: function () {
      return store.getters["settingsRoles/getRoles"];
    },
  },
  watch: {
    type: function () {
      this.name = "";
    },
  },
  mounted() {
    this.name = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;

      store.dispatch("settingsRoles/delete", this.roleId);

      this.submitted = false;
      this.name = "";

      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
});
