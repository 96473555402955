
  //@ts-disable
  import { store } from "@/store";
  import { defineComponent } from "vue";
  import dateFormat from "dateformat";
  import AddFlowModal from "./AddFlowModal.vue";
  import DeleteFlowModal from "./DeleteFlowModal.vue";
  import _ from "lodash";
  import ContextMenu from "@/views/components/ContextMenu.vue";
  import { index as indexApi } from "@smace-technologies/appmixer-integration/src/flow";
  import { authenticate } from "@smace-technologies/appmixer-integration/src/api";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
  
  export default defineComponent({
    components: {
      AddFlowModal,
      DeleteFlowModal,
      ContextMenu,
        LoadingSpinner
    },
    data: () => {
      return {
        loading: true,
        selectedItemId: "",
        flows: [] as any,
      };
    },
    watch: {
      baseWorkspaceId: function () {
        this.load();
      },
    },
    computed: {
      baseWorkspaceId: function () {
        return store.getters["getBaseWorkspaceId"];
      },
    },
    async mounted() {
      this.load();
    },
    methods: {
      go: function (flowId: string) {
        this.$router.push(`/settings/automations/${flowId}`);
      },
      format: function (time: string) {
        return dateFormat(time, "dd.mm.yyyy HH:MM");
      },
      load: async function () {
        this.flows = await indexApi(this.baseWorkspaceId, store.getters["getJWT"]);
        this.loading = false;
      },
    },
  });
  