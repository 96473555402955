
import { defineComponent } from 'vue';
import ModuleShow from './ModuleShow.vue';
import { store } from '@/store';
import { debounce } from "lodash";
import dateFormat from "dateformat";
import BlocksWrapper from './components/BlocksWrapper.vue';
import TableOfContent from "./components/TableOfContent/TableOfContent.vue";

export default defineComponent({
    components: {
        BlocksWrapper,
        ModuleShow,
        TableOfContent,
    },
    data() {
        return {
            loaded: false,
            processId: null,
        }
    },
    computed: {
        computedParticipants:  {
            get() {
                return store.getters["module/getModule"].participants || [];
            },
            set(v: any) {
                store.dispatch("module/updateModule", {
                    ...this.module,
                    participants: v,
                });
            },
        },
        module: function () {
            return store.getters["module/getModule"];
        },
        milestoneName: function () {
            return store.getters["module/getMilestoneName"];
        },
        processName: function () {
            return store.getters["process/getProcess"].name;
        },
        isTemplate: function (): boolean {
            return store.getters["process/getProject"].projectId === 'TEMPLATE';
        },
        getTaskStats: function () {
            return store.getters["moduleBlocks/getTaskStats"];
        },
        getProject: function () {
            return store.getters["process/getProject"];
        },
        minDuration: function () {
            const minDuration = store.getters["moduleBlocks/getTaskStats"]?.minDuration;
            if (!minDuration) return;
            return dateFormat(minDuration, "dd.mmm");
        },
        userList: function () {
            return store.getters['moduleBlocks/getBlocks']
                .filter((b: any) => b.type === 'task')
                .filter((b: any) => b.content.assignee)
                .map((b: any) => b.content.assignee)
        },
        headline: function () {
            return store.getters["module/getModule"].title || '';
        },
    },
    watch: {
        headline: {
            handler: function (v) {
                this.resizeHeadline();
            },
            immediate: true,
        },
        module: {
            handler: function () {
                if (this.loaded != true) {
                    this.loaded = true;
                    return;
                }
                this.debounceStore(() => {
                    this.resizeHeadline();
                    this.updateModule();
                });
            },
            deep: true,
        },
    },
    methods: {
        resizeHeadline: function () {
            const el = this.$refs["inlineHeadline"] as any;
            if (!el) {
                return;
            }

            const offset = el.offsetHeight - el.clientHeight;
            el.style.height = 'auto';
            el.style.height = el.scrollHeight + offset + 'px';
        },
        goToProject: function () {
            if (this.isTemplate) {
                this.$router.push({ name: 'settings-processes' });
            }
        },
        goToProcess: function () {
            if (this.isTemplate) {
                this.$router.push({ name: 'settings-process', params: { typeId: this.module.processId } });
            } else {
                this.$router.push({ name: 'process-show', params: { projectId: this.getProject.projectId, processId: this.module.processId } });
            }
        },
        updateModule: function () {
            store.dispatch("module/updateModule", this.module);
        },
        debounceStore: debounce(function (fnc) {
            fnc();
        }, 750),
    },
    mounted() {
        this.processId = this.$route.params.processId;
        this.debounceStore(() => this.resizeHeadline());
    }
});
