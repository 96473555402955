import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-711dfc0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "processTaskBoard" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "white-background" }
const _hoisted_5 = { class: "page h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_process_header = _resolveComponent("process-header")!
  const _component_milestone_modules_kanban = _resolveComponent("milestone-modules-kanban")!
  const _component_task_list = _resolveComponent("task-list")!
  const _component_share_project_modal = _resolveComponent("share-project-modal")!
  const _component_client_users_modal = _resolveComponent("client-users-modal")!
  const _component_AddNewModal = _resolveComponent("AddNewModal")!
  const _component_ImportSubprocessModal = _resolveComponent("ImportSubprocessModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_LoadingSpinner, { class: "loading-spinner" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_process_header, {
              onSetShowTaskList: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showTaskList = $event)),
              onSetShowOnlyCompletedModule: _cache[1] || (_cache[1] = ($event: any) => {_ctx.showOnlyCompletedModule = $event;})
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            (!_ctx.showTaskList)
              ? (_openBlock(), _createBlock(_component_milestone_modules_kanban, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.showTaskList)
              ? (_openBlock(), _createBlock(_component_task_list, { key: 1 }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_share_project_modal, { onReload: _ctx.load }, null, 8, ["onReload"]),
    _createVNode(_component_client_users_modal, { onReload: _ctx.load }, null, 8, ["onReload"]),
    _createVNode(_component_AddNewModal),
    _createVNode(_component_ImportSubprocessModal, { onReload: _ctx.load }, null, 8, ["onReload"])
  ]))
}