
import { store } from '@/store';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    users: {
      type: Array,
    },
    smallAvatarSize: {
      type: Boolean,
      default: false
    },
    middleAvatarSize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultIcons: [
        {
          avatar: require("@/assets/icons/user-icon.svg")
        },
        {
          avatar: require("@/assets/icons/user-icon.svg")
        },
        {
          avatar: require("@/assets/icons/user-icon.svg")
        },
      ]
    };
  },
  computed: {
    usersWithDefault: function () {
      if (!this.users.length || !Array.isArray(this.users)) {
        return this.defaultIcons;
      }
      
      return this.users.map((u) => {
        if (typeof u === 'string') {
          return store.getters["users/resolveUser"](u);
        }
        return u;
      }).filter((u) => u && (!!u.userId || !!u.placeholderId))
    },
  },
  methods: {
    isPlaceholder: function (user: any) {
      return user?.placeholderId;
    },
    getInitials: function (user: any) {
      if (user?.data?.firstName || user?.data?.lastName) {
        return (user?.data?.firstName.substring(0, 1).toUpperCase() || "") + (user?.data?.lastName.substring(0, 1).toUpperCase() || "");
      } else if (user && user.name) {
        return (user.name.substring(0, 1).toUpperCase() || "");
      } else {
        return user?.email?.substring(0, 1).toUpperCase();
      }
    }
  },
});
