
import { defineComponent } from "vue";
import { store } from "@/store";
import { update as updateApi } from "@smace-technologies/workspace/src/workspace";

export default defineComponent({
  components: {},
  props: {},
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
    };
  },
  computed: {
    workspaceName: function () {
      return store.getters["getBaseWorkspaceName"];
    },
  },
  watch: {
    workspaceName: {
      handler() {
        this.name = this.workspaceName;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
   
    submit: async function () {
      this.submitted = true;
      await updateApi(
        store.getters["getBaseWorkspaceId"],
        { name: this.name },
        store.getters["getJWT"]
      );
      store.dispatch("setBaseWorkspace", {
        ...store.getters["getBaseWorkspace"],
        name: this.name,
      });
      this.submitted = false;
    },
  },
});
