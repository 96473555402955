
import { defineComponent } from "vue";
import LogoAndName from "@/views/components/LogoAndName.vue";

export default defineComponent({
    components: {
        LogoAndName
    },
    props: {
        hidePlaceholder: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array
        },
        sectionType: {
            type: String
        },
        trashIcon: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String
        },
    },
    data: () => {
        return {
            accordionOpened: true,
        };
    },
    emits: ['addPlaceholder', 'addUser', 'create', 'deleteIcon', 'clearAll'],
    methods: {
        toggleAccordion() {
            this.accordionOpened = !this.accordionOpened;
        },
        callActionEmit() {
            if (this.sectionType === "internalUsers") {
                this.$emit('create', 'CreateInternaluser');
            } else if (this.sectionType === "externalUsers") {
                this.$emit('create', 'CreateExternalluser');
            } else if (this.sectionType === "selectedUsers") {
                this.$emit('clearAll',);
            } else {
                this.$emit('create', 'CreatePlaceholder');
            }
        },
        callItemEmit(item: any) {
            if (this.sectionType === "internalUsers") {
                this.$emit('addUser', item);
            } else if (this.sectionType === "externalUsers") {
                this.$emit('addUser', item);
            } else {
                this.$emit('addPlaceholder', item);
            }
        }

    },
});
