
//@ts-disable
import short from "short-uuid";
import { store } from "@/store";
import dateFormat from "dateformat";
import { defineComponent } from "vue";


export default defineComponent({
  components: {
  },
  data: () => {
    return {
      selectedJobType: "",
      selectedJob: {},
      selectedTask: {},
    };
  },
  computed: {
    doneJobs: {
      get() {
        return store.getters["tasks/getDoneJobs"];
      },
      set(val: any) {
        store.dispatch("tasks/updateDoneJobs", val);
      },
    },
    activeJobs: {
      get() {
        return store.getters["tasks/getActiveJobs"];
      },
      set(val: any) {
        store.dispatch("tassks/updateActiveJobs", val);
      },
    },
    inActiveJobs: {
      get() {
        return store.getters["tasks/getInActiveJobs"];
      },
      set(val: any) {
        store.dispatch("tasks/updateInActiveJobs", val);
      },
    },
  },
  async mounted() {
    this.load();
  },
  methods: {
    shortenUUID: function (projectId: string | any) {
      return short().fromUUID(String(projectId));
    },
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy HH:MM");
    },
    load: async function () {
     // store.dispatch("tasks/load");
    },
  },
});
