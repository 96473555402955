import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0413d502"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-selection" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  id: `collapseSteps`,
  class: "accordion-collapse",
  "aria-labelledby": `headingSteps`,
  "data-bs-parent": "#accordion"
}
const _hoisted_4 = { class: "dropdown" }
const _hoisted_5 = ["aria-labelledby"]
const _hoisted_6 = { class: "label d-flex justify-content-between align-items-center" }
const _hoisted_7 = { class: "scrollable-list-parent" }
const _hoisted_8 = { class: "scrollable-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateInternalUser = _resolveComponent("CreateInternalUser")!
  const _component_CreateExternalUser = _resolveComponent("CreateExternalUser")!
  const _component_CreatePlaceholder = _resolveComponent("CreatePlaceholder")!
  const _component_AddUserToModuleParticipants = _resolveComponent("AddUserToModuleParticipants")!
  const _component_feather_icon = _resolveComponent("feather-icon")!
  const _component_UserAccordion = _resolveComponent("UserAccordion")!
  const _component_UserList = _resolveComponent("UserList")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", {
      id: `dropdownStep${_ctx.id}`,
      class: "slot-wrapper",
      "data-bs-toggle": "dropdown",
      "data-bs-auto-close": "outside",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayType = 'Menu')),
      "aria-expanded": "false",
      ref: "toggleBtn"
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", {
          class: "dropdown-menu step-user-seclection",
          "aria-labelledby": `dropdownStep${_ctx.id}`
        }, [
          (_ctx.displayType == 'CreateInternaluser')
            ? (_openBlock(), _createBlock(_component_CreateInternalUser, {
                key: 0,
                id: _ctx.id,
                onAddUser: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addUser($event.userId))),
                onCancel: _ctx.close
              }, null, 8, ["id", "onCancel"]))
            : _createCommentVNode("", true),
          (_ctx.displayType == 'CreateExternalluser')
            ? (_openBlock(), _createBlock(_component_CreateExternalUser, {
                key: 1,
                id: _ctx.id,
                onAddUser: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addUser($event.userId))),
                onCancel: _ctx.close
              }, null, 8, ["id", "onCancel"]))
            : _createCommentVNode("", true),
          (_ctx.displayType == 'CreatePlaceholder')
            ? (_openBlock(), _createBlock(_component_CreatePlaceholder, {
                key: 2,
                id: _ctx.id,
                onAddPlaceholder: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addUser($event.placeholderId))),
                onCancel: _ctx.close
              }, null, 8, ["id", "onCancel"]))
            : _createCommentVNode("", true),
          (_ctx.displayType == 'AddUserToModuleParticipants')
            ? (_openBlock(), _createBlock(_component_AddUserToModuleParticipants, {
                key: 3,
                module: _ctx.module,
                userId: _ctx.tmpUserId,
                onAdded: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addUser(_ctx.tmpUserId))),
                onCancel: _ctx.close
              }, null, 8, ["module", "userId", "onCancel"]))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
              _createVNode(_component_feather_icon, {
                type: "x",
                stroke: "rgba(31, 50, 69, 0.5)",
                size: "20",
                onClick: _ctx.close
              }, null, 8, ["onClick"])
            ]),
            _withDirectives(_createElementVNode("input", {
              class: "search",
              type: "text",
              placeholder: "Search for user",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.userSearch) = $event))
            }, null, 512), [
              [_vModelText, _ctx.userSearch]
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_UserAccordion, {
                  list: _ctx.selectedUsers,
                  sectionType: 'selectedUsers',
                  title: _ctx.exactlyOne ? 'Selected user' : 'Selected users',
                  trashIcon: true,
                  onDeleteIcon: _cache[6] || (_cache[6] = (item) => _ctx.remove(item)),
                  onClearAll: _ctx.deselectAll
                }, null, 8, ["list", "title", "onClearAll"]),
                _createVNode(_component_UserList, {
                  id: _ctx.id,
                  hidePlaceholder: _ctx.hidePlaceholder,
                  onAddUser: _cache[7] || (_cache[7] = ($event: any) => (_ctx.addUser($event.userId))),
                  onAddPlaceholder: _cache[8] || (_cache[8] = ($event: any) => (_ctx.addUser($event.placeholderId))),
                  onCreate: _cache[9] || (_cache[9] = ($event: any) => (_ctx.displayType = $event)),
                  onRemove: _ctx.remove,
                  externalUsers: _ctx.externalUsers,
                  internalUsers: _ctx.internalUsers,
                  placeholders: _ctx.placeholders
                }, null, 8, ["id", "hidePlaceholder", "onRemove", "externalUsers", "internalUsers", "placeholders"])
              ])
            ])
          ], 512), [
            [_vShow, _ctx.displayType == 'Menu']
          ])
        ], 8, _hoisted_5)
      ])
    ])
  ]))
}