import ProcessesIndex from '../views/pages/settings/processes/index/ProcessesIndex.vue'
import ProcessView from '../views/pages/settings/processes/show/ProcessView.vue'
import FormEditor from '../views/pages/settings/processes/show/task/FormEditor.vue'
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/settings/processes',
        name: 'settings-processes',
        component: ProcessesIndex,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/settings/process/:typeId',
        name: 'settings-process',
        component: ProcessView,
        meta: {
            requiresAuth: true,
            subNavType: ' '
        }
    },
    {
        path: '/settings/job/task-editor/:templateId/:taskId',
        name: 'settings-job-task-editor',
        component: FormEditor,
        meta: {
            requiresAuth: true,
            subNavType: ''
        }
    },
];

export default routes;