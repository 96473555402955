
import { defineComponent } from "vue";

export default defineComponent({
  components: {
  },
  props: {
    status: {
      type: String,
    }
  },
  emits: ["update:status"],
  computed: {
    computedStatus: {
      get(): string {
        return this.status || "TODO";
      },
      set(value: any) {
        this.$emit("update:status", value);
      },
    },
  },
  data() {
    return {
    }
  }
})
