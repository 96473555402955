
//@ts-disable
import { store } from "@/store";
import TaskCard from "./TaskCard.vue";
import { defineComponent } from "vue";
import draggable from "vuedraggable";

export default defineComponent({
  components: {
    draggable,
    TaskCard
  },
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  emits: ["selectedmodule", "update:tasks", "setSelectedJob", "addModule"],
  data: () => {
    return {
      hoverJob: [] as any,
      drag: false,
    };
  },
  computed: {
    computedTasks: {
      get() {
        return this.tasks;
      },
      set(val: any) {
        return store.dispatch("processTasks/updateTasks", { tasks: val, status: this.status });
      },
    },
  },
  methods: {
    goTo: function (module: any) {
      this.$router.push({
        name: "module",
        params: {
          moduleId: module.moduleId,
          processId: store.getters["process/getProcessId"],
        },
      });
    },
    goToFormBuilder: function (moduleId: string) {
      this.$router.push({
        name: "module-form-step",
        params: {
          moduleId: moduleId,
          projectId: this.$route.params.projectId,
          stepId: "step1",
        },
      });
    },
  },
});
