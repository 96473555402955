import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2213a086"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hubspot-meeting" }
const _hoisted_2 = ["src", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      src: `${_ctx.link}?embed=true&parentHubspotUtk=8afc91b7caf4946f2e8216de73e1e389&parentPageUrl=http://localhost:8080/`,
      width: "100%",
      height: `${_ctx.height}px;`
    }, null, 8, _hoisted_2)
  ]))
}