
import router from "@/router";
import { store } from "@/store";
import AddWorkspaceModal from "./AddWorkspaceModal.vue";
import SearchAndUserInfo from "./SearchAndUserInfo.vue";
import { defineComponent } from "vue";
import { getCustomerPortalUrl } from "@/services/serviceUrls";

export default defineComponent({
  components: {
    AddWorkspaceModal,
    SearchAndUserInfo,
  },
  data() {
    return {
      externalUser: false,
    };
  },
  watch: {
  },
  computed: {
    name: function () {
      return store.getters["getBaseWorkspaceName"];
    },
    config: function (): any {
      return store.getters["getBaseWorkspaceConfig"];
    },
    customerPortalLink: function () {
      const config = store.getters["getBaseWorkspaceConfig"];
      const base = process.env.VUE_APP_CUSTOMER_PORTAL_BASE_URL;
      if(config.url){
        return getCustomerPortalUrl(config.url.prefix);
      }
      return null;
    },
    logo: function (): any {
      return this.config && this.config.images ? this.config.images.logo : "";
    },
    baseWorkspaceId: function (): any {
      return store.getters["getBaseWorkspaceId"];
    },
    workspaces: function () {
      return store.getters["getWorkspaces"];
    },
    highlightExecution: function () {
      return ['module', 'process-show', 'moduleContext', 'moduleTasks'].includes(String(this.$route.name)) && store.getters["process/getProject"].projectId !== 'TEMPLATE';
    },
    highlightBlueprint: function () {
      return ['module', 'settings-process', 'moduleContext', 'moduleTasks'].includes(String(this.$route.name)) && store.getters["process/getProject"].projectId === 'TEMPLATE';
    },
  },
  methods: {
    selectWorkspace: function (data: any) {
      store.dispatch("setBaseWorkspace", {
        ...data,
      });
    },
    goTo: function (params: any) {
      router.push(params).catch();
    },
  },
});
