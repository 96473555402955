import { RouteRecordRaw } from "vue-router";
import SelectWorkspace from '../views/pages/registration/SelectWorkspace.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/select-workspace/',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'select-workspace',
                component: SelectWorkspace,
                meta: {
                    requiresAuth: true,
                    layout: 'full'
                }
            },
        ]
    },
];

export default routes;