
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  computed: {
    profile: function() {
      return store.getters["user/getUserProfile"].data;
    }
  },
  methods: {
    updateProfile: async function () {
      const data = this.profile;
      if (!data.gender) {
        delete data.gender;
      }

      store.dispatch("user/updateUser", {userId: store.getters["user/getUserId"], profile:data});
    },
  },
  async mounted() {
    await store.dispatch("user/load");
  },
});
