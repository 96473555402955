
  import { defineComponent } from "vue";
  import { store } from "@/store";
  import TaskCard from "../components/blocks/components/task/TaskCard.vue";
  
  export default defineComponent({
    components: {
      TaskCard,
    },
    props: {
      task: {
        type: Object
      },
    },
    emits: ["reload"],
    data() {
      return {
        submitted: false,
      };
    },
    computed: {},
    methods: {
      submit: async function () {
        this.submitted = true;
        store.dispatch('task/deleteTask', {
            taskId: this.task.taskId,
            moduleId: this.task.moduleId,
        });
        this.submitted = false;
        const el = this.$refs["closeBtn"] as any;
        el.click();
      },
    },
  });
  