<template>
  <div id="app" class="">
    <component :is="layout"></component>
  </div>
</template>

<script>
import LayoutDefault from "./views/layout/LayoutDefault.vue";
import LayoutFull from "./views/layout/LayoutFull.vue";
import { store } from './store';

export default {
  components: {
    LayoutDefault,
    LayoutFull,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "LayoutFull";
      return `LayoutDefault`;
    },
  },
  mounted() {
    const domain = window.location.hostname;
    if (domain.startsWith('v2.smace.io') || domain.startsWith('eu.smace.io')) {
      return this.$router.push({ name: 'select-workspace' });
    } else if (domain === 'localhost' && !store.getters['getStoredWorkspaceId']) {
      return this.$router.push({ name: 'select-workspace' });
    }

    store.dispatch("LOAD_BY_ORIGIN");
  },
};
</script>
