import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c30855ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "blocks" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tasks_wrapper = _resolveComponent("tasks-wrapper")!
  const _component_module_show = _resolveComponent("module-show")!

  return (_openBlock(), _createBlock(_component_module_show, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tasks_wrapper)
      ])
    ]),
    _: 1
  }))
}