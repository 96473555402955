
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  emits: ["deleted"],
  data() {
    return {
      name: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    submit: function () {
      this.$emit("deleted", {});
      this.name = "";
      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
});
