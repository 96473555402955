const getCustomerPortalBaseUrl = () => {
    if (process.env.VUE_APP_SDK_ENVIRONMENT === 'production') {
        return 'cp.eu.smace.io';
    }
    return 'cp.v2.smace.io';
};

const getFormBuilderBaseUrl = () => {
    if (process.env.VUE_APP_SDK_ENVIRONMENT === 'production') {
        return 'https://form.eu.smace.io/#';
    }
    return 'https://form.v2.smace.io/#';
};

export const getAutomationBaseUrl = () => {
    if (process.env.VUE_APP_SDK_ENVIRONMENT === 'production') {
        return 'https://automation.eu.smace.io';
    }
    return 'https://automation.v2.smace.io';
};


export const getCustomerPortalUrl = (prefix: string) => {
    return `https://${prefix}.${getCustomerPortalBaseUrl()}/#`;
};

export const getFormBuilderUrl = (workspaceId: string, formId: string) => {
    return `${getFormBuilderBaseUrl()}/builder/${workspaceId}/${formId}`;
}

export const getFormViewerUrl = (workspaceId: string, projectId: string, formId: string, moduleId: string, blockId: string, config: any) => {
    return `${getFormBuilderBaseUrl()}/viewer/${workspaceId}/${projectId}/${formId}/${moduleId}/${blockId}${config && Object.keys(config).length ? '?' + new URLSearchParams(config).toString() : ''}`;
}

export const getDemoFormViewerUrl = () => {
    return `${getFormBuilderBaseUrl()}/preview/234234234`;
}

/*
// "real" projects
export const getFormBuilderProjectUrl = (workspaceId: string, jobId: string, taskId: string) => {
    return `${getFormBuilderBaseUrl()}/project/builder/${workspaceId}/${jobId}/${taskId}`;
}*/
