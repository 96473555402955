
import { defineComponent, isReadonly } from "vue";
import { store } from "@/store";
import dateFormat from "dateformat";

export default defineComponent({
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  computed: {
    status: function () {
      return this.model.status || 'PENDING';
    },
    subTasksStats: function () {
      return store.getters['processModules/getModuleStats'](this.model.moduleId);
    },
    subProcessTag: function () {
      if (
        this.model.origin &&
        this.model.origin.processTypeId !== this.model.origin.processId
      ) {
        return this.model.origin.processName
      }
      return '';
    },
    totalModuleItems: function () {
      return this.subTasksStats.TOTAL;
    },
    todoProgressLineSize: function () {
      const itemsCount = this.subTasksStats.STATUS?.TODO;
      return (itemsCount / this.totalModuleItems) * 100;
    },
    inProgressProgressLineSize: function () {
      const itemsCount = this.subTasksStats.STATUS?.IN_PROGRESS;
      return (itemsCount / this.totalModuleItems) * 100;
    },
    doneProgressLineSize: function () {
      const itemsCount = this.subTasksStats.STATUS?.DONE;
      return (itemsCount / this.totalModuleItems) * 100;
    },
    stuckedProgressLineSize: function () {
      const itemsCount = this.subTasksStats.STATUS?.STUCKED;
      return (itemsCount / this.totalModuleItems) * 100;
    },
    isRolledOut: function () {
      return this.model.projectId !== 'TEMPLATE';
    },
  },
  methods: {
    goTo: function () {
      this.$router.push({
        name: 'moduleTasks',
        params: {
          moduleId: this.model.moduleId,
          processId: this.model.processId,
        },
      });
    },
    generateRandomColor: function (seed: string) {
      let hash = 0;
      for (let i = 0; i < seed.length; i++) {
        hash = (hash << 5) - hash + seed.charCodeAt(i);
        hash |= 0;
      }

      let hex = (hash >>> 0).toString(16);
      while (hex.length < 6) {
        hex = '0' + hex;
      }

      let r = parseInt(hex.slice(0, 2), 16);
      let g = parseInt(hex.slice(2, 4), 16);
      let b = parseInt(hex.slice(4, 6), 16);

      const minBrightness = 127;
      r = Math.max(r, minBrightness);
      g = Math.max(g, minBrightness);
      b = Math.max(b, minBrightness);

      hex = ((r << 16) | (g << 8) | b).toString(16);
      while (hex.length < 6) {
        hex = '0' + hex;
      }

      return `#${hex.substring(0, 6)}`;
    },
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy");
    },
    toggleInternExtern() {
      store.dispatch("process/updateSingleJobField", {
        jobId: this.model.jobId,
        fieldName: 'internExtern',
        value: this.model.internExtern === 'EXTERN' ? 'INTERN' : 'EXTERN',
      });
    },
    changeModuleStatus(status: string) {
      if (status === 'DONE') {
        store.dispatch("processModules/setSelectedModule", {
          ...this.model,
        });
        const el = this.$refs["openModal"] as any;
        el.click();

        return;
      }

      if (this.model.status === 'PENDING') {
        store.dispatch("processModules/activateModule", {
          ...this.model,
        });

        return;
      }

      store.dispatch("processModules/changeModuleStatus", {
        ...this.model,
        status,
      });
    },
  },
});
