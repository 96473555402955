import { store } from "@/store";
import { store as storeProcessApi } from "@smace-technologies/process/src/process";

export async function createProcess(data: any, percentageCallback: any) {
    const expectedStoringtime = 5500;
    let client = {} as any;
    let project = {} as any;

    project = { projectId: data.projectData.projectId };
    client = { clientId: data.clientData.clientId };

    percentageCallback(0);

    for (let i = 1; i <= 9; i++) {
        setTimeout(() => percentageCallback(i * 10), expectedStoringtime / 10 * i);
    }

    const process = await storeProcessApi(
        store.getters["getBaseWorkspaceId"],
        project.projectId,
        {
            name: 'Not Used - Template Name Will Be Used',
            typeId: data.processData.processTemplateId,
            metaData: {
                //metaData: [],
                projectStart: data.datesData.startDate,
                projectEnd: data.datesData.endDate,
                subProcesses: data.processData.subProcesses,
                placeholderMatching: data.placeholderMatching,
                variables: data.variables,
            }
        },
        store.getters["getJWT"]
    );

    percentageCallback(100);

    return {
        client,
        project,
        process
    };
}