import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "secondary-sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountSettingsSubNav = _resolveComponent("AccountSettingsSubNav")!
  const _component_ProcessTemplateSubNav = _resolveComponent("ProcessTemplateSubNav")!

  return (_openBlock(), _createElementBlock("div", {
    id: "sidebar",
    class: _normalizeClass({ unfolded: _ctx.unfolded })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isSubNav('ACCOUNT_SETTINGS'))
        ? (_openBlock(), _createBlock(_component_AccountSettingsSubNav, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.isSubNav('PROJECT_TEMPLATE'))
        ? (_openBlock(), _createBlock(_component_ProcessTemplateSubNav, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ], 2))
}