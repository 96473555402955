
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
  },
  props: {
    stepName: {
      type: String,
      required: true,
    },
    stepId: {
      type: String,
    },
    stats: {
      type: Object,
      required: true,
    }
  },
  computed: {
    isTemplateBuilder() {
      const name = this.$route.name as string;
      return name.includes('settings');
    },
  },  
  methods: {
  },
});
