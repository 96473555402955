
import { defineComponent } from "vue";

export default defineComponent({
  components: {
  },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
});
