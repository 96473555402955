
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    typeId: {
      type: String,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
    };
  },
  computed: {
    type: function () {
      return store.getters["settingsProcesses/getSingleModules"].find((t: any) => t.moduleId === this.typeId)
    },
  },
  watch: {
    type: function () {
      this.name = this.type?.title;
    }
  },
  mounted() {
    this.name = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;

      store.dispatch("settingsProcesses/renameSingleModule", { title: this.name, moduleId: this.typeId });

      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;

      el.click();
      this.$emit("reload");
    },
  },
});
