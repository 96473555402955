import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06ec073e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  id: "renameRoleModal",
  tabindex: "-1",
  "data-bs-backdrop": "true",
  "aria-labelledby": "renameRoleModal",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = {
  type: "button",
  class: "btn-close",
  "data-bs-dismiss": "modal",
  "aria-label": "Close",
  ref: "closeBtn"
}
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = {
  for: "name",
  class: "form-label"
}
const _hoisted_10 = { class: "modal-footer" }
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, "Rename \"" + _toDisplayString(_ctx.role?.name) + "\"", 1),
          _createElementVNode("button", _hoisted_6, null, 512)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.loading ? "Loading" : "Role name"), 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              id: "name",
              placeholder: "Onboarding role name",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
              onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["enter"]))
            }, null, 544), [
              [_vModelText, _ctx.name]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary ms-2",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
            disabled: !_ctx.name?.length || _ctx.submitted
          }, _toDisplayString(_ctx.submitted ? "Renaming" : "Rename"), 9, _hoisted_11)
        ])
      ])
    ])
  ]))
}