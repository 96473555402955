
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    typeId: {
      type: String,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
    };
  },
  computed: {
    type: function () {
      return store.getters["settingsProcesses/getTypes"].find((t: any) => t.typeId == this.typeId);
    },
    types: function () {
      return store.getters["settingsProcesses/getTypes"];
    },
  },
  watch: {
    type: function () {
      this.name = "";
    },
  },
  mounted() {
    this.name = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;

      await store.dispatch("settingsProcesses/deleteAllBlueprints", this.typeId);

      this.submitted = false;
      this.name = "";

      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
});
