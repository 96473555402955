
import { store } from "@/store";
import { debounce } from "lodash";
import dateFormat from "dateformat";
import { defineComponent } from "vue";
import draggable from "vuedraggable";
import MilestoneProgressLine from "./MilestoneProgressLine.vue";
import ModulesElement from "./ModulesElement.vue";
import AddStepModal from "../modals/AddStepModal.vue";
import AddModuleModal from "../modals/AddModuleModal.vue";
import RenameStepModal from "../modals/RenameStepModal.vue";
import DeleteStepModal from "../modals/DeleteStepModal.vue";
import ModuleDoneWithTasksModal from "@/views/pages/module/modals/ModuleDoneWithTasksModal.vue";

export default defineComponent({
  components: {
    MilestoneProgressLine,
    ModulesElement,
    draggable,
    AddStepModal,
    AddModuleModal,
    RenameStepModal,
    DeleteStepModal,
    ModuleDoneWithTasksModal
  },
  props: {
    typeId: {
      type: String
    }
  },
  data: () => {
    return {
      selectedModule: {} as any,
    };
  },
  computed: {
    getProcessTypeId: () => {
      return store.getters["process/getProcessTypeId"];
    },
    modulesByMiletsones: {
      get() {
        return store.getters["processModules/getModulesByMilestones"];
      },
      set(v: any) {
        store.dispatch("processModules/updateMilestonesWeight", v);
      },
    },
    isReadOnly: () => {
      return store.getters["process/getProject"].projectId === 'TEMPLATE';
    },
    getProcessId: () => {
      return store.getters["process/getProcessId"];
    },
    getProjectId: () => {
      return store.getters["process/getProjectId"];
    },
  },
  methods: {
    toggleModuleDone() {
      store.dispatch("processModules/changeModuleStatus", {
        ...store.getters["processModules/getSelectedModule"],
        status: store.getters["processModules/getSelectedModule"].status === 'DONE' ? 'ACTIVE' : 'DONE'
      });
    },
    addModule: async function (step: any) {
      this.$router.push({
        name: 'add-module', params: {
          stepId: step.stepId,
          processId: this.getProcessId,
          projectId: this.getProjectId,
        }
      });
    },
    debounceStore: debounce(function (fnc) {
      fnc();
    }, 750),
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy HH:MM");
    },
  },
});
