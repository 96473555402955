import { RouteRecordRaw } from "vue-router";
import ModuleShow from '../views/pages/module/ModuleShow.vue'
import ModuleAdd from '../views/pages/module/ModuleAdd.vue'
import ModuleContext from '../views/pages/module/ModuleContext.vue'
import ModuleTasks from '../views/pages/module/ModuleTasks.vue'
import ModuleActivity from '../views/pages/module/ModuleActivity.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/module/',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: ':processId/:moduleId',
                redirect: {name: 'moduleTasks'},
            },
            {
                path: ':processId/:moduleId/context',
                name: 'moduleContext',
                component: ModuleContext,
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: ':processId/:moduleId/tasks',
                name: 'moduleTasks',
                component: ModuleTasks,
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: ':processId/:moduleId/activity',
                name: 'moduleActivity',
                component: ModuleActivity,
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: 'template/:processId/:moduleId',
                name: 'moduleTemplate',
                component: ModuleShow,
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: 'add/:processId/:stepId/:projectId',
                name: 'add-module',
                component: ModuleAdd,
                meta: {
                    requiresAuth: true,
                }
            },
        ]
    },
];

export default routes;