
import { defineComponent } from "vue";
import { store as storeApi } from "@smace-technologies/workspace/src/workspace";
import { store } from "@/store";

export default defineComponent({
  components: {},
  data() {
    return {
      submitted: false,
      name: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    submit: async function () {
      this.submitted = true;
      const res = await storeApi({ name: this.name }, store.getters["getJWT"]);
      this.submitted = false;
      store.dispatch("setBaseWorkspace", {
        ...res
      });
      const el = this.$refs["closeBtn"] as any;
      store.dispatch("loadWorkspaces");
      this.$emit("reload");
      el.click();
      this.name = '';
    },
  },
});
