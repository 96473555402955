import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "automation" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "back-button" }
const _hoisted_4 = ["src", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: { name: 'settings-automations-index' } }, {
          default: _withCtx(() => [
            _createTextVNode(" Back ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("iframe", {
        src: _ctx.iframeSrc,
        height: _ctx.windowHeight - 60
      }, null, 8, _hoisted_4)
    ])
  ]))
}