import FormIndex from '../views/pages/settings/forms/index/FormIndex.vue'
import FormEditor from '../views/pages/settings/forms/show/FormEditor.vue'
import FormViewer from '../views/pages/settings/forms/show/FormViewer.vue'
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/settings/forms',
        name: 'settings-forms',
        component: FormIndex,
        meta: {
            requiresAuth: true,
            subNavType: 'ACCOUNT_SETTINGS'
        }
    },
    {
        path: '/settings/task/form-editor/:formId',
        name: 'settings-form-editor',
        component: FormEditor,
        meta: {
            subNavType: ''
        }
    },
    {
        path: '/settings/task/form-viewer/:formId/:taskId',
        name: 'settings-form-viewer',
        component: FormViewer,
        meta: {
            subNavType: ''
        }
    },
];

export default routes;