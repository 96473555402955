import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6856a7e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "scroll-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_task_list_progress_line = _resolveComponent("task-list-progress-line")!
  const _component_tasks_element = _resolveComponent("tasks-element")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_draggable, {
      modelValue: _ctx.statusList,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.statusList) = $event)),
      group: "steps",
      "item-key": "stepId",
      class: "row h-100",
      disabled: !_ctx.isReadOnly
    }, {
      item: _withCtx(({ element }) => [
        _createElementVNode("div", {
          class: "col-md-3 h-100 d-flex flex-column process-column",
          onClick: ($event: any) => {_ctx.selectedModule = element;}
        }, [
          _createVNode(_component_task_list_progress_line, {
            stepName: element.name,
            stepId: element.stepId,
            stats: element.stats
          }, null, 8, ["stepName", "stepId", "stats"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_tasks_element, {
              status: element.status,
              tasks: element.tasks,
              "onUpdate:tasks": ($event: any) => ((element.tasks) = $event),
              onSelectedTask: _cache[0] || (_cache[0] = ($event: any) => {_ctx.selectedModule = $event;})
            }, null, 8, ["status", "tasks", "onUpdate:tasks"])
          ])
        ], 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["modelValue", "disabled"])
  ]))
}