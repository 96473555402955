
import CreateExternalUser from "@/views/components/UserSelection/CreateExternalUser.vue";
import UserSelection from "@/views/components/UserSelection/UserSelection.vue";
import CreatePlaceholder from "@/views/components/UserSelection/CreatePlaceholder.vue";
import GlobalVariablesSection from "./GlobalVariablesSection.vue";
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {
    CreateExternalUser,
    CreatePlaceholder,
    GlobalVariablesSection,
    UserSelection
  },
  props: {
    stepId: {
      type: String,
    },
    weight: {
      type: Number
    },
  },
  emits: ["reload", "added"],
  data() {
    return {
      submitted: false,
      createExternalUser: false,
      addPlaceholder: false,
      selectedPlaceholder: '',
      replacingUser: "",
      step: 'users',
    };
  },
  computed: {
    externalUser: function () {
      return store.getters["users/getExternalUsers"];
    },
    internalUser: function () {
      return store.getters["users/getUsers"];
    },
    placeholders: function () {
      return store.getters["process/getPlaceholders"];
    },
    process: function () {
      return store.getters["process/getProcess"];
    },
    placeholderMathing: function () {
      if (!store.getters["process/getProcess"].name) {
        return {};
      }
      return this.isTemplateBuilder ? {} : store.getters["process/getProcess"].metaData.placeholderMatching;
    },
    isTemplateBuilder() {
      const name = this.$route.name as string;
      return name.includes('settings');
    },
  },
  watch: {
    step: function (val: string) {
      if (val == 'placeholders') {
        store.dispatch("processTasks/load", this.process.processId);
      }
    }
  },
  methods: {
    hasUsers: function (placeholderId: string) {
      if (!this.placeholderMathing) {
        return;
      }
      const placeholders = Object.keys(this.placeholderMathing);

      return placeholders
        .filter((placeholder: string) => placeholder === placeholderId)
        .map((placeholder: string) => {
          return this.placeholderMathing[placeholder].map((userId: string) => {
            let user = this.externalUser.find((u: any) => u.userId == userId);
            if (!user) {
              user = this.internalUser.find((u: any) => u.userId == userId);
            }

            if (!user) {
              return 'No user found';
            } else {
              return `${user.data?.firstName} ${user.data?.lastName}`;
            }

          })
        }).join(', ');
    },
    unresolvedPlaceholders: function (placeholderId: string) {
      return store.getters["processTasks/getTasks"].filter((task: any) => {
        if (task.assignee && task.assignee === placeholderId) {
          return true;
        } else if (task.followers && task.followers.includes(placeholderId)) {
          return true;
        }
      })
    },
    hasPlaceholders: function (userId: string) {
      if (!this.placeholderMathing) {
        return;
      }
      const placeholders = Object.keys(this.placeholderMathing);

      return placeholders.filter((placeholder: string) => {
        const mathes = this.placeholderMathing[placeholder];
        if (mathes && mathes.includes(userId)) {
          return true
        }
      }).map((placeholder: string) => {
        const pl = this.placeholders.find((p: any) => p.placeholderId == placeholder)
        return pl ? pl.name : '';
      }).join(', ')
    },
    deletePlaceholder: async function (placeholderId: string) {
      await store.dispatch("process/removePlaceholder", placeholderId);
    },
    resolve: async function (users: string[]) {
      this.replacingUser = this.selectedPlaceholder;
      await store.dispatch("processTasks/rolloutPlaceholder", {
        processId: this.process.processId,
        projectId: this.process.projectId,
        placeholderId: this.selectedPlaceholder,
        userId: users[0]
      });
      this.replacingUser = "";
    },
    submit: async function () {
      this.submitted = true;


      this.submitted = false;

      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
});
