import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import {
    show as getModuleApi,
    store as storeModule,
    update as updateModuleApi,
    remove as removeModuleApi,
    activate as activateModuleApi,
} from "@smace-technologies/modules/src/module";
import { show as getMilestoneApi } from "@smace-technologies/process/src/step";
import { checkForValidSession } from '@/router';
import { Module } from '@smace-technologies/modules/types/module';
import { Task } from '@smace-technologies/modules/types/task';

export const key: InjectionKey<Store<TState>> = Symbol()

type TModuleState = {
    module: Module;
    processId: string;
    milestoneName: string;
    processName: string;
};

const initialState = (): TModuleState => ({
    module: {} as Module,
    processId: '',
    milestoneName: '',
    processName: '',
});

export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getModule: (state: TModuleState) => {
            return state.module;
        },
        getMilestoneName: (state: TModuleState) => {
            return state.milestoneName;
        },
    },
    actions: {
        async load(context: ActionContext<TModuleState, TState>, data: { moduleId: string, processId: string }) {
            if (data.processId !== context.state.processId) {
                context.commit('RESET_STATE');
            }

            getModuleApi(
                store.getters["getBaseWorkspaceId"],
                data.processId,
                data.moduleId,
                store.getters["getJWT"]
            ).then((res) => {
                context.commit('SET_MODULE', res)
                context.dispatch("loadProcess", { processId: data.processId, projectId: res.projectId })
                    .then(() => {
                        if (res.stepId != '_') {
                            context.dispatch("loadMilestone", { processId: store.getters['process/getProcess'].typeId, stepId: res.stepId });
                        }
                    });
            });

            store.dispatch("moduleBlocks/load", data);
            await store.dispatch("tasks/load", data);
            await store.dispatch("activities/loadByModule");
            context.commit('SET_PROCESS_ID', data.processId);
        },
        async loadMilestone(context: ActionContext<TModuleState, TState>, data: { processId: string, stepId: string }) {
            getMilestoneApi(
                store.getters["getBaseWorkspaceId"],
                data.processId,
                data.stepId,
                store.getters["getJWT"]
            ).then((res) => context.commit('SET_MILESTONE_NAME', res.name)).catch(() => context.commit('SET_MILESTONE_NAME', ''));
        },
        async loadProcess(_context: ActionContext<TModuleState, TState>, data: { processId: string, projectId: string }) {
            store.dispatch("process/load", { projectId: data.projectId, processId: data.processId });
        },
        async addModule(context: ActionContext<TModuleState, TState>, data: { title: string, processId: string, aiPrompt?: string, stepId: string, weight: -1, projectId: string }) {
            const module = await storeModule(
                store.getters["getBaseWorkspaceId"],
                data.processId,
                {
                    title: data.title,
                    description: '',
                    stepId: data.stepId,
                    aiPrompt: data.aiPrompt,
                    done: false,
                    weight: data.weight,
                    projectId: data.projectId,
                    private: false
                },
                store.getters["getJWT"]
            );

            context.commit('SET_MODULE', module);
            context.commit('SET_BLOCKS', []);
        },
        async updateModule(context: ActionContext<TModuleState, TState>, module: Module) {
            await checkForValidSession();

            updateModuleApi(store.getters["getBaseWorkspaceId"],
                module.processId,
                module.moduleId,
                {
                    title: module.title,
                    description: module.description,
                    stepId: module.stepId,
                    done: module.done,
                    weight: module.weight,
                    private: module.private,
                    participants: module.participants?.filter((u: string) => u),
                    status: module.status,
                },
                store.getters["getJWT"]
            );
            context.commit('SET_MODULE', module);
        },
        async toggleModuleDone(context: ActionContext<TModuleState, TState>, module: Module) {
            await checkForValidSession();

            if (!module.done) {
                await store.dispatch("tasks/load", { moduleId: module.moduleId, processId: module.processId });
                const tasks = store.getters["tasks/getTasks"];

                await Promise.all(
                    tasks
                        .filter((task: Task) => task.status !== 'DONE')
                        .map((task: Task) => store.dispatch("tasks/updateTask", {
                            moduleId: module.moduleId,
                            taskId: task.taskId,
                            payload: { ...task, status: 'DONE' }
                        }))
                );
            }

            module.done = !module.done;

            updateModuleApi(store.getters["getBaseWorkspaceId"],
                module.processId,
                module.moduleId,
                {
                    title: module.title,
                    description: module.description,
                    stepId: module.stepId,
                    done: module.done,
                    weight: module.weight,
                    private: module.private,
                    participants: module.participants.filter((u: any) => u),
                },
                store.getters["getJWT"]
            );

            context.commit('SET_MODULE', module);
        },
        async deleteModule(_context: ActionContext<TModuleState, TState>, module: Module) {
            removeModuleApi(store.getters["getBaseWorkspaceId"],
                module.processId,
                module.moduleId,
                store.getters["getJWT"]
            );
        },
        async activateModule(context: ActionContext<TModuleState, TState>, module: Module) {
            const activatedModule = await activateModuleApi(
                store.getters["getBaseWorkspaceId"],
                module.processId,
                module.moduleId,
                store.getters["getJWT"]
            );

            context.commit('SET_MODULE', activatedModule);

            return activatedModule;
        },
    },
    mutations: {
        RESET_STATE(state: TModuleState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_MODULE(state: TModuleState, module: Module) {
            state.module = module;
        },
        SET_PROCESS_ID(state: TModuleState, processId: string) {
            state.processId = processId;
        },
        SET_MILESTONE_NAME(state: TModuleState, milestoneName: string) {
            state.milestoneName = milestoneName;
        },
        SET_PROCESS_NAME(state: TModuleState, processName: string) {
            state.processName = processName;
        }
    },
}
