import { RouteRecordRaw } from "vue-router";
import TasksView from '../views/pages/tasks/index/ShowTasks.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/tasks/',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'tasks',
                component: TasksView,
                meta: {
                  requiresAuth: true,
                }
              },
        ]
    },
];

export default routes;