
import router from "@/router";
import AccountSettingsSubNav from "./SubNav/WorkspaceSettingsSubNav.vue";
import ProcessTemplateSubNav from "./SubNav/ProcessTemplateSubNav.vue";
import { defineComponent } from "vue";


export default defineComponent({
  components: {
    AccountSettingsSubNav,
    ProcessTemplateSubNav,
  },
  props: {},
  data() {
    return {
      loaded: false,
      unfolded: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    goTo: function (params: any) {
      router.push(params).catch();
    },
    isActive: function (workspaceId: string, targetObjectId: string) {
      return (
        this.$route.name === "targetObject" &&
        this.$route.params.workspaceId === workspaceId &&
        this.$route.params.targetObjectId === targetObjectId
      );
    },
    isSubNav: function (subNavType: string) {
      return this.$route.meta.subNavType === subNavType;
    },
  },
  mounted() {
    //
  },
});
