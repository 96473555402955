import { RouteRecordRaw } from "vue-router";
import UserProfile from '../views/pages/user/UserProfile.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/user/',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'profile',
                name: 'user-profile',
                component: UserProfile,
                meta: {
                    requiresAuth: true,
                }
            },
        ]
    },
];

export default routes;