import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import { v4 as uuidv4 } from 'uuid';
import {
    getAll as getAllBlocksApi,
    store as storeBlockApi,
    update as updateBlockApi,
    updateWeights as updateWeightsApi,
    remove as deleteBlockApi,
} from "@smace-technologies/modules/src/block";
import { Block, BlockType } from '@smace-technologies/modules/types/block';
import { checkForValidSession } from '@/router';

export const key: InjectionKey<Store<TState>> = Symbol();

type TModuleBlocksState = {
    blocks: Block[];
    processId: string;
};

const initialState = (): TModuleBlocksState => ({
    blocks: [] as Block[],
    processId: ''
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getBlocks: (state: TModuleBlocksState) => {
            return state.blocks;
        },
    },
    actions: {
        async load(context: ActionContext<TModuleBlocksState, TState>, data: { moduleId: string, processId: string }) {
            if (data.processId !== context.state.processId) {
                context.commit('RESET_STATE');
            }

            getAllBlocksApi(
                store.getters["getBaseWorkspaceId"],
                data.moduleId,
                store.getters["getJWT"]
            ).then((res) => context.commit('SET_BLOCKS', res));

            context.commit('SET_PROCESS_ID', data.processId);
        },
        async addBlock(context: ActionContext<TModuleBlocksState, TState>, data: { content: Block['content'], type: string, weight: number, typeConfig?: object, taskType: string, headline?: string, description?: string, taskId?: string, noAddBlock?: boolean}) {
            await checkForValidSession();

            let content = {} as Block['content'];
            if (data.type === "taskLink") {
                content = {
                    taskId: data.taskId,
                };
            }

            const richDataTypes = ['image', 'video', 'pdf'];

            if (richDataTypes.includes(data.type)) {
                content = data.content
            }

            const block = {
                blockId: uuidv4(),
                content: content,
                type: data.type as BlockType,
                weight: data.weight,
            }

            if(Object.keys(block.content).length === 0) {
                block.content = ' ';
            }

            await storeBlockApi(
                store.getters["getBaseWorkspaceId"],
                context.state.processId,
                store.getters["module/getModule"].moduleId,
                block,
                store.getters["getJWT"]
            );

            if (parseFloat(String(data.weight)) === Math.round(data.weight * 10) / 10) {
                context.state.blocks.splice(data.weight, 0, block as Block);
                store.dispatch('moduleBlocks/updateAllBlockWeights', context.state.blocks);
            } else {
                context.commit('ADD_BLOCK', block);
            }
        },
        async updateBlock(context: ActionContext<TModuleBlocksState, TState>, block: Block) {
            await checkForValidSession();

            updateBlockApi(store.getters["getBaseWorkspaceId"],
                store.getters["module/getModule"].moduleId,
                block.blockId,
                {
                    content: block.content,
                    type: block.type,
                    weight: block.weight
                },
                store.getters["getJWT"]
            ).then(() => context.commit('SET_BLOCK', block))
                .catch((e) => alert('An error occured:' + e));
        },
        async silentUpdateBlock(context: ActionContext<TModuleBlocksState, TState>, block: Block) {
            context.commit('SET_BLOCK', block);
        },
        async updateAllBlockWeights(context: ActionContext<TModuleBlocksState, TState>, blocks: { blockId: string, weight: number }[]) {
            await checkForValidSession();

            const newBlocks = [] as Block[];
            blocks = blocks.map((b: {blockId: string}, index: number) => {
                return {
                    blockId: b.blockId,
                    weight: index
                };
            })

            updateWeightsApi(store.getters["getBaseWorkspaceId"],
                store.getters["module/getModule"].moduleId,
                blocks,
                store.getters["getJWT"]
            );

            blocks
                .sort((a, b) => a.weight - b.weight)
                .forEach((b) => {
                    const newBlock = context.state.blocks.find((block: Block) => b.blockId === block.blockId);
                    newBlock.weight = b.weight;
                    if (newBlock) {
                        newBlocks.push(newBlock);
                    }
                })

            context.commit('SET_BLOCKS', newBlocks);
        },
        async deleteBlock(context: ActionContext<TModuleBlocksState, TState>, blockId: string) {
            await checkForValidSession();

            deleteBlockApi(store.getters["getBaseWorkspaceId"],
                store.getters["module/getModule"].moduleId,
                blockId,
                store.getters["getJWT"]
            ).catch(() => alert('An error occured'));
            context.commit('DELETE_BLOCK', blockId);
            if (context.state.blocks.length === 0) {
                store.commit('moduleBlocks/APPEND_EMPTY_BLOCK');
            } else {
                store.dispatch('moduleBlocks/updateAllBlockWeights', context.state.blocks);
            }
        },
    },
    mutations: {
        RESET_STATE(state: TModuleBlocksState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        APPEND_EMPTY_BLOCK(state: TModuleBlocksState) {
            const lastBlock = state.blocks[state.blocks.length - 1];
            if (lastBlock && !lastBlock.content) {
                return;
            }

            store.dispatch('moduleBlocks/addBlock', {
                type: "text",
                weight: lastBlock ? lastBlock.weight + 1 : 0,
                noAddBlock: true
            });
        },
        SET_BLOCKS(state: TModuleBlocksState, blocks: Block[]) {
            state.blocks = blocks;
            if (blocks.length === 0) {
                store.commit('moduleBlocks/APPEND_EMPTY_BLOCK');
            }
        },
        SET_PROCESS_ID(state: TModuleBlocksState, processId: string) {
            state.processId = processId;
        },
        SET_BLOCK(state: TModuleBlocksState, block: Block) {
            state.blocks = state.blocks.map((b: Block) => b.blockId === block.blockId ? block : b);
        },
        ADD_BLOCK(state: TModuleBlocksState, block: Block) {
            state.blocks.push(block);
            state.blocks = state.blocks.filter((b: Block) => b);
        },
        DELETE_BLOCK(state: TModuleBlocksState, blockId: string) {
            state.blocks = state.blocks.filter((b: Block) => b.blockId !== blockId);
        }
    },
}
