
//@ts-disable
import { store } from "@/store";
import { defineComponent } from "vue";
import dateFormat from "dateformat";
import _ from "lodash";
import ContextMenu from "@/views/components/ContextMenu.vue";
import AddFormModal from "./modals/AddFormModal.vue";
import RenameFormModal from "./modals/RenameFormModal.vue";
import DeleteFormModal from "./modals/DeleteFormModal.vue";
import CloneFormModal from "./modals/CloneFormModal.vue";

export default defineComponent({
  components: {
    ContextMenu,
    AddFormModal,
    RenameFormModal,
    DeleteFormModal,
    CloneFormModal
  },
  data: () => {
    return {
      loading: false,
      selectedItemId: "",
      job: {},
    };
  },
  watch: {
    baseWorkspaceId: function () {
      this.load();
    },
  },
  computed: {
    baseWorkspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
    items: function () {
      return store.getters["forms/getForms"];
    },
  },
  async mounted() {
    this.load();
  },
  methods: {
    go: function (formId: string) {
      this.$router.push({ name : 'settings-form-editor', params: { formId } });
    },
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy HH:MM");
    },
    load: async function () {
      return store.dispatch("forms/load");
    },
  },
});
