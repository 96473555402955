
//@ts-disable
import { store } from "@/store";
import { defineComponent } from "vue";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import ProcessHeader from "./header/ProcessHeader.vue";
import AddNewModal from "./modals/AddNewModal.vue";
import ImportSubprocessModal from "./modals/importSubProcess/ImportSubprocessModal.vue";
import ShareProjectModal from "../../../projects/show/modals/ShareProjectModal.vue";
import ClientUsersModal from "./modals/ClientUsersModal.vue";
import MilestoneModulesKanban from "./milestonesModulesKanban/MilestoneModulesKanban.vue";
import TaskList from "./taskList/TaskList.vue";

export default defineComponent({
  components: {
    ProcessHeader,
    LoadingSpinner,
    ShareProjectModal,
    MilestoneModulesKanban,
    ClientUsersModal,
    AddNewModal,
    TaskList,
    ImportSubprocessModal
  },
  data: () => {
    return {
      showTaskList: false,
      showOnlyCompletedModule: false,
    };
  },
  watch: {
  },
  computed: {
    loaded: () => {
      return !store.getters["process/isLoading"] && !store.getters["processModules/isLoading"];
    },
    project: () => {
      return store.getters["process/getProject"];
    },
    isReadOnly: () => {
      return store.getters["process/getProject"].projectId === 'TEMPLATE';
    },
    getProcessTypeName: () => {
      return store.getters["process/getProcessTypeName"];
    },
    getProcessId: () => {
      return store.getters["process/getProcessId"];
    },
  },
  async mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      if (this.$route.name === 'settings-process') {
        store.dispatch("process/loadWithModules", { projectId: 'TEMPLATE', processId: this.$route.params.typeId });
      } else if (this.$route.name === 'process-show') {
        store.dispatch("process/loadWithModules", { projectId: this.$route.params.projectId, processId: this.$route.params.processId });
      }
    },
  },
});
