
import { clearAllCookies, store } from "@/store";
import { defineComponent } from 'vue';
import LogoAndName from '../../components/LogoAndName.vue';
import LoginCodeModal from "./LoginCodeModal.vue";

export default defineComponent({
  components: {
    LogoAndName,
    LoginCodeModal
  },
  data() {
    return {
      loaded: false,
      emailAddress: '',
      redirectTo: '',
      userList: [
        {
          firstName: "Ioahn",
          lastName: "Will",
          email: "ioahnwill@gmail.com",
          avatar: require("@/assets/images/avatar.png"),
          userId: "1",
        },
        {
          firstName: "Aill",
          lastName: "Ioahn",
          email: "",
          avatar: "",
          userId: "3",
        },
      ]
    }
  },
  methods: {
    openModal: function () {
      document.body.classList.add('login-code-modal-open');
      const el = this.$refs["loginModalBtn"] as any;
      el.click();
    },
    decodeEmailAddress: function() {
      if (this.$route.query.eml) {
        this.emailAddress = atob(this.$route.query.eml.toString());
      }
    },
    getRedirectUrl: function() {
      if (this.$route.query.redirectTo) {
        this.redirectTo = this.$route.query.redirectTo.toString();
      } else {
        this.redirectTo = '/'
      }
    },
  },
  async mounted() {
    clearAllCookies();
    this.decodeEmailAddress();
    this.getRedirectUrl();
    this.openModal();
  },
  async unmounted() {
    document.body.classList.remove('login-code-modal-open');
  }
});
