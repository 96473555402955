import { RouteRecordRaw } from "vue-router";
import IndexView from '../views/pages/settings/IndexView.vue'
import DeleteView from '../views/pages/settings/DeleteView.vue'
import AppearanceView from '../views/pages/settings/AppearanceView.vue'
import TextsView from '../views/pages/settings/TextsView.vue'
import UpdateNameView from '../views/pages/settings/UpdateNameView.vue'
import UrlView from '../views/pages/settings/UrlView.vue'
import UsersView from '../views/pages/settings/users/ShowUsersView.vue'
import ApiKeyView from '../views/pages/settings/api/ApiKey.vue'
import UserInviteView from '../views/pages/settings/users/UserInviteView.vue'
import jobRoutes from './settings-process'
import automationRoutes from './settings-automations'
import settingsProcess from './settings-process'
import settingsRoles from './settings-roles'
import formRoutes from './settings-form'
import emailRoutes from './settings-emails'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/settings/',

        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'settings',
                component: IndexView,
                meta: {
                    requiresAuth: true,
                    subNavType: 'ACCOUNT_SETTINGS'
                }
            }, {
                path: 'api-key',
                name: 'settings-api-key',
                component: ApiKeyView,
                meta: {
                    requiresAuth: true,
                    subNavType: 'ACCOUNT_SETTINGS'
                }
            }, /*{
                path: 'make-integration',
                name: 'settings-make-integration',
                component: MakeIntegration,
                meta: {
                    requiresAuth: true,
                    subNavType: 'ACCOUNT_SETTINGS'
                }
            }*/
            {
                path: 'delete',
                name: 'settings-delete',
                component: DeleteView,
                meta: {
                    requiresAuth: true,
                    subNavType: 'ACCOUNT_SETTINGS'
                }
            },
            {
                path: 'url',
                name: 'settings-url',
                component: UrlView,
                meta: {
                    requiresAuth: true,
                    subNavType: 'ACCOUNT_SETTINGS'
                }
            },
            {
                path: 'style',
                name: 'settings-style',
                component: AppearanceView,
                meta: {
                    requiresAuth: true,
                    subNavType: 'ACCOUNT_SETTINGS'
                }
            },
            {
                path: 'texts',
                name: 'settings-texts',
                component: TextsView,
                meta: {
                    requiresAuth: true,
                    subNavType: 'ACCOUNT_SETTINGS'
                }
            },
            {
                path: 'update-name',
                name: 'settings-update-name',
                component: UpdateNameView,
                meta: {
                    requiresAuth: true,
                    subNavType: 'ACCOUNT_SETTINGS'
                }
            },
            {
                path: '/settings/users',
                name: 'settings-users',
                component: UsersView,
                meta: {
                    requiresAuth: true,
                    subNavType: 'ACCOUNT_SETTINGS'
                }
            }, {
                path: '/settings/user-invite',
                name: 'settings-user-invite',
                component: UserInviteView,
                meta: {
                    requiresAuth: true,
                    subNavType: 'ACCOUNT_SETTINGS'
                }
            },
            ...jobRoutes,
            ...settingsProcess,
            ...automationRoutes,
            ...formRoutes,
            ...settingsRoles,
            ...emailRoutes,
        ]
    },
];

export default routes;