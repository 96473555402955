
import { defineComponent } from "vue";
import { store } from "@/store";
import { update as updateApi } from "@smace-technologies/workspace/src/workspace";

export default defineComponent({
  components: {},
  props: {},
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      prefix: "",
      urlAlreadyInUse: false,
    };
  },
  computed: {
    workspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
    workspaceDomain: function () {
      return store.getters["getBaseWorkspaceDomain"];
    },
    workspaceConfig: function () {
      return store.getters["getBaseWorkspaceConfig"];
    },
  },
  watch: {
    workspaceConfig: {
      handler() {
        this.prefix =
          this.workspaceConfig && this.workspaceConfig.url
            ? this.workspaceConfig.url.prefix
            : "";
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      this.urlAlreadyInUse = false;
      await updateApi(
        store.getters["getBaseWorkspaceId"],
        {
          config: {
            url: {
              prefix: this.prefix,
            },
          },
        } as any,
        store.getters["getJWT"]
      ).catch((errors: any) => {
        if (errors.response && errors.response.data) {
          this.urlAlreadyInUse = errors.response.data.some(
            (e: any) => e.message == '"prefix" is already used'
          );
        }
      });
      this.submitted = false;
      store.dispatch("reloadBaseWorkspace");
    },
  },
});
