
import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
    },
    data() {
        return {
            processTemplateId: "",
            subProcesses: {} as any,
        };
    },
    computed: {
        processTypesAndSinglemodules: function (): any {
            return [...this.processTypes, ...this.singleModules].map((item) => {
                return {
                    label: item.moduleId ? item.title + '(Module)' : item.name + '(Process)',
                    value: item.moduleId ? 'm:' + item.moduleId :  item.typeId,
                }
            });
        },
        processTypes: function () {
            return store.getters["settingsProcesses/getTypes"];
        },
        subTypes: function () {
            return store.getters["settingsProcesses/getSubTypes"]
                .filter((item: any) => item.processId !== this.processTemplateId)
                .filter((item: any) => item.typeId === this.processTemplateId);
        },
        singleModules: function () {
            return store.getters["settingsProcesses/getSingleModules"];
        },
    },
    watch: {
        subProcesses: {
            handler: function () {
                this.update();
            },
            deep: true,
        },
        processTemplateId: function () {
            this.subProcesses = {};
            this.update();
        }
    },
    methods: {
        update: function () {
            this.$emit('update', {
                processTemplateId: this.processTemplateId,
                subProcesses: Object.keys(this.subProcesses).filter((key: any) => this.subProcesses[key]),
            });
        },
        load: async function () {
            store.dispatch("settingsProcesses/load");
        },
        tryNext: async function () {
            if (!this.subTypes.length) {
                this.$emit('next');
            }
        },
        next: async function () {
            this.$emit('next');
        },
    },
    async mounted() {
        this.load();
    },
});
