
import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
    },
    props: {
        list: {
            type: Array
        },
        headerActionLabel: {
            type: String
        },
        trashIcon: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String
        },
    },
    data: () => {
        return {
            accordionOpened: true,
        };
    },
    emits: ['headerAction', 'trashAction', 'selectItem'],
    computed: {
        processVariables: function () {
            return store.getters['process/getVariables'] || {};
        },
    },
    methods: {
        getVariableName(variableId: string) {
            return this.processVariables[variableId]?.name || '';
        },
        toggleAccordion() {
            this.accordionOpened = !this.accordionOpened;
        },
    },
});
