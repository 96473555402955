
import { defineComponent } from "vue";
import { store } from "@/store";
import { remove as removeApi } from "@smace-technologies/process/src/process";
import { show as showApi } from "@smace-technologies/process/src/process";
import { show as showModulesApi, remove as removeModuleApi } from "@smace-technologies/modules/src/module";

export default defineComponent({
  components: {},
  props: {
    processId: {
      type: String,
    },
    projectId: {
      type: String,
      required: true,
    },
    moduleId: {
      type: String,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      processName: "",
      name: "",
    };
  },
  computed: {
  },
  watch: {
    projectId: function () {
      this.load();
    },
    processId: function () {
      this.load();
    },
  },
  mounted() {
    this.load();
    this.name = "";
  },
  methods: {
    load: async function () {
      this.loading = true;
      const res = await showApi(
        store.getters["getBaseWorkspaceId"],
        this.projectId,
        this.processId,
        store.getters["getJWT"]
      );
      this.processName = res.name;
      this.name = "";
      this.loading = false;
    },
    submit: async function () {
      this.submitted = true;
      await removeApi(
        store.getters["getBaseWorkspaceId"],
        this.projectId,
        this.processId,
        store.getters["getJWT"]
      );
      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.name = "";
      this.$emit("reload");
    },
  },
});
