
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { store } from "@/store";
import {
  getFormBuilderUrl,
} from "@/services/serviceUrls";

export default defineComponent({
  components: {},
  props: {},
  emits: [""],
  data() {
    return {
      route: null,
    };
  },
  computed: {
    iframeLink: function () {
      const route = useRoute();
      const taskId = route.params.taskId as string;
      const jobId = route.params.jobId as string;
      return getFormBuilderUrl(
        store.getters["getBaseWorkspaceId"],
        taskId
      );
    },
  },
  watch: {},
  methods: {
    receivePreviewMessage(event: any) {
      if (event.data.type == "GO_BACK") {
        this.$router.push({
          name: "project-show",
          params: { projectId: this.$route.params.projectId },
        });
      } else if (event.data.type == "ON_PREVIEW") {
        const prefix = store.getters["getBaseWorkspaceDomain"].split(".")[0];
        const taskId = this.route.params.taskId as string;
        const projectId = this.route.params.projectId as string;
        const jobId = this.route.params.jobId as string;
        const stepId = event.data.stepId as string;
        
      }
    },
  },
  mounted() {
    this.route = useRoute();
    window.addEventListener("message", this.receivePreviewMessage, false);
  },
});
