
import { defineComponent } from "vue";
import LogoAndName from '@/views/components/LogoAndName.vue';
import TaskStatusSelect from "@/views/pages/module/components/blocks/components/task/TaskStatusSelect.vue";
import UserSelection from "@/views/components/UserSelection/UserSelection.vue";
import VariableSelection from "@/views/components/VariableSelection/VariableSelection.vue";
import { store } from '@/store';
import SelectDate from "../blocks/components/task/SelectDate.vue";
import DeadlineSelect from "../blocks/components/task/DeadlineSelect.vue";

export default defineComponent({
  components: {
    LogoAndName,
    TaskStatusSelect,
    UserSelection,
    VariableSelection,
    SelectDate,
    DeadlineSelect,
  },
  props: {
    content: {
      type: Object,
    },
    isExecution: {
      type: Boolean,
    }
  },
  emits: ["update:content"],
  data() {
    return {
      form: {
        status: "",
        dueDate: 0,
        anticipatedTaskDuration: '',
        hidden: false,
        assignee: "12345678",
        followers: [] as any[],
        followersConfig: {},
        dueDateConfig: {} as any,
        assigneeConfig: {} as any
      },
      updatedJob: {} as any,
      selectedUsers: [] as string[],
    };
  },
  methods: {
    getVariable(variableId: string): any {
      return this.processVariables[variableId] || {};
    },
  },
  computed: {
    processVariables: function () {
      return store.getters['process/getVariables'] || {};
    },
    variables: {
      get(): string[] {
        return this.content.variablesSectionIds || []
      },
      set(v: any) {
        store.dispatch('task/updateTask', {
          moduleId: this.content.moduleId,
          taskId: this.content.taskId,
          payload: {
            ...this.content,
            variablesSectionIds: v
          }
        });
        return v;
      },
    },
    formattedDueDate(): string {
      return this.content.dueDate ? new Date(this.content.dueDate).toLocaleDateString() : 'Set Due Date';
    },
    computedStatus: {
      get(): string {
        return this.content.status
      },
      set(v: any) {
        store.dispatch('task/updateTask', {
          moduleId: this.content.moduleId,
          taskId: this.content.taskId,
          payload: {
            ...this.content,
            status: v
          }
        });
      },
    },
    computedDueDate: {
      get(): string {
        return this.content.dueDate
      },
      set(v: any) {
        store.dispatch('task/updateTask', {
          moduleId: this.content.moduleId,
          taskId: this.content.taskId,
          payload: {
            ...this.content,
            dueDate: v
          },
        });
      },
    },
    computedDueDateConfig: {
      get() {
        return this.content.dueDateConfig
      },
      set(v: any) {
        store.dispatch('task/updateTask', {
          moduleId: this.content.moduleId,
          taskId: this.content.taskId,
          payload: {
            ...this.content,
            dueDateConfig: v
          }
        });
      },
    },
    computedAccountablePerson: {
      get(): string[] {
        return [this.content.assignee];
      },
      set(v: any) {
        store.dispatch('task/updateTask', {
          moduleId: this.content.moduleId,
          taskId: this.content.taskId,
          payload: {
            ...this.content,
            assignee: v[0]
          }
        });
      },
    },
    computedFollowers: {
      get(): string[] {
        return this.content.followers || []
      },
      set(v: any) {
        this.$emit('update:content', {
          ...this.content,
          followers: v.map((u: any) => String(u))
        })
      },
    }
  }
}
)

