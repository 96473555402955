
import { defineComponent } from "vue";
import { store } from "@/store";
import { remove as removeApi } from "@smace-technologies/workspace/src/workspace";

export default defineComponent({
  components: {},
  props: {},
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
    };
  },
  computed: {
    workspaceName: function () {
      return store.getters["getBaseWorkspaceName"];
    },
  },
  watch: {
  },
  mounted() {
    this.name = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      await removeApi(
        store.getters["getBaseWorkspaceId"],
        store.getters["getJWT"]
      );
      this.submitted = false;
      store.dispatch("setBaseWorkspace", {
        workspaceId: "",
        name: "",
      });
      this.$router.push("/");
      window.location.reload();
    },
  },
});
