
import { defineComponent, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { store } from "@/store";
import { getFormBuilderUrl } from "@/services/serviceUrls";

export default defineComponent({
  components: {},
  emits: ["formUpdated", "hidden"],
  data() {
    return {
      route: null,
      stepId: "",
    };
  },
  computed: {
    formId() {
      const task = store.getters["task/getSelectedTask"];
      if (task && task.typeConfig && task.typeConfig.FORM) {
        return task.typeConfig.FORM.formId;
      }
      return null;
    },
    iframeLink: function () {
      const formId = this.formId as string;
      return getFormBuilderUrl(store.getters["getBaseWorkspaceId"], formId);
    },
  },
  watch: {},
  methods: {
    receivePreviewMessage(event: any) {
      if (event.data.type == "GO_BACK") {
        const el = this.$refs["closeBtn"] as any;
        this.$emit("formUpdated");
        el.click();
      } else if (event.data.type == "FORM_UPDATED") {
        this.$emit("formUpdated");
      }
      /* else if (event.data.type == "ON_PREVIEW") {
          this.stepId = event.data.stepId;
          const el = this.$refs["previewModal"] as any;
          el.click();
        }*/
    },
  },
  mounted() {
    this.route = useRoute();
    window.addEventListener("message", this.receivePreviewMessage, false);
    const modalElement = document.getElementById('formEditorModal');
    const handleModalHidden = () => {
      this.$emit('hidden');
    };
    modalElement.addEventListener('hidden.bs.modal', handleModalHidden);

    onUnmounted(() => {
      modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
    });
  },
});
