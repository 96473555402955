
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    props: {
        status: {
            type: String,
        }
    },
    data() {
        return {
        }
    },
    emits: ["update:status"],
    computed: {
    },
    methods: {
        set(status: string) {
            this.$emit("update:status", status);
        }
    }
})
