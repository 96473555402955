<template>
    <div class="d-flex">
        <slot></slot>
    </div>         
</template>

<script>
import * as filestack from 'filestack-js'
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        apikey: String,
        handleSuccess: {
            type: Function,
        },
        file: {
            type: Object,
            default: null
        },
        link: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'pick'
        },
        options: {
            type: Object,
            default: function() {
                return {};
            }
        },
        security: {
            type: Object,
            default: null
        },
        cname: {
            type: String,
            default: null
        },
        isFilestackOpen: {
            type: Boolean,
            default: false,
        },
        accept: {
            type: Array,
            default: function() {
                return ['*'];
            },
        },
        allowConcurrent: {
            type: Number,
            default: 1,
        },
        onClosed: {
            type: Function,
        },
    },
    mounted() {
        if (this.$props.isFilestackOpen) {
            this.onClickPick();
        }
    },
    emits: ['success', 'error'],
    methods: {
        onError(err) {
            this.$emit('error', err)
        },
        onClickPick() {
            this.initClient(
                this.mode,
                this.apikey,
                this.options,
                this.file,
                this.security,
                this.cname
            )
                .catch((err) => this.onError(err))
        },
        initClient (mode, apikey, options, file, security, cname) {
            const url = options.url
            const handle = options.handle
            const client = filestack.init(apikey, security, cname)
            const ops = Object.assign({}, options)
            delete ops.handle
            delete ops.url

            ops.onCancel             = (result) => this.$emit('canceled', result);
            ops.onClose              = () => this.$emit('closed');
            ops.onFileSelected       = (result) => this.$emit('file-selected', result);
            ops.onFileUploadFailed   = (result) => this.$emit('file-failed', result);
            ops.onFileUploadFinished = (result) => this.$emit('file-finished', result);
            ops.onFileUploadStarted  = (result) => this.$emit('file-started', result);
            ops.onOpen               = () => this.$emit('opened');
            ops.onUploadDone         = (result) => this.handleSuccess(result);
            ops.onUploadStarted      = (result) => this.$emit('upload-started', result);
            ops.accept               = this.$props.accept;
            ops.maxFiles             = this.$props.allowConcurrent;

            if (mode === 'transform') {
                return new Promise((resolve, reject) => {
                try {
                    resolve(client.transform(url, ops))
                } catch (err) {
                    reject(err)
                }
                })
            } else if (mode === 'metadata') {
                return client.metadata(handle, ops)
            } else if (mode === 'storeUrl') {
                return client.storeURL(url, ops)
            } else if (mode === 'upload') {
                return client.upload(file, ops)
            } else if (mode === 'remove') {
                return client.remove(handle, security)
            }

            return client.picker(ops).open()
        }
    }
})
</script>

<style>
</style>
