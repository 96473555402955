import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-96e7c36a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  id: "loginModal",
  tabindex: "-1",
  "data-bs-backdrop": "static",
  "aria-labelledby": "loginModal",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body auth-wrapper" }
const _hoisted_5 = { class: "auth-form-wrapper" }
const _hoisted_6 = { class: "modal-title mb-3" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("h5", _hoisted_6, [
                _createElementVNode("img", {
                  src: _ctx.logo,
                  height: "35",
                  class: "pe-2"
                }, null, 8, _hoisted_7),
                _createTextVNode(" No permission ")
              ])
            ]),
            _createElementVNode("p", null, [
              _createTextVNode(" Your are currently logged in as "),
              _createElementVNode("b", null, _toDisplayString(_ctx.userEmail), 1),
              _createTextVNode(" and "),
              (_ctx.workspace)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                    _createTextVNode("try to access the workspace "),
                    _createElementVNode("b", null, _toDisplayString(_ctx.workspace), 1)
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.workspace)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, " you have not selected a workspace"))
                : _createCommentVNode("", true),
              _createTextVNode(". ")
            ]),
            _createElementVNode("p", null, [
              _createTextVNode(" Either "),
              _createVNode(_component_router_link, { to: "/logout" }, {
                default: _withCtx(() => [
                  _createTextVNode("log out")
                ]),
                _: 1
              }),
              _createTextVNode(" or "),
              _createVNode(_component_router_link, { to: "/select-workspace" }, {
                default: _withCtx(() => [
                  _createTextVNode("choose another workspace")
                ]),
                _: 1
              }),
              _createTextVNode(". ")
            ])
          ])
        ])
      ])
    ])
  ]))
}