
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from "aws-amplify";
import { defineComponent } from 'vue';

let cognitoUser: CognitoUser;

async function signIn(email: string) {
    console.log(email);
    cognitoUser = await Auth.signIn(email);
}

async function answerCustomChallenge(answer: string) {
    cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);
    try {
        await Auth.currentSession();
    } catch {
        //@TODO show error message to user
        console.log('Apparently the user did not enter the right code');
    }
}

export default defineComponent({
    data: () => {
        return {
            email: "",
            customChallengeAnswer: "",
            codeSent: false,
        }
    },
    methods: {
        async sendLoginCode(event: Event) {
            await signIn(this.email);
            this.codeSent = true;
        },
        async answerCode(event: Event) {
            await answerCustomChallenge(this.customChallengeAnswer);
        }
    }
})
