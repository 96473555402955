
import { defineComponent } from 'vue';
//import UserSelection from '@/views/components/UserSelection/UserSelection.vue';
import AvatarsRow from '@/views/components/AvatarsRow.vue';
import { store } from "@/store";

export default defineComponent({
  components: {
    //UserSelection,
    AvatarsRow,
  },
  props: {
  },
  emits: ["setShowTaskList", "setShowOnlyCompletedModule"],
  data() {
    return {
      showTaskList: false,
      showOnlyCompletedModule: true,
    };
  },
  computed: {
    project: () => {
      return store.getters["process/getProject"];
    },
    clientName: () => {
      return store.getters["client/getClientName"];
    },
    process: () => {
      return store.getters["process/getProcess"];
    },
    isTemplateBuilder() {
      const name = this.$route.name as string;
      return name.includes('settings');
    },
    externalUsers: {
      get() {
        return store.getters["users/getExternalUsers"]
          .map((user: any) => { return user.userId })
      },
      set(v: any) {
        //store.dispatch("users/setFollowers", v);
      },
    },
  },
});
