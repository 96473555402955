import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd5371b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "context-menu" }
const _hoisted_2 = {
  id: `collapseSteps`,
  class: "accordion-collapse",
  "aria-labelledby": `headingSteps`,
  "data-bs-parent": "#accordion"
}
const _hoisted_3 = { class: "dropdown" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["aria-labelledby"]
const _hoisted_6 = ["data-bs-toggle", "data-bs-target", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_feather_icon = _resolveComponent("feather-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "float-end dropdown-step",
          type: "button",
          id: `dropdownStep${_ctx.id}`,
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false"
        }, [
          _createVNode(_component_feather_icon, {
            type: _ctx.icon,
            class: "cursor-pointer",
            size: _ctx.size,
            stroke: _ctx.stroke
          }, null, 8, ["type", "size", "stroke"])
        ], 8, _hoisted_4),
        _createElementVNode("ul", {
          class: _normalizeClass(["dropdown-menu step-context-menu", { show: _ctx.mouseOver }]),
          "aria-labelledby": `dropdownStep${_ctx.id}`,
          onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mouseOver = true)),
          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mouseOver = _ctx.leave))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links.filter((link) => !link.hidden), (link) => {
            return (_openBlock(), _createElementBlock("li", {
              key: link.text,
              "data-bs-toggle": link.bsToggle || 'modal',
              "data-bs-target": link.bsTarget,
              class: "d-flex align-items-center",
              onClick: ($event: any) => (_ctx.navigate(link.router))
            }, [
              _createVNode(_component_feather_icon, {
                type: link.icon,
                class: "cursor-pointer me-2",
                size: "17"
              }, null, 8, ["type"]),
              _createTextVNode(" " + _toDisplayString(link.text), 1)
            ], 8, _hoisted_6))
          }), 128))
        ], 42, _hoisted_5)
      ])
    ])
  ]))
}