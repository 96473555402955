import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import { getAll } from "@smace-technologies/client/src/client";
import { store as createClientApi } from "@smace-technologies/client/src/client";
import { Client } from '@smace-technologies/client/types/client';

export const key: InjectionKey<Store<TState>> = Symbol()

type TClientState = {
    clients: Client[];
};

const initialState = (): TClientState => ({
    clients: [] as Client[],
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getClients: (state: TClientState) => {
            return state.clients;
        },
    },
    actions: {
        async load(context: ActionContext<TClientState, TState>) {
            if (!store.getters["getJWT"]) return;
            const clients = await getAll(
                store.getters["getBaseWorkspaceId"],
                store.getters["getJWT"]
            );
            store.dispatch('users/load');
            context.commit('SET_CLIENTS', clients);
        },
        async add(context: ActionContext<TClientState, TState>, data: { name: string }): Promise<Client> {
            const client = await createClientApi(
                store.getters["getBaseWorkspaceId"],
                data,
                store.getters["getJWT"]
            );

            context.commit('ADD_CLIENT', client);
            return client;
        },
    },
    mutations: {
        RESET_STATE(state: TClientState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_CLIENTS(state: TClientState, clients: Client[]) {
            state.clients = clients;
        },
        ADD_CLIENT(state: TClientState, client: Client) {
            state.clients.push(client);
        },
    },
}
