
import { store } from '@/store';
import { defineComponent } from 'vue';
import taskCard from '../components/blocks/components/task/TaskCard.vue';
import draggable from 'vuedraggable';
import LoadingSpinner from '@/views/components/LoadingSpinner.vue';
import { Task } from '@smace-technologies/modules/types/task';
import AddFormModal from '@/views/pages/module/components/AddMenu/AddFormModal.vue';
import DeleteTaskModal from '../modals/DeleteTaskModal.vue';

export default defineComponent({
    components: {
        taskCard,
        LoadingSpinner,
        AddFormModal,
        DeleteTaskModal,
        draggable,
    },
    data() {
        return {
            selectedFormId: '',
            selectedTask: {},
            showActions: [],
            timeoutIds: {} as any,
            delay: 0,
        }
    },
    computed: {
        showDebugHelpers(): any {
            return Object.prototype.hasOwnProperty.call(this.$route.query, 'showDebugHelpers');
        },
        tasks(): Task[] {
            return store.getters['tasks/getTasks'];
        },
        computedTasks: {
            get() {
                return store.getters['tasks/getTasks'];
            },
            set(value: Task[]) {
                store.dispatch('tasks/updateAllWeights', value);
            }
        },
        loaded(): boolean {
            return !!store.getters["module/getModule"].moduleId;
        },
    },
    methods: {
        openModal() {
            const el = this.$refs["openSelectTaskTypeModal"] as any;
            el.click();
        },
        showAction: function (id: any) {
            clearTimeout(this.timeoutIds[id]);
            this.showActions[id] = true;
        },
        closeAction: function (id: any) {
            this.timeoutIds[id] = setTimeout(() => {
                this.showActions[id] = false;
            }, this.delay);
        },
    }
});

