
import { defineComponent } from 'vue';
import TasksWrapper from "./components/TasksWrapper.vue";
import ModuleShow from './ModuleShow.vue';

export default defineComponent({
    components: {
        TasksWrapper,
        ModuleShow
    },
});
