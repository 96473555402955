import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-755036ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "toc"
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  key: 0,
  class: "undone-tasks"
}
const _hoisted_4 = {
  key: 1,
  class: "onlydone-tasks"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "undone-tasks"
}
const _hoisted_7 = {
  key: 1,
  class: "onlydone-tasks"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(`type-h1 ${_ctx.highlightedHeadline == '' ? 'active' : ''}`),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToTop()))
          }, [
            _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
            (_ctx.parentTasks.filter((t) => !t.done).length)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "•"))
              : _createCommentVNode("", true),
            (_ctx.parentTasks.length && _ctx.parentTasks.every((t) => t.done))
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "•"))
              : _createCommentVNode("", true)
          ], 2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.title
            }, [
              (item.type != 'task')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(`type-${item.type} ${_ctx.highlightedHeadline === item.headline ? 'active' : ''}`),
                    onClick: ($event: any) => (_ctx.scrollTo(item))
                  }, [
                    _createTextVNode(_toDisplayString(item.headline) + " ", 1),
                    (item.tasks.filter((t) => !t.done).length)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, "•"))
                      : _createCommentVNode("", true),
                    (item.tasks.length && item.tasks.every((t) => t.done))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, "•"))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}