import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import { show as getClientApi } from "@smace-technologies/client/src/client";
import { Client } from '@smace-technologies/client/types/client';

export const key: InjectionKey<Store<TState>> = Symbol()

type ClientState = {
    client: Client;
};

const initialState = (): ClientState => ({
    client: {} as Client,
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getClient: (state: ClientState) => {
            return state.client;
        },
        getClientName: (state: ClientState) => {
            return state.client.name;
        },
    },
    actions: {
        async load(context: ActionContext<ClientState, TState>, clientId: string) {
            const client = await getClientApi(
                store.getters["getBaseWorkspaceId"],
                clientId,
                store.getters["getJWT"]
            );

            context.commit('SET_CLIENT', client);
            store.dispatch('users/loadExternalUser', clientId);
        },
    },
    mutations: {
        RESET_STATE(state: ClientState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_CLIENT(state: ClientState, client: Client) {
            state.client = client;
        },
    },
}
