
import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    props: {
        id: {
            type: Number,
            required: true
        },
        inviteToProcess: {
            required: false
        }
    },
    data: () => {
        return {
            errMsg: '',
            firstName: '',
            lastName: '',
            gender: '',
            email: '',
            creating: false,
        };
    },
    emits: ['cancel', 'addUser'],
    mounted() {
        this.reset();
    },
    computed: {
        canStore: function () {
            return this.firstName &&
                this.lastName &&
                this.gender;
        }
    },
    methods: {
        reset: function () {
            this.errMsg = '';
            this.firstName = '';
            this.lastName = '';
            this.gender = '';
            this.email = '';
        },
        submit: async function () {
            this.creating = true;
            await store.dispatch('users/addExternal', {
                email: this.email,
                inviteToProcess: this.inviteToProcess,
                userProfile: {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    gender: this.gender
                }
            }).then((res: any) => {
                this.$emit('addUser', res);
                this.reset();
            }).catch((err: any) => {
                console.log(err.response, 'status');
                if (!err.response || !err.response.status) {
                    this.errMsg = 'Something went wrong';
                    return;
                }
                switch (err.response.status) {
                    case 409:
                        this.errMsg = 'User already exists';
                        break;
                    case 422:
                        this.errMsg = `Invalid input: ${err.response.data}`;
                        break;
                    default:
                        this.errMsg = 'Something went wrong';
                        break;
                }
                //
            });
            this.creating = false;
        }
    },
});
