
//@ts-disable
import { store } from "@/store";
import { defineComponent } from "vue";
import dateFormat from "dateformat";
import AddProcessModal from "./modal/AddProcessModal.vue";
import AddModuleModal from "./modal/AddModuleModal.vue";
import AddNewModal from "./modal/AddNewModal.vue";
import AddSubProcessModal from "./modal/AddSubProcessModal.vue";
import DeleteProcessModal from "./modal/DeleteProcessModal.vue";
import DeleteModuleModal from "./modal/DeleteModuleModal.vue";
import RenameProcessModal from "./modal/RenameProcessModal.vue";
import RenameModuleModal from "./modal/RenameModuleModal.vue";
import CloneSubProcessModal from "./modal/CloneSubProcessModal.vue";
import DeleteSubProcessModal from "./modal/DeleteSubProcessModal.vue";
import RenameSubProcessModal from "./modal/RenameSubProcessModal.vue";
import CloneProcessModal from "./modal/CloneProcessModal.vue";
import ContextMenu from "@/views/components/ContextMenu.vue";
import { getAll as getAllModulesFromProcessApi } from "@smace-technologies/modules/src/module";
import { getAll as getAllMilestonesApi } from "@smace-technologies/process/src/step";

export default defineComponent({
  components: {
    AddProcessModal,
    DeleteProcessModal,
    DeleteModuleModal,
    RenameProcessModal,
    RenameModuleModal,
    CloneProcessModal,
    ///
    CloneSubProcessModal,
    DeleteSubProcessModal,
    RenameSubProcessModal,
    ///
    ContextMenu,
    AddSubProcessModal,
    //
    AddNewModal,
    AddModuleModal
  },
  data: () => {
    return {
      loading: false,
      selectedItemId: "",
      openProcess: [] as any[],
      modules: {} as any,
      selectedItem: {} as any,
      milestones: {} as any,
    };
  },
  watch: {
    baseWorkspaceId: function () {
      this.load();
    },
  },
  computed: {
    baseWorkspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
    singleModules: function () {
      return store.getters["settingsProcesses/getSingleModules"];
    },
    types: function () {
      const res = [] as any;
      store.getters["settingsProcesses/getTypes"].forEach((item: any) => {
        res.push(item);
        this.subTypes.forEach((subItem: any) => {
          if (subItem.typeId === item.typeId && subItem.processId !== subItem.typeId && this.openProcess[item.typeId]) {
            res.push({
              ...subItem,
              isSubType: true,
            });

            if (this.openProcess[subItem.processId]) {
              this.modules[subItem.processId]?.forEach((module: any) => res.push({
                ...module,
                isSubtItem: true
              }));
            }
          }
        });
        if (this.openProcess[item.typeId]) {
          this.modules[item.typeId]?.forEach((module: any) => res.push({
            ...module,
            isSubtItem: false
          }));
        }

      });
      return res;
    },
    subTypes: function () {
      return store.getters["settingsProcesses/getSubTypes"];
    },
  },
  async mounted() {
    this.load();
  },
  methods: {
    gotToModule: function (processId: string, moduleId: string) {
      this.$router.push({ name: 'moduleTasks', params: { processId, moduleId } });
    },
    go: function (typeId: string) {
      this.$router.push({ name: 'settings-process', params: { typeId } });
    },
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy HH:MM");
    },
    load: async function () {
      await store.dispatch("settingsProcesses/load");
    },
    getAllModulesFromProcessApi: async function (processId: string) {
      if (this.modules[processId]) {
        return;
      }
      this.getAllMilestonesApi(processId);
      this.modules[processId] = await getAllModulesFromProcessApi(this.baseWorkspaceId, processId, store.getters.getJWT);
    },
    getAllMilestonesApi: async function (processId: string) {
      return getAllMilestonesApi(this.baseWorkspaceId, processId, store.getters.getJWT)
        .then((steps: any) => {
          steps.forEach((step: any) => {

            let hash = 0;
            for (let i = 0; i < step.stepId.length; i++) {
              hash = (hash << 5) - hash + step.stepId.charCodeAt(i);
              hash |= 0;
            }

            let hex = (hash >>> 0).toString(16);
            while (hex.length < 6) {
              hex = '0' + hex;
            }

            this.milestones[step.stepId] = { name: step.name, color: `#${hex.substring(0, 6)}` };
          });
        })
    },
    getModuleContextMenu: function (item: any) {
      return [{
        text: 'Rename',
        icon: 'edit-3',
        bsTarget: item.moduleId ? '#renameModuleModal' : '#renameModuleModal'
      }, /*{
        text: 'Clone',
        icon: 'copy',
        bsTarget: !item.isSubType ? '#cloneProcessModal' : '#cloneSubProcessModal',
      }, */{
        text: 'Delete',
        icon: 'trash-2',
        bsTarget: '#deleteModuleModal',
      }];

    },
    getContextMenu: function (item: any) {
      let bsTarget = '#deleteProcessModal';
      if (item.isSubType) {
        bsTarget = '#deleteSubProcessModal';
      } else if (item.moduleId) {
        bsTarget = '#deleteModuleModal';
      }

      let menu = [{
        text: 'Rename',
        icon: 'edit-3',
        bsTarget: !item.isSubType ? '#renameProcessModal' : '#renameSubProcessModal',
      }, /*{
        text: 'Clone',
        icon: 'copy',
        bsTarget: !item.isSubType ? '#cloneProcessModal' : '#cloneSubProcessModal',
      }, */{
        text: 'Delete',
        icon: 'trash-2',
        bsTarget: bsTarget
      }];

      if (!item.isSubType) {
        menu = [{
          text: 'Sub-Process ',
          icon: 'plus',
          bsTarget: '#addSubProcess',
        },
        ...menu
        ];
      }

      return menu;
    },
  },
});
