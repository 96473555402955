
  import { defineComponent } from "vue";
  import { useRoute } from "vue-router";
  import { store } from "@/store";
  import { getFormBuilderUrl } from "@/services/serviceUrls";

  export default defineComponent({
    components: { },
    props: {},
    emits: [""],
    data() {
      return {
        route: null,
        stepId: "",
      };
    },
    computed: {
      iframeLink: function () {
        const route = useRoute();
        const taskId = route.params.taskId as string;
        return getFormBuilderUrl(store.getters["getBaseWorkspaceId"], taskId);
      },
    },
    watch: {},
    methods: {
      receivePreviewMessage(event: any) {
        if (event.data.type == "GO_BACK") {
          this.$router.push({
            name: "settings-process-template",
            params: {
              templateId: this.$route.params.templateId,
            },
          });
        }/* else if (event.data.type == "ON_PREVIEW") {
          this.stepId = event.data.stepId;
          const el = this.$refs["previewModal"] as any;
          el.click();
        }*/
      },
    },
    mounted() {
      this.route = useRoute();
      window.addEventListener("message", this.receivePreviewMessage, false);
    },
  });
  