import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a52cbba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendly" }
const _hoisted_2 = ["src", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      src: `${_ctx.link}&hide_gdpr_banner=1&hide_event_type_details=1&`,
      width: "100%",
      height: `${_ctx.height}px;`
    }, null, 8, _hoisted_2)
  ]))
}