
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    stepId: {
      type: String,
    },
    weight: {
      type: Number
    },
  },
  emits: ["reload", "added"],
  data() {
    return {
      submitted: false,
      name: "",
      aiPrompt: '',
      selectedStepId: "",
      showAiWaitingPrompt: false
    };
  },
  computed: {
    milestones: function () {
      return store.getters["processModules/getMilestones"];
    },
    step: function () {
      return this.stepId ? store.getters["modules/getMilestones"].find((s: any) => s.stepId === this.stepId) : null;
    },
  },
  watch: {
    stepId: function (v: any) {
      this.selectedStepId = v;
    },
  },
  mounted() {
    this.name = '';
    this.aiPrompt = '';
    this.selectedStepId = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      this.showAiWaitingPrompt = false;

      await store.dispatch("module/addModule", {
        title: this.name,
        stepId: this.selectedStepId,
        aiPrompt: this.aiPrompt,
        processId: store.getters["process/getProcessId"],
        projectId: store.getters["process/getProjectId"],
        weight: -1
      });

      store.dispatch("processModules/reloadModules");

      this.submitted = false;
      this.name = "";
      this.selectedStepId = null;

      if (!this.aiPrompt) {
        this.aiPrompt = '';
        const el = this.$refs["closeBtn"] as any;
        el.click();
        this.$router.push({
          name: 'moduleContext', params: {
            moduleId: store.getters["module/getModule"].moduleId,
            processId: store.getters["process/getProcessId"]
          }
        });
      } else {
        this.aiPrompt = "";
        this.showAiWaitingPrompt = true;
      }
    },
  },
});
