
import { defineComponent } from "vue";
import { store } from "@/store";
import { remove as removeApi } from "@smace-technologies/client/src/client";
import { show as showApi } from "@smace-technologies/client/src/client";

export default defineComponent({
  components: {},
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      clientName: "",
      name: "",
    };
  },
  computed: {},
  watch: {
    clientId: function () {
      this.load();
    },
  },
  mounted() {
    this.load();
    this.name = "";
  },
  methods: {
    load: async function () {
      this.loading = true;
      const res = await showApi(
        store.getters["getBaseWorkspaceId"],
        this.clientId,
        store.getters["getJWT"]
      );

      this.clientName = res.name;
      this.name = "";
      this.loading = false;
    },
    submit: async function () {
      this.submitted = true;
      await removeApi(
        store.getters["getBaseWorkspaceId"],
        this.clientId,
        store.getters["getJWT"]
      );
      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.name = "";
      this.$emit("reload");
    },
  },
});
