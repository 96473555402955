import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import {
    getAll as getAllTypesApi,
    store as storeApi,
    update as updateApi,
    remove as removeTypeApi,
} from '@smace-technologies/process/src/type';
import {
    getAll as getAllApi,
    remove as removeSubProcessApi,
    update as updateSubProcessApi,
    store as storeSubProcessApi,
    remove as removeApi,
} from '@smace-technologies/process/src/process';
import { getAll as getAllModuleApi, update as updateModuleApi, remove as removeModuleApi } from "@smace-technologies/modules/src/module";
import { Type } from '@smace-technologies/process/types/type';
import { Module } from '@smace-technologies/modules/types/module';
import { Process } from '@smace-technologies/process/types/process';

export const key: InjectionKey<Store<TState>> = Symbol();

type TProcessesState = {
    types: Type[];
    subTypes: Process[];
    singleModules: Module[];
};

const initialState = (): TProcessesState => ({
    types: [] as Type[],
    subTypes: [] as Process[],
    singleModules: [] as Module[],
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getTypes: (state: TProcessesState): Type[] => {
            return state.types;
        },
        getSubTypes: (state: TProcessesState): Type[] => {
            return state.subTypes;
        },
        getSingleModules: (state: TProcessesState): Module[] => {
            return state.singleModules;
        },
    },
    actions: {
        async load(context: ActionContext<TProcessesState, TState>) {
            const types = await getAllTypesApi(store.getters.getBaseWorkspaceId, store.getters.getJWT);
            context.commit('SET_TYPES', types);
            context.dispatch('loadSubProcess');
            context.dispatch('loadSingleModules');
        },
        async loadSubProcess(context: ActionContext<TProcessesState, TState>) {
            const subtypes = await getAllApi(store.getters.getBaseWorkspaceId, 'TEMPLATE', store.getters.getJWT);
            context.commit('SET_SUB_TYPES', subtypes);
        },
        async add(context: ActionContext<TProcessesState, TState>, data: { name: string, importType?: string }): Promise<Type> {
            const type = await storeApi(store.getters.getBaseWorkspaceId, { name: data.name, importType: data.importType }, store.getters.getJWT);
            context.commit('ADD_TYPE', type);

            return type;
        },
        async addSubProcess(_context: ActionContext<TProcessesState, TState>, data: { name: string, typeId: string }): Promise<Type> {
            const type = await storeSubProcessApi(store.getters.getBaseWorkspaceId, 'TEMPLATE', { name: data.name, typeId: data.typeId }, store.getters.getJWT);
            return type;
        },
        async rename(context: ActionContext<TProcessesState, TState>, data: { typeId: string, name: string }): Promise<void> {
            context.commit('RENAME_TYPE', data);
            await updateApi(store.getters.getBaseWorkspaceId, data.typeId, { name: data.name }, store.getters.getJWT)
                .catch(() => context.dispatch('load'));
        },
        async delete(context: ActionContext<TProcessesState, TState>, typeId: string) {
            context.commit('DELETE_TYPE', typeId);
            await removeApi(store.getters.getBaseWorkspaceId, 'TEMPLATE', typeId, store.getters.getJWT)
                .catch(() => context.dispatch('load'));
        },
        async deleteType(context: ActionContext<TProcessesState, TState>, typeId: string) {
            context.commit('DELETE_TYPE', typeId);
            await removeTypeApi(store.getters.getBaseWorkspaceId, typeId, store.getters.getJWT)
                .catch(() => context.dispatch('load'));
        },
        async deleteAllBlueprints(context: ActionContext<TProcessesState, TState>, typeId: string) {
            const toDelete = context.state.subTypes.filter((p: Process) => p.typeId === typeId);
            const toDelete2 = context.state.types.filter((p: any) => p.typeId === typeId)
            toDelete.concat(toDelete2 as any[]);

            await Promise.all(toDelete.map((data) => context.dispatch('delete', data.processId)));

            context.dispatch('deleteType', typeId);
        },
        ///
        async renameSubProcess(context: ActionContext<TProcessesState, TState>, data: { processId: string, name: string }): Promise<void> {
            context.commit('RENAME_SUB_TYPE', data);
            await updateSubProcessApi(store.getters.getBaseWorkspaceId, 'TEMPLATE', data.processId, { name: data.name }, store.getters.getJWT)
                .catch(() => context.dispatch('loadSubProcess'));
        },
        async deleteSubProcess(context: ActionContext<TProcessesState, TState>, processId: string) {
            context.commit('DELETE_SUB_TYPE', processId);
            await removeSubProcessApi(store.getters.getBaseWorkspaceId, 'TEMPLATE', processId, store.getters.getJWT)
                .catch(() => context.dispatch('loadSubProcess'));
        },
        async removeSingleModule(context: ActionContext<TProcessesState, TState>, moduleId: string) {
            await removeModuleApi(store.getters.getBaseWorkspaceId, '0', moduleId, store.getters.getJWT)
            context.commit('REMOVE_SINGLE_MODULE', moduleId);
        },
        async renameSingleModule(context: ActionContext<TProcessesState, TState>, data: { title: string, moduleId: string }) {
            const oldData = store.getters["settingsProcesses/getSingleModules"].find((m: any) => m.moduleId === data.moduleId);
            if (!oldData.title) {
                return;
            }
            await updateModuleApi(store.getters.getBaseWorkspaceId, '0', data.moduleId, {
                title: data.title,
                description: oldData.description,
                stepId: oldData.stepId,
                weight: oldData.weight,
                assignee: oldData.assignee,
                assigneeConfig: oldData.assigneeConfig,
                followers: oldData.followers,
                followersConfig: oldData.followersConfig,
                dueDate: oldData.dueDate,
                dueDateConfig: oldData.dueDateConfig,
                private: oldData.private,
            }, store.getters.getJWT)
            context.commit('RENAME_SINGLE_MODULE', data);
        },
        async loadSingleModules(context: ActionContext<TProcessesState, TState>) {
            const modules = await getAllModuleApi(store.getters.getBaseWorkspaceId, '0', store.getters.getJWT)
            context.commit('SET_SINGLE_MODULES', modules);
        },
    },
    mutations: {
        RESET_STATE(state: TProcessesState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_TYPES(state: TProcessesState, types: Type[]) {
            state.types = types;
        },
        SET_SUB_TYPES(state: TProcessesState, types: Process[]) {
            state.subTypes = types;
        },
        ADD_TYPE(state: TProcessesState, type: Type) {
            state.types.push(type);
        },
        RENAME_TYPE(state: TProcessesState, data: { name: string, typeId: string }) {
            state.types = state.types.map((f: Type) => {
                if (f.typeId === data.typeId) {
                    f.name = data.name;
                }
                return f;
            });
        },
        DELETE_TYPE(state: TProcessesState, typeId: string) {
            state.types = state.types.filter((t: Type) => t.typeId !== typeId);
        },
        ////
        RENAME_SUB_TYPE(state: TProcessesState, data: { name: string, processId: string }) {
            state.subTypes = state.subTypes.map((f: Process) => {
                if (f.processId === data.processId) {
                    f.name = data.name;
                }
                return f;
            });
        },
        DELETE_SUB_TYPE(state: TProcessesState, processId: string) {
            state.subTypes = state.subTypes.filter((t: Process) => t.processId !== processId);
        },
        //
        SET_SINGLE_MODULES(state: TProcessesState, modules: Module[]) {
            state.singleModules = modules.filter((m: Module) => m.projectId === 'TEMPLATE');
        },
        RENAME_SINGLE_MODULE(state: TProcessesState, data: { moduleId: string, title: string }) {
            state.singleModules = state.singleModules.map((m: any) => {
                if (m.moduleId === data.moduleId) {
                    m.title = data.title;
                }
                return m;
            });
        },
        ADD_SINGLE_MODULE(state: TProcessesState, module: Module) {
            if (!state.singleModules) {
                state.singleModules = [];
            }
            state.singleModules.push(module);
        },
        REMOVE_SINGLE_MODULE(state: TProcessesState, moduleId: string) {
            state.singleModules = state.singleModules.filter((m: Module) => m.moduleId !== moduleId);
        },
    },
}
