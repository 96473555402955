
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  emits: ["updated"],
  computed: {
  },
  watch: {
    job: {
      handler(){
        this.updatedJob = JSON.parse(JSON.stringify(this.job));
      },
      immediate: true
    },
  },
  data() {
    return {
      updatedJob: {} as any,
    };
  },
  methods: {
    submit: function () {
      this.$emit("updated", this.updatedJob);

      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
});
