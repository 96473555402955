import { TState } from '@/types/TState';
import { store } from '@/store';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { getAllByProcess } from '@smace-technologies/modules/src/task';
import { rolloutPlaceholder as rolloutPlaceholderApi } from "@smace-technologies/process/src/process";
import { Task } from '@smace-technologies/modules/types/task';

export const key: InjectionKey<Store<TState>> = Symbol();

type TProcessTasksState = {
    tasks: Task[];
};
const initialState = (): TProcessTasksState => ({
    tasks: [],
});

export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getTasks: (state: TProcessTasksState) => {
            return state.tasks;
        },
    },
    actions: {
        async load(context: ActionContext<TProcessTasksState, TState>, processId: string): Promise<void> {
            const res = await getAllByProcess(
                store.getters.getBaseWorkspaceId,
                processId,
                store.getters.getJWT
            )

            context.commit("SET_TASKS", res);
        },
        async rolloutPlaceholder(context: ActionContext<TProcessTasksState, TState>, data: { processId: string, userId: string, placeholderId: string, projectId: string }): Promise<void> {
            await rolloutPlaceholderApi(
                store.getters.getBaseWorkspaceId,
                data.projectId,
                data.processId,
                {
                    placeholderId: data.placeholderId,
                    userId: data.userId,
                },
                store.getters.getJWT
            );

            const p1 = store.dispatch('process/load', { projectId: data.projectId, processId: data.processId });
            const p2 = context.dispatch("load", data.processId);

            await Promise.all([p1, p2]);
        }
    },
    mutations: {
        SET_TASKS(state: TProcessTasksState, tasks: Task[]) {
            state.tasks = tasks;
        },
        RESET_STATE(state: TProcessTasksState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
    }
};
