
//@ts-disable
import { defineComponent } from "vue";
import JobsElement from "./JobsElement.vue";

export default defineComponent({
  components: {
    JobsElement,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
  },
  emits: ["update:model", "setSelectedJob", "setSelectedTask"],
  computed: {
    computedModel: {
      get() {
        return this.model;
      },
      set(val: any) {
        this.$emit("update:model", val);
      },
    },
  },
  data: () => {
    return {};
  },
  methods: {
    setSelectedJob: function (job: any) {
      this.$emit("setSelectedJob", {
        selectedJobType: this.type,
        selectedJob: job,
      });
    },
    setSelectedTask: function (task: any) {
      this.$emit("setSelectedTask", task);
    },
  },
});
