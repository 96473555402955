import { Task } from "@smace-technologies/modules/types/task";
import { store } from "@/store";
import { update, store as storeTask, remove } from "@smace-technologies/modules/src/task";
import { TState } from "@/types/TState";
import { ActionContext } from "vuex";
import { Block, TaskLinkContent } from "@smace-technologies/modules/types/block";

type TTaskState = {
    isTaskSidebarOpen: boolean;
    selectedTask: Task;
    shouldCreateReference: boolean;
};

const initialState = (): TTaskState => ({
    isTaskSidebarOpen: false,
    selectedTask: {} as Task,
    shouldCreateReference: false,
});

export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getSelectedTask: (state: TTaskState) => {
            return state.selectedTask;
        },
        getTaskById: () => (id: string) => {
            return store.getters['tasks/getTasks'].find((task: Task) => task.taskId === id);
        },
    },
    actions: {
        async updateTask(context: ActionContext<TTaskState, TState>, data: { moduleId: string; taskId: string, payload: Task }) {
            const payload = {
                taskType: data.payload.taskType,
                headline: data.payload.headline,
                description: data.payload.description,
                variablesSectionIds: data.payload.variablesSectionIds,
                weight: data.payload.weight || -1,
                status: data.payload.status,
                typeConfig: data.payload.typeConfig,
                assignee: data.payload.assignee || '',
                followers: data.payload.followers || [],
                dueDate: data.payload.dueDate || 0,
                dueDateConfig: data.payload.dueDateConfig || {},
            };
            context.commit('UPDATE_SELECTED_TASK', payload);
            await update(store.getters["getBaseWorkspaceId"], data.moduleId, data.taskId, payload, store.getters["getJWT"]);
            store.dispatch('tasks/load', { moduleId: data.moduleId, processId: store.getters['process/getProcessId'] });
        },
        async addTask(context: ActionContext<TTaskState, TState>, data: { moduleId: string; payload: Task }) {
            const tasks = store.getters['tasks/getTasks'];
            const payload = {
                taskId: data.payload.taskId || '',
                taskType: data.payload.taskType,
                headline: data.payload.headline,
                description: data.payload.description,
                weight: data.payload.weight || tasks.length + 1,
                status: data.payload.status,
                typeConfig: data.payload.typeConfig,
                assignee: data.payload.assignee || '',
                followers: data.payload.followers || [],
                dueDate: data.payload.dueDate || 0,
                dueDateConfig: data.payload.dueDateConfig || {},
            };
            const newTask = await storeTask(store.getters["getBaseWorkspaceId"], store.getters['process/getProcessId'], data.moduleId, payload, store.getters["getJWT"]);
            context.commit('SET_SELECTED_TASK', { ...newTask });

            if (context.state.shouldCreateReference) {
                await store.dispatch('moduleBlocks/addBlock', {
                    taskId: newTask.taskId,
                    type: 'taskLink',
                    weight: 0,
                    taskType: newTask.taskType,
                    moduleId: data.moduleId,
                });
                context.commit('SET_SHOULD_CREATE_REFERENCE', false);
            }

            store.dispatch('tasks/load', { moduleId: data.moduleId, processId: store.getters['process/getProcessId'] });
        },
        async deleteTask(_context: ActionContext<TTaskState, TState>, data: { moduleId: string; taskId: string }) {
            await remove(store.getters["getBaseWorkspaceId"], data.moduleId, data.taskId, store.getters["getJWT"]);

            const moduleBlocks = store.getters['moduleBlocks/getBlocks'];
            const taskLinkBlock = moduleBlocks.find((block: Block) => {
                if (block.content) {
                    return typeof block.content !== 'string' && (block.content as TaskLinkContent).taskId === data.taskId;
                }

                return false;
            });
            if (taskLinkBlock) {
                await store.dispatch('moduleBlocks/deleteBlock', taskLinkBlock.blockId);
            }
            await store.dispatch('tasks/load', { moduleId: data.moduleId, processId: store.getters['process/getProcessId'] });
        },
        async setSelectedTask(context: ActionContext<TTaskState, TState>, task: Task) {
            context.commit('SET_SELECTED_TASK', task);
        },
        async setShouldCreateReference(context: ActionContext<TTaskState, TState>, shouldCreateReference: boolean) {
            context.commit('SET_SHOULD_CREATE_REFERENCE', shouldCreateReference);
        }
    },
    mutations: {
        UPDATE_SELECTED_TASK(state: TTaskState, data: Task) {
            state.selectedTask = {
                ...state.selectedTask,
                ...data,
            };
        },
        SET_SELECTED_TASK(state: TTaskState, task: Task) {
            state.selectedTask = { ...task };
        },
        OPEN_TASK_SIDEBAR(state: TTaskState, task: Task) {
            state.isTaskSidebarOpen = true;
            state.selectedTask = task;
        },
        CLOSE_TASK_SIDEBAR(state: TTaskState) {
            state.isTaskSidebarOpen = false;
            state.selectedTask = {} as Task;
        },
        RESET_STATE(state: TTaskState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_SHOULD_CREATE_REFERENCE(state: TTaskState, shouldCreateReference: boolean) {
            state.shouldCreateReference = shouldCreateReference;
        },
    }
}
