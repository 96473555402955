
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    stepId: {
      type: String,
    },
    weight: {
      type: Number
    },
  },
  emits: ["reload", "added"],
  data() {
    return {
      submitted: false,
      name: "",
    };
  },
  computed: {
    step: function () {
      return this.stepId ? store.getters["modules/getMilestones"].find((s: any) => s.stepId === this.stepId) : null;
    },
  },
  watch: {},
  mounted() {
    this.name = '';
  },
  methods: {
    submit: async function () {
      this.submitted = true;

      const res = await store.dispatch("processModules/addMilestone", {
        name: this.name,
        afterWeight: this.weight || 0,
        typeId: store.getters["process/getProcessTypeId"],
      });

      this.submitted = false;
      this.name = "";

      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
});
