
import { store } from "@/store";
import AddWorkspaceModal from "../../layout/components/AddWorkspaceModal.vue";
import { getAllByUser } from "@smace-technologies/workspace/src/workspace";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        AddWorkspaceModal,
    },
    props: {},
    data() {
        return {
            workspaces: [] as any,
            externalUser: false,
        };
    },
    watch: {
        triggerReload: {
            handler() {
                this.load();
            },
            immediate: true,
        },
    },
    computed: {
        name: function () {
            return store.getters["getBaseWorkspaceName"];
        },
        config: function (): any {
            return store.getters["getBaseWorkspaceConfig"];
        },
        logo: function (): any {
            return this.config && this.config.images ? this.config.images.logo : "";
        },
        baseWorkspaceId: function (): any {
            return store.getters["getBaseWorkspaceId"];
        },
        triggerReload: function () {
            return store.getters["getJWT"];
        },
    },
    methods: {
        selectWorkspace: function (workspace: any) {
            store.dispatch("setBaseWorkspace", {
                ...workspace,
                cleanUp: true,
            });
        },
        load: async function () {
            this.workspaces = await getAllByUser(
                store.getters["getUserEmail"],
                store.getters["getJWT"]
            );
        },
    },
});
