
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    processLink: {},
  },
  emits: ["added"],
  data() {
    return {
      password: "",
      description: "",
    };
  },
  computed: {
    process: () => {
      return store.getters["process/getProcess"];
    },
    hasPassword: function () {
      return this.process && this.process.auth && this.process.auth.password;
    },
  },
  watch: {
    project: {
      handler: function () {
        this.password  = '';
        if (this.hasPassword) {
          this.password = this.process.auth.password;
        }
      },
      deep:true,
      immediate: true,
    },
  },
  methods: {
    submit: function () {
      store.dispatch("process/setUserAuthPW", this.password);
    },
  },
});
