
import { defineComponent } from "vue";
import { store } from "@/store";
import LogoAndName from "@/views/components/LogoAndName.vue";
import { Process } from "@smace-technologies/process/types/process";
import UserSelection from '@/views/components/UserSelection/UserSelection.vue';
import SelectDate from "@/views/pages/settings/processes/show/sidebar/components/SelectDate.vue";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import { importSubProcess } from "@smace-technologies/process/src/process";

export default defineComponent({
  components: {
    SelectDate,
    LogoAndName,
    UserSelection,
    LoadingSpinner
  },
  props: {
    stepId: {
      type: String,
    },
    weight: {
      type: Number
    },
  },
  emits: ["reload", "added"],
  data() {
    return {
      submitted: false,
      page: 1,
      percentage: 0,
      error: '',
      ///
      selectedSubProcessId: "",
      startDate: null,
      endDate: null,
      selectedPlaceholder: {} as any,
    };
  },
  computed: {
    process: function () {
      return store.getters["process/getProcess"] || { metaData: {} } as Process;
    },
    selectedProcessId: function () {
      return store.getters["process/getProcessTypeId"];
    },
    subprocesses: function () {
      return store.getters["settingsProcesses/getSubTypes"]
        .filter((item: any) => item.processId !== this.selectedProcessId)
        .filter((item: any) => item.typeId === this.selectedProcessId);
    },
    placeholders: function () {
      return this.process.metaData?.placeholders || [];
    },
  },
  watch: {
    selectedProcessId: function () {
      this.reset();
    },
    placeholders: {
      handler: function () {
        this.reset();
      },
      deep: true,
    },
  },
  methods: {
    reset: function () {
      this.error = '';
      this.page = 1;
      this.percentage = 0;
      this.selectedSubProcessId = "";
      this.startDate = this.process.metaData?.projectStart || this.dateInDays(0);
      this.endDate = this.process.metaData?.projectEnd || this.dateInDays(62);

      if (this.process.metaData?.placeholderMatching?.length) {
        this.selectedPlaceholder = this.process.metaData.placeholderMatching;
      }
      else {
        this.placeholders.forEach((placeholder: any) => {
          this.selectedPlaceholder[placeholder.placeholderId] = [];
        });
      }
    },
    dateInDays: function (days: number) {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + days);
      const timestamp = (new Date(tomorrow).getTime());
      return timestamp;
    },
    submit: async function () {
      const expectedStoringtime = 5500;
      this.submitted = true;
      this.percentage = 0;
      this.page = 3;
      this.error = '';

    for (let i = 1; i <= 9; i++) {
        setTimeout(() => this.percentage = i * 10, expectedStoringtime / 10 * i);
    }

      await importSubProcess(
        store.getters["getBaseWorkspaceId"],
        this.process.projectId,
        this.process.processId,
        {
          subProcessId: this.selectedSubProcessId,
          metaData: {
            projectStart:  this.startDate,
            projectEnd:  this.endDate,
            placeholderMatching: this.selectedPlaceholder
          }
        },
        store.getters["getJWT"]
      );

      this.percentage = 100;
      this.submitted = false;
      this.reset();
      this.$emit("reload");

      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
  mounted() {
    this.reset();
  },
});
