
import { defineComponent } from "vue";
import { store } from "@/store";
import { create as storeApi } from "@smace-technologies/appmixer-integration/src/flow";

export default defineComponent({
  components: {},
  props: {},
  emits: ["reload", "added"],
  data() {
    return {
      submitted: false,
      name: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    submit: async function () {
      this.submitted = true;
      const res = await storeApi(
        store.getters["getBaseWorkspaceId"],
        {
          name: this.name,
          flow: {},
        } as any,
        store.getters["getJWT"]
      );
      this.submitted = false;
      this.name = "";
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$emit("reload");
    },
  },
});
