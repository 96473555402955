
import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    props: {
        module: {
            type: Object,
            required: true
        },
        userId: {
            type: String,
            required: true
        }
    },
    data: () => {
        return {
            errMsg: '',
            creating: false,
        };
    },
    emits: ['cancel', 'added'],
    mounted() {
        this.reset();
    },
    computed: {
    },
    methods: {
        reset: function () {
            this.errMsg = '';
        },
        submit: async function () {
            this.creating = true;

            await store.dispatch("module/updateModule", {
                ...this.module,
                participants: [...this.module.participants, this.userId],
            });

            this.$emit('added');

            this.creating = false;
        }
    },
});
