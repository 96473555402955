
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  emits: ["added"],
  data() {
    return {
      submitted: false,
      name: "",
      importType: 'SALES_1'
    };
  },
  computed: {},
  watch: {},
  methods: {
    submit: async function () {
      this.submitted = true;

      const res = await store.dispatch("settingsProcesses/add", { name: this.name, importType: this.importType });

      this.submitted = false;
      this.name = "";

      const el = this.$refs["closeBtn"] as any;
      el.click();

      this.$emit("added", res.typeId);
    },
  },
});
