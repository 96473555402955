import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import { getAll } from "@smace-technologies/project/src/project";
import { store as createProjectApi } from "@smace-technologies/project/src/project";
import { Project } from '@smace-technologies/project/types/project';

export const key: InjectionKey<Store<TState>> = Symbol();

type TProjectsState = {
    projects: Project[];
};

const initialState = (): TProjectsState => ({
    projects: [] as Project[],
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getProjects: (state: TProjectsState) => {
            return state.projects;
        },
    },
    actions: {
        async load(context: ActionContext<TProjectsState, TState>) {
            if (!store.getters["getJWT"]) return;
            const projects = await getAll(
                store.getters["getBaseWorkspaceId"],
                store.getters["getJWT"]
            );

            context.commit('SET_PROJECTS', projects);
        },
        async add(context: ActionContext<TProjectsState, TState>, data: { name: string, clientId: string }): Promise<Project> {
            const project = await createProjectApi(
                store.getters["getBaseWorkspaceId"],
                {
                    clientId: data.clientId,
                    name: data.name,
                },
                store.getters["getJWT"]
            );

            context.commit('ADD_PROJECT', project);
            return project;
        },
        async loadByClients(context: ActionContext<TProjectsState, TState>, clientId: string) {
            if (!clientId) {
                return context.commit('SET_PROJECTS', []);
            }
            const projects = await getAll(
                store.getters["getBaseWorkspaceId"],
                store.getters["getJWT"]
            );

            context.commit('SET_PROJECTS', projects.filter((project: Project) => project.clientId === clientId));
        },
    },
    mutations: {
        RESET_STATE(state: TProjectsState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_PROJECTS(state: TProjectsState, projects: Project[]) {
            state.projects = projects;
        },
        ADD_PROJECT(state: TProjectsState, project: Project) {
            state.projects.push(project);
        },
    },
}
