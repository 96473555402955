import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21b8d9a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "form-builder" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("iframe", {
      style: {},
      src: _ctx.iframeLink,
      key: _ctx.iframeLink
    }, null, 8, _hoisted_2))
  ]))
}