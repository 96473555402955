import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import { getAll as getAllApi, store as storeApi } from "@smace-technologies/form/src/form";
import { update as updateApi, remove as removeApi, clone as cloneApi } from "@smace-technologies/form/src/form";
import { Form, FormStorePayload } from '@smace-technologies/form/types/form';

export const key: InjectionKey<Store<TState>> = Symbol()

type TFormState = {
    forms: Form[];
};

const initialState = (): TFormState => ({
    forms: [] as Form[],
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getForms: (state: TFormState) => {
            return state.forms;
        },
    },
    actions: {
        async load(context: ActionContext<TFormState, TState>) {
            const forms = await getAllApi(
                store.getters["getBaseWorkspaceId"],
                store.getters["getJWT"]
            );

            context.commit('SET_FORMS', forms);
        },
        async add(context: ActionContext<TFormState, TState>, payload: FormStorePayload): Promise<Form> {
            const form = await storeApi(
                store.getters["getBaseWorkspaceId"],
                payload,
                store.getters["getJWT"]
            );

            context.commit('ADD_FORM', form);
            return form;
        },
        async clone(context: ActionContext<TFormState, TState>, data: {formId: string, name: string}) {
            const form = await cloneApi(
                store.getters["getBaseWorkspaceId"],
                data.formId,
                {
                    name: data.name
                },
                store.getters["getJWT"]
            );

            context.commit('ADD_FORM', form);
        },
        async delete(context: ActionContext<TFormState, TState>, formId: string) {
            await removeApi(
                store.getters["getBaseWorkspaceId"],
                formId,
                store.getters["getJWT"]
            );

            context.commit('DELETE_FORM', formId);
        },
        async rename(context: ActionContext<TFormState, TState>, data: { formId: string, name: string }) {
            const forms = await updateApi(
                store.getters["getBaseWorkspaceId"],
                data.formId,
                { 
                    name: data.name 
                },
                store.getters["getJWT"]
            );

            context.commit('RENAME_FORM', forms);
        },
    },
    mutations: {
        RESET_STATE(state: TFormState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_FORMS(state: TFormState, forms: Form[]) {
            state.forms = forms;
        },
        ADD_FORM(state: TFormState, form: Form) {
            state.forms.push(form);
        },
        RENAME_FORM(state: TFormState, form: Form) {
            state.forms = state.forms.map((f: Form) => {
                if (f.formId === form.formId) {
                    f = form;
                }
                return f;
            });
        },
        DELETE_FORM(state: TFormState, formId: string) {
            state.forms = state.forms.filter((f: Form) => f.formId !== formId);
        },
    },
}
