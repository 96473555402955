
import { defineComponent } from "vue";
import { store } from "@/store";
import { getAll as getMilestonesApi } from "@smace-technologies/process/src/step";
import { clone as cloneModuleApi } from "@smace-technologies/modules/src/module";

export default defineComponent({
  components: {
  },
  props: {
    module: {
      type: Object
    },
  },
  emits: [""],
  data() {
    return {
      submitted: false,
      selectedProcessId: '',
      selectedProjectId: '',
      selectedSubProcessId: '',
      selectedStepId: '',
      milestones: [],
    };
  },
  watch: {
    selectedProcessId: function (v: any) {
      if (v) {
        this.loadMilestones();
      }
    }
  },
  computed: {
    clientId: function () {
      return null;
    },
    isSelected: function () {
      return this.selectedProcessId && this.selectedProjectId && this.selectedStepId;
    },
    projects: function () {
      const temnplate = { projectId: 'TEMPLATE', name: 'TEMPLATE' };
      if (!this.clientId) {
        return [temnplate]
      }

      return [temnplate, ...store.getters["projects/getProjects"].filter((project: any) => project.clientId === this.clientId)];
    },
    processes: function () {
      if (this.selectedProjectId === 'TEMPLATE') {
        return store.getters["settingsProcesses/getTypes"]
      }

      return [];
    },
    subprocesses: function () {
      if (this.selectedProjectId === 'TEMPLATE') {
        return store.getters["settingsProcesses/getSubTypes"]
          .filter((item: any) => item.processId !== this.selectedProcessId)
          .filter((item: any) => item.typeId === this.selectedProcessId);
      }

      return [];
    },
  },
  methods: {
    reset: function () {
      this.selectedProcessId = '';
      this.selectedProjectId = '';
      this.selectedSubProcessId = '';
      this.selectedStepId = '';
      this.milestones = [];
    },
    loadMilestones: async function () {
      this.milestones = await getMilestonesApi(
        store.getters["getBaseWorkspaceId"],
        this.selectedProcessId,
        store.getters["getJWT"]
      );
    },
    submit: async function () {
      this.submitted = true;

      const cloned = await cloneModuleApi(
        store.getters["getBaseWorkspaceId"],
        {
          src: {
            moduleId: this.module.moduleId,
            projectId: this.module.projectId,
            processId: this.module.processId
          },
          target: {
            projectId: this.selectedProjectId,
            processId: this.selectedSubProcessId ? this.selectedSubProcessId : this.selectedProcessId,
            stepId: this.selectedStepId
          }
        },
        store.getters["getJWT"]
      )

      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.reset();

      if (cloned.projectId === 'TEMPLATE') {
        return this.$router.push({ name: "moduleTemplate", params: { processId: cloned.processId, moduleId: cloned.moduleId } });
      }

      return this.$router.push({ name: "module", params: { processId: cloned.processId, moduleId: cloned.moduleId } });
    },
  },
  mounted() {
    this.reset();
  },
});
