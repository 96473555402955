
//@ts-disable
import ModuleCard from "./ModuleCard.vue";
import { store } from "@/store";
import { defineComponent } from "vue";
import draggable from "vuedraggable";

export default defineComponent({
  components: {
    draggable,
    ModuleCard
  },
  props: {
    modules: {
      type: Array,
    },
    stepId: {
      type: String,
    },
  },
  emits: ["selectedModule", "update:modules", "setSelectedJob", "addModule"],
  data: () => {
    return {
      hoverJob: [] as any,
      drag: false,
    };
  },
  computed: {
    isTemplate: function (): boolean {
      return store.getters["process/getProject"].projectId === 'TEMPLATE';
    },
    computedModules: {
      get() {
        return this.modules || [];
      },
      set(val: any) {
        store.dispatch("processModules/updateModules", { modules: val, stepId: this.stepId });
      },
    },
  },
  methods: {
    changeEv: function (evt: any) {
      console.log(evt);
    },
    goToFormBuilder: function (moduleId: string) {
      this.$router.push({
        name: "module-form-step",
        params: {
          moduleId: moduleId,
          projectId: this.$route.params.projectId,
          stepId: "step1",
        },
      });
    },
  },
});
