
import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
    },
    data() {
        return {
            selectedItemId: null,
            selectedPlaceholder: {} as any,
        };
    },
    emits: ['next', 'update:variables'],
    props: {
        variables: {
            type: Object,
        },
        clientId: {
            type: String,
        },
    },
    computed: {
        computedVariables: {
            get() {
                return this.variables;
            },
            set(value: any) {
                this.$emit('update:variables', value);
            }
        }
    },
    methods: {
    },
    async mounted() {
        //
    },
});
