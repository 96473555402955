
import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
    },
    props: {
        clientId: String,
    },
    data() {
        return {
            creating: false,
            showCreateForm: false,
            newProjectName: null,
            selectedProject: "",
        };
    },
    watch: {
        selectedProject: function () {
            if (this.selectedProject === 'add') return;

            this.$emit('update', {
                projectId: this.selectedProject,
                name: this.newProjectName,
            });
        },
    },
    computed: {
        projectList: function () {
            if (!this.clientId) {
                return []
            }

            return store.getters["projects/getProjects"].filter((project: any) => project.clientId === this.clientId);
        },
        canNext: function (): boolean {
            return this.selectedProject || this.newProjectName;
        },
    },
    methods: {
        createProject: async function () {
            if (!this.newProjectName) return;

            this.creating = true;
            const newProject = await store.dispatch("projects/add", { name: this.newProjectName, clientId: this.clientId });
            this.selectedProject = newProject.projectId;
            this.showCreateForm = false;
            this.creating = false;
            this.newProjectName = "";
        },
        onChange: async function () {
            if (this.selectedProject === 'add') {
                this.showCreateForm = true;
            }
        },
    },
    mounted() {
        store.dispatch("projects/load");
    }
});
