import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c207832"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-selection" }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  id: `collapseSteps`,
  class: "accordion-collapse",
  "aria-labelledby": `headingSteps`,
  "data-bs-parent": "#accordion"
}
const _hoisted_4 = { class: "dropdown" }
const _hoisted_5 = ["aria-labelledby"]
const _hoisted_6 = { class: "scrollable-list-parent" }
const _hoisted_7 = { class: "scrollable-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddVariable = _resolveComponent("AddVariable")!
  const _component_VariableAccordion = _resolveComponent("VariableAccordion")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", {
      id: `dropdownStep${_ctx.id}`,
      class: "slot-wrapper",
      "data-bs-toggle": "dropdown",
      "data-bs-auto-close": "outside",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayType = 'Menu')),
      "aria-expanded": "false",
      ref: "toggleBtn"
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", {
          class: "dropdown-menu step-user-seclection",
          "aria-labelledby": `dropdownStep${_ctx.id}`
        }, [
          (_ctx.displayType == 'createVariable')
            ? (_openBlock(), _createBlock(_component_AddVariable, {
                key: 0,
                id: _ctx.id,
                onAddVariable: _cache[1] || (_cache[1] = ($event: any) => (_ctx.displayType = 'Menu')),
                onCancel: _ctx.close
              }, null, 8, ["id", "onCancel"]))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_VariableAccordion, {
                  list: _ctx.selectedVariables,
                  headerActionLabel: 'Clear all',
                  onHeaderAction: _ctx.deselectAll,
                  title: _ctx.label,
                  trashIcon: true,
                  onTrashAction: _cache[2] || (_cache[2] = (item) => _ctx.remove(item)),
                  onClearAll: _ctx.deselectAll
                }, null, 8, ["list", "onHeaderAction", "title", "onClearAll"]),
                _createVNode(_component_VariableAccordion, {
                  list: _ctx.availableVariables,
                  title: `Available references`,
                  onHeaderAction: _cache[3] || (_cache[3] = ($event: any) => (_ctx.displayType = 'createVariable')),
                  headerActionLabel: _ctx.isExecution ? '' : 'Add new',
                  onSelectItem: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addVariable($event)))
                }, null, 8, ["list", "headerActionLabel"])
              ])
            ])
          ], 512), [
            [_vShow, _ctx.displayType == 'Menu']
          ])
        ], 8, _hoisted_5)
      ])
    ])
  ]))
}