
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        link: {
            type: String,
        }
    },
    data() {
        return {
            height: 600,
        }
    },
    emits: ["done"],
    methods: {
        addPreFillParameter(){
            //TODO
        },
        receiveMessage(event: any): void {
            console.log('event', event);

            if (!this.isCalendlyEvent(event)) {
                return;
            }

            if (event.data.event === "calendly.date_and_time_selected") {
                this.height = 900;
            } else if (event.data.event === "calendly.event_scheduled") {
                console.log('event.data', event.data, 'mark as done');
                this.$emit("done");
            }
        },
        isCalendlyEvent(e: any) {
            return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
        }
    },
    mounted() {
        window.addEventListener("message", this.receiveMessage, false);
    },
    beforeUnmount() {
        window.removeEventListener("message", this.receiveMessage);
    },
});
