import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-List" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAccordion = _resolveComponent("UserAccordion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isTemplate)
      ? (_openBlock(), _createBlock(_component_UserAccordion, {
          key: 0,
          list: _ctx.externalUsers,
          sectionType: 'externalUsers',
          title: `Users from ${_ctx.clientName}`,
          onCreate: _cache[0] || (_cache[0] = (item) => _ctx.$emit('create', item)),
          onAddUser: _cache[1] || (_cache[1] = (item) => _ctx.$emit('addUser', item)),
          trashIcon: true,
          onAddPlaceholder: _cache[2] || (_cache[2] = (item) => _ctx.$emit('addPlaceholder', item)),
          onDeleteIcon: _ctx.removeExternal
        }, null, 8, ["list", "title", "onDeleteIcon"]))
      : _createCommentVNode("", true),
    (!_ctx.hidePlaceholder)
      ? (_openBlock(), _createBlock(_component_UserAccordion, {
          key: 1,
          list: _ctx.placeholders,
          sectionType: 'placeholders',
          title: 'Placeholder',
          onCreate: _cache[3] || (_cache[3] = (item) => _ctx.$emit('create', item)),
          onAddUser: _cache[4] || (_cache[4] = (item) => _ctx.$emit('addUser', item)),
          onDeleteIcon: _ctx.removePlaceholder,
          trashIcon: true,
          onAddPlaceholder: _cache[5] || (_cache[5] = (item) => _ctx.$emit('addPlaceholder', item))
        }, null, 8, ["list", "onDeleteIcon"]))
      : _createCommentVNode("", true),
    _createVNode(_component_UserAccordion, {
      list: _ctx.internalUsers,
      sectionType: 'internalUsers',
      title: `Users from ${_ctx.workspaceName}`,
      trashIcon: true,
      onCreate: _cache[6] || (_cache[6] = (item) => _ctx.$emit('create', item)),
      onAddUser: _cache[7] || (_cache[7] = (item) => _ctx.$emit('addUser', item)),
      onDeleteIcon: _ctx.removeInternal
    }, null, 8, ["list", "title", "onDeleteIcon"])
  ]))
}