
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
    components: {
    },
    props: {
        tmpBlock: {
            type: Object
        },
    },
    emits: ["submit", "addBlock"],
    data() {
        return {
            link: '',
            submitted: false,
        };
    },
    watch: {
        tmpBlock: {
            handler: function (val) {
                if (val) {
                    this.link = '';
                }
            },
            deep: true,
        },
    },
    computed: {
        type: function () {
            if (!this.tmpBlock ||
                !this.tmpBlock.typeConfig ||
                !this.tmpBlock.typeConfig.CALENDAR ||
                !this.tmpBlock.typeConfig.CALENDAR.type) {
                return '';
            }

            if (this.tmpBlock.typeConfig.CALENDAR.type === 'HUBSPOT') {
                return 'HubSpot';
            } else if (this.tmpBlock.typeConfig.CALENDAR.type === 'CALENDLY') {
                return 'Calendly';
            }
            return '';
        },
    },
    methods: {
        submit: async function () {
            this.submitted = true;

            const content = {
                moduleId: this.$route.params.moduleId.toString(),
                headline: `Schedule a ${this.type.toLowerCase()} meeting`,
                status: 'TODO',
                taskType: this.tmpBlock.type,
                typeConfig: {
                    CALENDAR: {
                        type: this.tmpBlock.typeConfig.CALENDAR.type,
                        link: this.link,
                    }
                }
            };

            store.commit('task/OPEN_TASK_SIDEBAR', content);
            await store.dispatch('task/addTask', { moduleId: this.$route.params.moduleId, payload: content });
            this.$emit('addBlock', { weight: this.tmpBlock.weight, content: { taskId: store.getters['task/getSelectedTask'].taskId } });

            this.submitted = false;
            const el = this.$refs["closeBtn"] as any;
            el.click();
        },
    },
});
