import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/auth/LoginView.vue'
import BlurredLoginPage from '../views/auth/blurred/BlurredLoginPage.vue'
import { Auth, Hub } from "aws-amplify";
import { store } from '@/store'
import settings from './settings'
import project from './project'
import tasks from './tasks'
import registration from './registration'
import module from './module'
import user from './user'
import BlurredNoPermissionPage from '@/views/auth/no-permission/BlurredNoPermissionPage.vue';

let timeout: ReturnType<typeof setTimeout>
function getUserSession() {
  return Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUserSession', data);
      return data;
    }
  }).catch(() => {
    store.commit('setUserSession', null);
    return null;
  });
}


export async function checkForValidSession() {
  const token = store.getters['getUserSession'];
  const isValid = token && token.signInUserSession && token.signInUserSession.idToken.payload.exp > Date.now() / 1000;
  if (!isValid) {
    const session = await Auth.currentSession();
    store.commit('setUserSignInToken', session);
  }
}

function refreshToken() {
  timeout = setTimeout(() => {
    Auth.currentSession()
      .then((session) => {
        store.commit('setUserSignInToken', session);
      })
      .catch(() => {
        store.commit('setUserSession', null);
        clearTimeout(timeout);
      })
  }, 15 * 60 * 1000)
}

Hub.listen("auth", async (data) => {
  switch (data.payload.event) {
    case "signIn":
      store.commit('setUserSession', data.payload.data);
      refreshToken();
      if (['v2.smace.io', 'eu.smace.io', 'localhost'].includes(window.location.hostname)) {
        router.push({ name: 'select-workspace' });
      } else {
        router.push({ name: 'projects' });
        store.dispatch("LOAD_BY_ORIGIN");
      }
      break;
    case "signOut":
      store.commit('setUserSession', null);
      clearTimeout(timeout);
      router.push({ name: 'login' });
      break;
  }
});

const routes: Array<RouteRecordRaw> = [
  ...settings,
  ...project,
  ...tasks,
  ...module,
  ...user,
  ...registration,
  ////////////
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      layout: 'full'
    }
  },
  {
    path: '/login-code',
    name: 'login-code',
    component: BlurredLoginPage,
    meta: {
      // layout: 'full'
    }
  },
  {
    path: '/no-permission',
    name: 'no-permission',
    component: BlurredNoPermissionPage,
    meta: {
      // layout: 'full'
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeResolve(async (to, from, next) => {
  await getUserSession();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.getJWT) {
      return next();
    }

    return next({
      path: '/login'
    });
  }
  return next();
});

export default router
