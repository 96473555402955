
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
    components: {},
    props: {
        selectedVar: {
            type: Object,
            required: true
        },
        id: {
            type: String,
            required: true
        },
    },
    data: () => {
        return {
            errMsg: '',
            label: '',
            name: '',
            value: '',
            type: '',
            creating: false,
        };
    },
    emits: ['cancel', 'updated'],
    mounted() {
        this.reset();
    },
    computed: {
        canStore: function () {
            return true;
        }
    },
    watch: {
        selectedVar: {
            handler: function () {
                this.reset();
            },
            immediate: true
        }
    },
    methods: {
        reset: function () {
            this.errMsg = '';
            this.value = this.selectedVar.value;
            this.label = this.selectedVar.label;
            this.type = this.selectedVar.type;
            this.name = this.selectedVar.name;
        },
        submit: async function () {
            this.creating = true;

            await store.dispatch('process/updateVariable', {
                id: this.id,
                name: this.name,
                value: this.value,
                label: this.label,
                type: this.type,
            }).then((res: any) => {
                this.$emit('updated', res);
                this.reset();
            }).catch((err: any) => {
                this.errMsg = err.message;
            });
            this.creating = false;
        }
    },
});
