
//@ts-disable
import { store } from "@/store";
import short from "short-uuid";
import { debounce } from "lodash";
import dateFormat from "dateformat";
import { defineComponent } from "vue";
import TaskJobModal from "./modals/TaskJobModal.vue";
import TaskBasicAddModal from "./modals/TaskBasicAddModal.vue";

import JobAddModal from "./modals/JobAddModal.vue";
import JobUpdateModal from "./modals/JobUpdateModal.vue";
import JobDeleteModal from "./modals/JobDeleteModal.vue";
import JobElementWrapper from "./JobElementWrapper.vue";
import TaskDeleteModal from "./modals/TaskDeleteModal.vue";
import TaskUpdateModal from "./modals/TaskUpdateModal.vue";
import ContextMenu from "@/views/components/ContextMenu.vue";
import ArrowIcon from "@/views/components/ArrowIcon.vue";
import UpdateProjectModal from "../index/modals/UpdateProjectModal.vue";
import DeleteProjectModal from "../index/modals/DeleteProjectModal.vue";
import HelpTooltip from "@/views/components/HelpTooltip.vue";
import ShareProjectModal from "./modals/ShareProjectModal.vue";

export default defineComponent({
  components: {
    TaskJobModal,
    TaskBasicAddModal,
    TaskDeleteModal,
    TaskUpdateModal,
    //
    JobAddModal,
    JobUpdateModal,
    JobDeleteModal,
    JobElementWrapper,
    //
    ContextMenu,
    ArrowIcon,
    //
    UpdateProjectModal,
    DeleteProjectModal,
    HelpTooltip,
    ShareProjectModal,
  },
  data: () => {
    return {
      selectedJobType: "",
      selectedJob: {},
      selectedTask: {},
    };
  },
  watch: {
    inActiveJobs: {
      handler(n) {
        // Delayed because of direct input changes through referenced objects
        store.dispatch("process/delayedUpdateInActiveJobs", n);
      },
      deep: true,
    },
    activeJobs: {
      handler(n) {
        // Delayed because of direct input changes through referenced objects
        store.dispatch("process/delayedUpdateActiveJobs", n);
      },
      deep: true,
    },
    doneJobs: {
      handler(n) {
        // Delayed because of direct input changes through referenced objects
        store.dispatch("process/delayedUpdateDoneJobs", n);
      },
      deep: true,
    },
  },
  computed: {
    project: () => {
      return store.getters["process/getProcess"];
    },
    doneJobs: {
      get() {
        return store.getters["process/getDoneJobs"].sort(
          (a: any, b: any) => a.weight - b.weight
        );
      },
      set(v: any) {
        // Non delayed because of none object referencing updates like drag&drop
        store.dispatch("process/updateDoneJobs", v);
      },
    },
    activeJobs: {
      get() {
        return store.getters["process/getActiveJobs"].sort(
          (a: any, b: any) => a.weight - b.weight
        );
      },
      set(v: any) {
        // Non delayed because of none object referencing updates like drag&drop
        store.dispatch("process/updateActiveJobs", v);
      },
    },
    inActiveJobs: {
      get() {
        return store.getters["process/getInActiveJobs"].sort(
          (a: any, b: any) => a.weight - b.weight
        );
      },
      set(v: any) {
        // Non delayed because of none object referencing updates like drag&drop
        store.dispatch("process/updateInActiveJobs", v);
      },
    },
  },
  async mounted() {
    this.load();
  },
  methods: {
    debounceStore: debounce(function (fnc) {
      fnc();
    }, 750),
    shortenUUID: function (projectId: string | any) {
      return short().fromUUID(String(projectId));
    },
    addTask: function (val: any) {
      store.dispatch("process/addTaskToJob", {
        type: this.selectedJobType,
        job: this.selectedJob,
        task: val,
      });
    },
    deleteTask: function () {
      store.dispatch("process/deleteTaskFromJob", {
        type: this.selectedJobType,
        job: this.selectedJob,
        task: this.selectedTask,
      });
    },
    updateTask: function (updatedTask: any) {
      store.dispatch("process/updateTaskFromJob", {
        type: this.selectedJobType,
        job: this.selectedJob,
        task: updatedTask,
      });
    },
    //
    setSelectedJob: function (data: any) {
      this.selectedJobType = data.selectedJobType;
      this.selectedJob = data.selectedJob;
    },
    setSelectedTask: function (task: any) {
      this.selectedTask = task;
    },
    //
    addJob: function (data: any) {
      store.dispatch("process/addJob", { type: this.selectedJobType, data });
    },
    updateJob: function (updatedJob: any) {
      store.dispatch("process/renameJob", {
        type: this.selectedJobType,
        job: updatedJob,
      });
    },
    deleteJob: function () {
      store.dispatch("process/deleteJob", {
        type: this.selectedJobType,
        job: this.selectedJob,
      });
    },
    ///
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy HH:MM");
    },
    load: async function () {
      store.dispatch("process/load", this.$route.params.projectId);
    },
  },
});
