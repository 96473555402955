import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import { getAll as getAllApi, update as updateModuleApi } from "@smace-technologies/modules/src/module";
import { getAll as getAllTasksApi } from "@smace-technologies/modules/src/task";
import { Module } from '@smace-technologies/modules/types/module';
import {
    getAll as getMilestonesApi,
    store as storeStepApi,
    remove as deleteStepApi,
    update as updateStepApi,
} from "@smace-technologies/process/src/step";
import { checkForValidSession } from '@/router';
import { Step } from '@smace-technologies/process/types/step';
import { Task } from '@smace-technologies/modules/types/task';

export const key: InjectionKey<Store<TState>> = Symbol()

type TModulesState = {
    modules: Module[];
    milestones: Step[];
    processId: string;
    typeId: string;
    loading: boolean;
    moduleTasksStats: any;
    selectedModule?: Module;
};

const initialState = (): TModulesState => ({
    modules: [] as Module[],
    milestones: [] as Step[],
    typeId: "",
    processId: "",
    loading: true,
    moduleTasksStats: {},
    selectedModule: undefined,
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getProcessId: (state: TModulesState) => {
            return state.processId;
        },
        getTypeId: (state: TModulesState) => {
            return state.typeId;
        },
        isLoading: (state: TModulesState) => {
            return state.loading;
        },
        getModules: (state: TModulesState) => {
            return state.modules;
        },
        getMilestones: (state: TModulesState) => {
            return state.milestones;
        },
        getModuleStats: (state: TModulesState) => (moduleId: string) => {
            return state.moduleTasksStats[moduleId] || {};
        },
        getTitle: (state: TModulesState) => (moduleId: string) => {
            return state.modules.find((m: Module) => m.moduleId == moduleId)?.title;
        },
        getSelectedModule: (state: TModulesState) => {
            return state.selectedModule;
        },
        getModulesByMilestones: (state: TModulesState) => {
            const group = {} as any
            const finalGroup = [] as any;
            if (!state.milestones.length) {
                return [];
            }

            state.modules
                .forEach((module: Module) => {
                    if (!group[module.stepId]) {
                        group[module.stepId] = {};
                    }
                    if (group[module.stepId] && !group[module.stepId]['modules']) {
                        group[module.stepId]['modules'] = [];
                    }
                    group[module.stepId]['modules'].push({
                        ...module,
                        name: '',
                        stats: {
                            doneModules: 0,
                            unDoneModules: 0,
                        }
                    });

                    group[module.stepId]['modules'] = group[module.stepId]['modules'].sort((a: Module, b: Module) => a.weight - b.weight);
                });

            state.milestones
                .forEach((milestone: Step) => {
                    if (!group[milestone.stepId]) {
                        group[milestone.stepId] = {};
                    }
                    group[milestone.stepId]['name'] = milestone.name;
                    group[milestone.stepId]['stepId'] = milestone.stepId;
                    group[milestone.stepId]['weight'] = milestone.weight;
                    group[milestone.stepId]['typeId'] = milestone.typeId;
                    group[milestone.stepId]['stats'] = {
                        doneModules: 0,
                        unDoneModules: 0,
                    };
                });

            Object.keys(group)
                .forEach((key) => {
                    finalGroup.push(group[key]);
                });

            return finalGroup.sort((a: any, b: any) => a.weight - b.weight);
        },
    },
    actions: {
        async load(context: ActionContext<TModulesState, TState>, data: { processId: string, typeId: string }) {
            context.commit('SET_LOADING', true);

            if (data.processId !== context.state.processId) {
                context.commit('RESET_STATE');
            }

            const p1 = getAllApi(
                store.getters["getBaseWorkspaceId"],
                data.processId,
                store.getters["getJWT"]
            ).then((res) => {
                context.dispatch('loadModuleTasks', { modules: res });
                context.commit('SET_MODULES', res)
            });

            const p2 = getMilestonesApi(
                store.getters["getBaseWorkspaceId"],
                data.typeId,
                store.getters["getJWT"]
            ).then((res) => context.commit('SET_MILESTONES', res));

            context.commit('SET_PROCESS_ID', data.processId);
            context.commit('SET_TYPE_ID', data.typeId);

            await Promise.all([p1, p2]);

            context.commit('SET_LOADING', false);
        },
        async loadModuleTasks(context: any, data: { modules: Module[] }) {
            const moduleTasksStats = {} as any;

            const promises = data.modules.map((module: Module) =>
                getAllTasksApi(
                    store.getters["getBaseWorkspaceId"],
                    module.moduleId,
                    store.getters["getJWT"]
                ).then((res) => buildModuleTasksStats(moduleTasksStats, res))
            );

            await Promise.all(promises);

            context.commit('SET_MODULE_TASKS_STATS', moduleTasksStats);

            ////

            function buildModuleTasksStats(moduleTasksStats: any, tasks: Task[]) {
                tasks.forEach((task: any) => {
                    if (!moduleTasksStats[task.moduleId]) {
                        moduleTasksStats[task.moduleId] = {
                            TOTAL: 0,
                            STATUS: {
                                TODO: 0,
                                IN_PROGRESS: 0,
                                STUCKED: 0,
                                DONE: 0,
                            }
                        };
                    }

                    moduleTasksStats[task.moduleId]['TOTAL']++;
                    moduleTasksStats[task.moduleId]['STATUS'][task.status]++;
                });
            }
        },
        async reloadModules(context: ActionContext<TModulesState, TState>) {
            await getAllApi(
                store.getters["getBaseWorkspaceId"],
                context.state.processId,
                store.getters["getJWT"]
            ).then((res) => context.commit('SET_MODULES', res));
        },
        async changeModuleStatus(context: ActionContext<TModulesState, TState>, module: Module) {
            await checkForValidSession();

            store.dispatch("module/updateModule", {
                ...module,
              });

            context.commit('SET_MODULE', {
                ...module,
            });
        },
        async activateModule(context: ActionContext<TModulesState, TState>, module: Module) {
            await checkForValidSession();

            store.dispatch("module/activateModule", {
                ...module,
            });

            context.commit('SET_MODULE', {
                ...module,
                status: "ACTIVE",
            });
        },
        async updateModules(context: ActionContext<TModulesState, TState>, data: { modules: [], stepId: string }) {
            await checkForValidSession();

            data.modules.forEach((element: Module, index: number) => {
                const newM = {
                    ...element,
                    stepId: data.stepId,
                    weight: index,
                };
                context.commit('SET_MODULE', newM);
                store.dispatch('module/updateModule', newM);
            });
        },
        async setSelectedModule(context: ActionContext<TModulesState, TState>, module: Module) {
            context.commit('SET_SELECTED_MODULE', module);
        },
        /// Milestones 
        async addMilestone(context: ActionContext<TModulesState, TState>, data: { typeId: string, afterWeight: number, name: string }) {
            await checkForValidSession();

            storeStepApi(
                store.getters.getBaseWorkspaceId,
                data.typeId,
                {
                    weight: data.afterWeight + 1,
                    name: data.name,
                },
                store.getters.getJWT
            ).then((res) => {
                context.commit('ADD_MILESTONE', res);
            });

            context.state.milestones.forEach((s: Step) => {
                if (s.weight > data.afterWeight) {
                    s.weight++;
                    context.dispatch('updateMilestone', { typeId: data.typeId, stepId: s.stepId, weight: s.weight, name: s.name });
                }
            });
        },
        async updateMilestonesWeight(context: ActionContext<TModulesState, TState>, steps: Step[]) {
            steps.forEach((s: Step, index: number) => {
                context.dispatch('updateMilestone', { typeId: s.typeId, stepId: s.stepId, weight: index, name: s.name });
            });
        },
        async updateMilestone(context: ActionContext<TModulesState, TState>, data: { typeId: string, stepId: string, weight: number, name: string }) {
            await checkForValidSession();

            updateStepApi(
                store.getters.getBaseWorkspaceId,
                data.typeId,
                data.stepId,
                {
                    name: data.name,
                    weight: data.weight,
                },
                store.getters.getJWT
            )

            context.commit('UPDATE_MILESTONE', data);
        },
        async deleteMilestone(context: ActionContext<TModulesState, TState>, data: { typeId: string, stepId: string }) {
            await checkForValidSession();

            deleteStepApi(
                store.getters.getBaseWorkspaceId,
                data.typeId,
                data.stepId,
                store.getters.getJWT
            )

            context.commit('DELETE_MILESTONE', data.stepId);
        }
    },
    mutations: {
        RESET_STATE(state: TModulesState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_MODULES(state: TModulesState, modules: Module[]) {
            state.modules = modules;
        },
        SET_LOADING(state: TModulesState, loading: boolean) {
            state.loading = loading;
        },
        SET_MODULE(state: TModulesState, module: Module) {
            state.modules = state.modules.map((m: Module) => m.moduleId == module.moduleId ? module : m);
        },
        SET_PROCESS_ID(state: TModulesState, processId: string) {
            state.processId = processId;
        },
        SET_TYPE_ID(state: TModulesState, typeId: string) {
            state.typeId = typeId;
        },
        SET_MILESTONES(state: TModulesState, milestones: Step[]) {
            state.milestones = milestones;
        },
        ADD_MILESTONE(state: TModulesState, milestone: Step) {
            state.milestones.push(milestone);
        },
        REMOVE_MILESTONE(state: TModulesState, milestone: Step) {
            state.milestones = state.milestones.filter((m: Step) => m.stepId !== milestone.stepId);
        },
        UPDATE_MILESTONE(state: TModulesState, milestone: Step) {
            state.milestones = state.milestones.map((m: Step) => m.stepId == milestone.stepId ? milestone : m);
        },
        DELETE_MILESTONE(state: TModulesState, stepId: string) {
            state.milestones = state.milestones.filter((milestone: Step) => milestone.stepId !== stepId);
        },
        SET_MODULE_TASKS_STATS(state: TModulesState, moduleTasksStats: any) {
            state.moduleTasksStats = moduleTasksStats;
        },
        SET_SELECTED_MODULE(state: TModulesState, module: Module) {
            state.selectedModule = module;
        },
    },
}
