
import { store } from "@/store";
import { defineComponent } from 'vue';
import LogoAndName from '../../components/LogoAndName.vue';

export default defineComponent({
    components: {
        LogoAndName
    },
    data() {
        return {
            loaded: false,
            userList: [
                {
                    firstName: "Ioahn",
                    lastName: "Will",
                    email: "ioahnwill@gmail.com",
                    avatar: require("@/assets/images/avatar.png"),
                    userId: "1",
                },
                {
                    firstName: "Aill",
                    lastName: "Ioahn",
                    email: "",
                    avatar: "",
                    userId: "3",
                },
                // {
                //     firstName: "",
                //     lastName: "",
                //     email: "willi@gmail.com",
                //     avatar: require("@/assets/images/avatar.png"),
                //     userId: "2",
                // },

            ]
        }
    },
    async mounted() {
        await store.dispatch("module/addModule", {
            stepId: this.$route.params.stepId,
            processId: this.$route.params.processId,
            projectId: this.$route.params.projectId,
            weight: -1
        });

        this.$router.replace({
            name: 'module', params: {
                moduleId: store.getters["module/getModule"].moduleId,
                processId: this.$route.params.processId
            }
        });
    },
});
