
import { defineComponent } from "vue";

export default defineComponent({
    components: {
    },
    props: {
        tmpBlock: {
            type: Object
        },
    },
    emits: ["submit"],
    data() {
        return {
            link: '',
            submitted: false,
            error: '',
            videoId: '',
        };
    },
    watch: {
        tmpBlock: {
            handler: function (val) {
                if (val) {
                    this.link = '';
                }
            },
            deep: true,
        },
    },
    methods: {
        validateUrl() {
            this.error = '';

            const regex = /(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
            const validateYoutubeUrl = this.link.match(regex);
            if (!validateYoutubeUrl) {
                this.error = 'This is not a valid Youtube Link. Please try again.';
                return;
            }

            this.videoId = validateYoutubeUrl[8];
            if (!this.videoId) {
                this.error = 'No Video ID found. Please try again.';
                return;
            }

            this.submit();
        },
        submit: async function () {
            this.submitted = true;

            this.$emit('submit', {
                type: 'video',
                weight: this.tmpBlock.weight,
                content: {
                    originalUrl: this.link,
                    embedUrl: `https://www.youtube.com/embed/${this.videoId}`,
                    providerName: 'YouTube',
                },
            });

            this.submitted = false;
            const el = this.$refs["closeBtn"] as any;
            el.click();
        },
    },
});
