
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    stepId: {
      type: String,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
    };
  },
  computed: {
    step: function () {
      return this.stepId ? store.getters["processModules/getMilestones"].find((s: any) => s.stepId === this.stepId) : null;
    },
  },
  watch: {
    step: function () {
      this.name = this.step?.name;
    }
  },
  mounted() {
    this.name = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;

      const res = await store.dispatch("processModules/updateMilestone", { name: this.name, weight: this.step.weight, stepId: this.stepId, typeId: this.step.typeId });

      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;

      el.click();
      this.$emit("reload");
    },
  },
});
