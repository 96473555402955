import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e8db93a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block block-video mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "video-container"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.computedContent && _ctx.computedContent.embedUrl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("iframe", {
            width: "560",
            height: "315",
            src: _ctx.computedContent.embedUrl,
            title: "YouTube video player",
            frameborder: "0",
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
            allowfullscreen: ""
          }, "\n            ", 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true)
  ]))
}