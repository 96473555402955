
import { defineComponent } from "vue";
import LogoAndName from '@/views/components/LogoAndName.vue';
import { store } from '@/store';
import { Task } from "@smace-technologies/modules/types/task";
import UserSelection from "@/views/components/UserSelection/UserSelection.vue";
import SelectDate from "../blocks/components/task/SelectDate.vue";
import TaskStatusSelect from "./components/task/TaskStatusSelect.vue";

export default defineComponent({
    components: {
        LogoAndName,
        UserSelection,
        SelectDate,
        TaskStatusSelect,
    },
    props: {
        content: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            selectedUsers: [] as string[],
            dueDateEdit: false,
        }
    },
    computed: {
        task(): any {
            return store.getters['tasks/getTasks'].find((task: Task) => task.taskId === this.content.taskId);
        },
        formattedDueDate(): string {
            return this.computedDueDate ? new Date(this.computedDueDate).toLocaleDateString() : 'Set Due Date';
        },
        computedAccountablePerson: {
            get(): string[] {
                return [this.task.assignee];
            },
            set(v: any) {
                store.dispatch('task/updateTask', {
                    moduleId: this.task.moduleId,
                    taskId: this.task.taskId,
                    payload: {
                    ...this.task,
                    assignee: v[0]
                    }
                });
            },
        },
        computedDueDate: {
            get(): number {
                return this.task.dueDate;
            },
            set(v: any) {
                store.dispatch('task/updateTask', {
                    moduleId: this.task.moduleId,
                    taskId: this.task.taskId,
                    payload: {
                        ...this.task,
                        dueDate: v
                    },
                });
                this.dueDateEdit = false;
            },
        },
        computedStatus: {
            get(): any {
                return this.task.status;
            },
            set(v: any) {
                store.dispatch('task/updateTask', {
                    moduleId: this.task.moduleId,
                    taskId: this.task.taskId,
                    payload: {
                        ...this.task,
                        status: v
                    },
                });
            },
        }
    },
    methods: {
        openTaskSidebar(task: any) {
            store.commit('task/OPEN_TASK_SIDEBAR', task);
            const el = this.$refs["openModal"] as any;
            el.click();
        },
    }
});
