
import { defineComponent } from 'vue';
import ModuleShow from './ModuleShow.vue';
import { store } from '@/store';

export default defineComponent({
    components: {
        ModuleShow
    },
    computed: {
        activities() {
            return store.getters['activities/getActivitiesByModule'];
        },
    },
    methods: {
        formatDate(date: number) {
            return new Date(date).toLocaleString();
        },
        formatStatus(status: string) {
            return status;
            // return status.replace('_', ' ').toLocaleUpperCase();
        },
        openTaskSidebar(task: any) {
            store.commit('task/OPEN_TASK_SIDEBAR', task);
            const el = this.$refs["openModal"] as any;
            el.click();
        },
    },
});
