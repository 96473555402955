
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  emits: ["added", "update:type"],
  data() {
    return {
      submitted: false,
      name: "",
    };
  },
  computed: {
    computedType: {
      get() {
        return this.type;
      },
      set(v: any) {
        this.$emit("update:type", v)
      }
    },
  },
  watch: {},
  methods: {
    submit: async function () {
      this.submitted = true;

      const res = await store.dispatch("settingsRoles/add", { name: this.name, internExtern: this.computedType });

      this.submitted = false;
      this.name = "";

      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
});
