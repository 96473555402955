import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { ActionContext, Store } from 'vuex';
import { store } from '@/store';
import { getAllByWorkspace as getAllApi, store as storeApi } from "@smace-technologies/process/src/process";
import { update as updateApi, remove as removeApi } from "@smace-technologies/process/src/process";
import { Process } from '@smace-technologies/process/types/process';
import { Module } from '@smace-technologies/modules/types/module';

export const key: InjectionKey<Store<TState>> = Symbol()

type TProcessesState = {
    processes: Process[];
    singleModules: Module[];
};

const initialState = (): TProcessesState => ({
    processes: [] as Process[],
    singleModules: [] as Module[],
});
export default {
    namespaced: true,
    state: {
        ...initialState(),
    },
    getters: {
        getProcesses: (state: TProcessesState) => {
            return state.processes;
        },
        getSingleModules: (state: TProcessesState) => {
            return state.singleModules;
        },
    },
    actions: {
        async load(context: ActionContext<TProcessesState, TState>) {
            if (!store.getters["getJWT"]) return;
            const processes = await getAllApi(
                store.getters["getBaseWorkspaceId"],
                store.getters["getJWT"]
            );

            context.commit('SET_PROCESSES', processes);
        },
        async add(context: ActionContext<TProcessesState, TState>, projectId: string): Promise<Process> {
            const process = await storeApi(
                store.getters["getBaseWorkspaceId"],
                projectId,
                store.getters["getJWT"]
            );

            context.commit('ADD_PROCESSES', process);
            return process;
        },
        async delete(context: ActionContext<TProcessesState, TState>, processId: string) {
            await removeApi(
                store.getters["getBaseWorkspaceId"],
                processId,
                store.getters["getJWT"]
            );

            context.commit('DELETE_PROCESSES', processId);
        },
        async rename(context: ActionContext<TProcessesState, TState>, data: { projectId: string, processId: string, name: string }) {
            const processes = await updateApi(
                store.getters["getBaseWorkspaceId"],
                data.projectId,
                data.processId,
                {
                    name: data.name
                },
                store.getters["getJWT"]
            );

            context.commit('RENAME_PROCESSES', processes);
        },
    },
    mutations: {
        RESET_STATE(state: TProcessesState) {
            const initial = initialState();
            Object.assign(state, initial);
        },
        SET_PROCESSES(state: TProcessesState, processes: Process[]) {
            state.processes = processes;
        },
        ADD_PROCESSES(state: TProcessesState, process: Process) {
            state.processes.push(process);
        },
        RENAME_PROCESSES(state: TProcessesState, process: Process) {
            state.processes = state.processes.map((f: Process) => {
                if (f.processId === process.processId) {
                    f = process;
                }
                return f;
            });
        },
        DELETE_PROCESSES(state: TProcessesState, processId: string) {
            state.processes = state.processes.filter((f: Process) => f.processId !== processId);
        },
    },
}
