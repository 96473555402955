
//@ts-disable
import { store } from "@/store";
import dateFormat from "dateformat";
import { defineComponent } from "vue";
import draggable from "vuedraggable";
import TaskListProgressLine from "./TaskListProgressLine.vue";
import TasksElement from "@/views/pages/settings/processes/show/taskList/TasksElement.vue";

export default defineComponent({
  components: {
    TaskListProgressLine,
    TasksElement,
    draggable,
  },
  data: () => {
    return {
      selectedModule: {} as any
    };
  },
  watch: {
  },
  computed: {
    statusList: () => {
      return store.getters["processTasks/getStatusList"];
    },
    isReadOnly: () => {
      return store.getters["process/getProject"].projectId === 'TEMPLATE';
    },
    getProcessId: () => {
      return store.getters["process/getProcessId"];
    },
  },
  methods: {
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy HH:MM");
    },
    load: async function () {
      if (this.$route.name === 'settings-process') {
        store.dispatch("processTasks/load", { processId: this.$route.params.typeId });
      } else if (this.$route.name === 'process-show') {
        store.dispatch("processTasks/load", { processId: this.$route.params.processId });
      }
    },
  },
  async mounted() {
    this.load();
  },
});
