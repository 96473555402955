import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_feather_icon = _resolveComponent("feather-icon")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createBlock(_component_VueDatePicker, {
    modelValue: _ctx.date,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.date) = $event)),
    "enable-time-picker": false,
    placeholder: "Select Deadline",
    "input-class-name": "dp-custom-input",
    format: _ctx.format,
    "menu-class-name": "dp-custom-menu",
    "calendar-cell-class-name": "dp-custom-cell",
    "hide-input-icon": _ctx.hideInputIcon,
    ref: "datepicker"
  }, {
    "clear-icon": _withCtx(({ clear }) => [
      _createVNode(_component_feather_icon, {
        onClick: ($event: any) => (clear(), _ctx.$emit('clearDate')),
        type: "x",
        class: "clear-btn d-flex align-items-center",
        size: "15",
        stroke: "#63687E"
      }, null, 8, ["onClick"])
    ]),
    "action-buttons": _withCtx(() => [
      _createElementVNode("button", {
        class: "custom-cancel btn me-2",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelSelection && _ctx.cancelSelection(...args)))
      }, "Cancel"),
      _createElementVNode("button", {
        class: "custom-select btn btn-primary",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectDate && _ctx.selectDate(...args)))
      }, "Select")
    ]),
    _: 1
  }, 8, ["modelValue", "format", "hide-input-icon"]))
}