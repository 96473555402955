
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    formId: {
      type: String,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
    };
  },
  computed: {
    form: function () {
      return store.getters["forms/getForms"].find((f: any) => f.formId === this.formId)
    },
  },
  mounted() {
    this.name = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      
      store.dispatch("forms/clone", {formId: this.form.formId, name: this.name});

      this.name = "";
      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$emit("reload");
    },
  },
});
