import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16288aff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "blocks" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  type: "button",
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#taskSidebar",
  ref: "openModal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_module_show = _resolveComponent("module-show")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_module_show, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activities, (activity) => {
            return (_openBlock(), _createElementBlock("div", {
              key: activity.id
            }, [
              (activity.changes.length > 0 && activity.changes[0].type === 'modified')
                ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _withModifiers(($event: any) => (_ctx.openTaskSidebar(activity)), ["prevent"])
                    }, _toDisplayString(activity.headline), 9, _hoisted_3),
                    _createTextVNode(": " + _toDisplayString(activity.updatedBy) + " changed " + _toDisplayString(_ctx.formatStatus(activity.changes[0].field)) + " from ", 1),
                    _createElementVNode("span", {
                      class: _normalizeClass([ 'status status-' + activity.changes[0].oldValue ])
                    }, _toDisplayString(_ctx.formatStatus(activity.changes[0].oldValue)), 3),
                    _createTextVNode(" to "),
                    _createElementVNode("span", {
                      class: _normalizeClass([ 'status status-' + activity.changes[0].newValue ])
                    }, _toDisplayString(_ctx.formatStatus(activity.changes[0].newValue)), 3),
                    _createTextVNode(" at " + _toDisplayString(_ctx.formatDate(activity.timestamp)), 1)
                  ]))
                : _createCommentVNode("", true),
              (activity.changes.length > 0 && activity.changes[0].type === 'created')
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(activity.updatedBy) + " created Task " + _toDisplayString(activity.headline) + " at " + _toDisplayString(_ctx.formatDate(activity.timestamp)), 1))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("button", _hoisted_5, "Open", 512), [
      [_vShow, false]
    ])
  ], 64))
}