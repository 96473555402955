import { Task } from "@smace-technologies/modules/types/task";
import { store } from "@/store";
import { getAll } from "@smace-technologies/modules/src/task";
import { update as updateAPI } from "@smace-technologies/modules/src/task";
import { TState } from "@/types/TState";
import { ActionContext } from "vuex";

type TTaskState = {
  tasks: Task[];
};

const initialState = (): TTaskState => ({
  tasks: [] as Task[],
});

export default {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: {
    getTasks: (state: TTaskState) => {
      return state.tasks.sort((a: Task, b: Task) => {
        return a.weight - b.weight;
      });
    },
    getTaskStats: (state: TTaskState) => {
      return {
        totalTasks: state.tasks.length,
        completedTasks: state.tasks.filter((t: Task) => t.status === 'DONE').length,
        minDuration: "",
      }
    },
  },
  actions: {
    async updateTask(context: ActionContext<TTaskState, TState>, data: { moduleId: string; taskId: string, payload: Task }) {
      const payload = {
        taskType: data.payload.taskType,
        headline: data.payload.headline,
        description: data.payload.description,
        weight: data.payload.weight,
        status: data.payload.status,
        typeConfig: data.payload.typeConfig,
        assignee: data.payload.assignee || '',
        followers: data.payload.followers || [],
        dueDate: data.payload.dueDate || 0,
        dueDateConfig: data.payload.dueDateConfig || {},
      };
      context.commit('UPDATE_TASK', { taskId: data.taskId, ...payload });
      await updateAPI(store.getters["getBaseWorkspaceId"], data.moduleId, data.taskId, payload, store.getters["getJWT"]);
    },
    async load(context: ActionContext<TTaskState, TState>, data: { moduleId: string; processId: string }) {
      const tasks = await getAll(
        store.getters["getBaseWorkspaceId"],
        data.moduleId,
        store.getters["getJWT"],
      );
      const sortedTasks = tasks.sort((a: Task, b: Task) => {
        return a.weight - b.weight;
      });
      context.commit("SET_TASKS", sortedTasks);
    },
    async getTasksByClientAndUser(
      context: ActionContext<TTaskState, TState>,
      data: { clientId: string; modules: string[] },
    ) {
      const promises = [];
      for (const moduleId of data.modules) {
        promises.push(
          getAll(
            store.getters["getBaseWorkspaceId"],
            moduleId,
            store.getters["getJWT"],
          ),
        );
      }
      const tasks = await Promise.all(promises);
      context.commit("SET_TASKS", tasks.flat());
    },
    async updateAllWeights(context: ActionContext<TTaskState, TState>, tasks: Task[]) {
      const promises = tasks.map((task: Task, index: number) => {
        return context.dispatch('updateTask', {
          moduleId: task.moduleId,
          taskId: task.taskId,
          payload: {
            ...task,
            weight: index
          },
        });
      });

      await Promise.all(promises);
    },
  },
  mutations: {
    SET_TASKS(state: TTaskState, tasks: Task[]) {
      state.tasks = tasks;
    },
    RESET_STATE(state: TTaskState) {
      const initial = initialState();
      Object.assign(state, initial);
    },
    UPDATE_TASK(state: TTaskState, data: Task) {
      state.tasks = state.tasks.map((task: Task) => {
        if (task.taskId === data.taskId) {
          return {
            ...task,
            ...data,
          };
        }
        return task;
      });
    }
  },
};
