import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/info-icon-2.svg'


const _withScopeId = n => (_pushScopeId("data-v-58263082"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-line-wrapper d-flex flex-column" }
const _hoisted_2 = { class: "header-and-actions w-100 d-flex justify-content-between align-items-center position-relative" }
const _hoisted_3 = { class: "header d-flex align-items-center" }
const _hoisted_4 = { class: "me-1" }
const _hoisted_5 = {
  key: 0,
  class: "info-icon cursor-pointer d-none",
  src: _imports_0,
  alt: "info-icon"
}
const _hoisted_6 = {
  key: 0,
  class: "actions d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.stepName), 1),
        false
          ? (_openBlock(), _createElementBlock("img", _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isTemplateBuilder)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6))
        : _createCommentVNode("", true)
    ])
  ]))
}