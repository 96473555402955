
import { defineComponent } from 'vue';
import CreateTaskSection from './CreateTaskSection.vue';
import { store } from '@/store';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
  components: {
    CreateTaskSection,
  },
  props: {
    renderKey: {
      type: Number,
    },
  },
  methods: {
    createTask(payload: any) {
        const taskContent = {
            headline: 'My New Task',
            taskId: uuidv4(),
            taskType: payload.taskType,
            status: 'TODO',
        }
        store.dispatch('task/addTask', { moduleId: this.$route.params.moduleId, payload: { ...payload, ...taskContent }});
        
        const closeButton = this.$refs["closeSelectTaskTypeModal"] as any;
        closeButton.click();
        
        const el = this.$refs["openTaskSidebar"] as any;
        el.click();
    },
  },
});
