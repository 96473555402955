
import NavBar from "./components/NavBar.vue";
import SidebarMenu from "./components/SidebarMenu.vue";
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {
    SidebarMenu,
    NavBar
  },
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    isLoggedIn: function () {
      return store.getters.getUserSession;
    },
  },
  methods: {
  },
});
