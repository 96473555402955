
import { defineComponent } from "vue";
import MarkAsButton from "@/views/components/MarkAsButton.vue";
import { store } from "@/store";
import { getFormViewerUrl, getDemoFormViewerUrl } from "@/services/serviceUrls";

export default defineComponent({
  components: {
    MarkAsButton
  },
  props: {
    blockId: {
      type: String,
    },
    moduleId: {
      type: String,
    },
    formId: {
      type: String,
    },
    config: {
      type: Object,
    },
    isReadOnly: {
      type: Boolean,
    }
  },
  emits: ["updateStatus"],
  data() {
    return {
      iframeHeight: "400",
      formIsEmpty: false,
      loading: true,
    };
  },
  computed: {
    isLocal: function () {
      return document.location.hostname === "localhost";
    },
    iframeLink: function () {
      return this.isLocal ? this.demoLink : getFormViewerUrl(store.getters["getBaseWorkspaceId"], store.getters["process/getProjectId"], this.formId, this.moduleId, this.blockId, this.config?.getParams);
    },
    demoLink: function () {
      return getDemoFormViewerUrl();
    },
  },
  watch: {
  },
  methods: {
    receiveMessage(event: any): void {
      if (event.data.origin != this.iframeLink) {
        return;
      }

      if (this.loading) {
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }

      if (event.data.type === "EMPTY_FORM") {
        this.formIsEmpty = true;
      } else if (event.data.type === "FORM_HEIGHT") {
        this.formIsEmpty = false;
        this.iframeHeight = event.data.height + 10;
      }
    },
  },
  mounted() {
    window.addEventListener("message", this.receiveMessage, false);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.receiveMessage);
  },
});
