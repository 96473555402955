
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    formId: {
      type: String,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      formName: "",
      name: "",
    };
  },
  computed: {
    form: function () {
      return store.getters["forms/getForms"].find((f: any) => f.formId === this.formId)
    },
  },
  watch: {
    form: function () {
      this.formName = this.form?.name;
    }
  },
  mounted() {
    this.name = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;

      store.dispatch("forms/delete", this.formId);

      this.submitted = false;
      this.name = "";
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$emit("reload");
    },
  },
});
