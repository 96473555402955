
import { defineComponent } from "vue";
import TaskFormViewer from "../blocks/components/task/TaskFormViewer.vue";
import DetailsBlock from "./DetailsBlock.vue";
import { store } from "@/store";
import { mapState } from 'vuex';
import CalendlyView from "../blocks/components/task/CalendlyView.vue";
import HubspotMeetingView from "../blocks/components/task/HubspotMeetingView.vue";
import SettingsBlockV2 from "./SettingsBlockV2.vue";
import WYSIWYGEditor from "../WYSIWYGEditor.vue";

export default defineComponent({
  components: {
    TaskFormViewer,
    CalendlyView,
    HubspotMeetingView,
    DetailsBlock,
    SettingsBlockV2,
    WYSIWYGEditor,
  },
  props: {
    renderKey: {
      type: Number,
    },
  },
  methods: {
    update(value: any) {
      store.dispatch('task/updateTask', {
        moduleId: this.selectedTask.moduleId,
        taskId: this.selectedTask.taskId,
        payload: value
      })
    },
    markAsDone() {
      store.dispatch('task/updateTask', {
        moduleId: this.selectedTask.moduleId,
        taskId: this.selectedTask.taskId,
        payload: {
          ...this.selectedTask,
          status: 'DONE',
        }
      });
    },
    updateStatus(status: string) {
      store.dispatch('task/updateTask', {
        moduleId: this.selectedTask.moduleId,
        taskId: this.selectedTask.taskId,
        payload: {
          ...this.selectedTask,
          status: status,
        }
      })
    },
    goToContext() {
      this.$router.push({
        name: 'moduleContext',
        params: {
          moduleId: this.selectedTask.moduleId,
          processId: this.selectedTask.processId,
        },
        hash: `#${this.selectedTask.taskId}`,
      });
    },
    adjustHeight() {
      this.$nextTick(() => {
        const textarea = this.$refs.headlineTextarea as HTMLTextAreaElement;
        textarea.style.height = '0';
        textarea.style.height = textarea.scrollHeight + 'px';
      });
    },
  },
  computed: {
    ...mapState('task', ['isTaskSidebarOpen', 'selectedTask']),
    computedHeadline: {
      get(): string {
        return this.selectedTask.headline
      },
      set(v: any) {
        this.update({
          ...this.selectedTask,
          headline: v
        });
      },
    },
    computedDescription: {
      get(): string {
        return this.selectedTask.description
      },
      set(v: any) {
        this.update({
          ...this.selectedTask,
          description: v
        });
      },
    },
    hasTaskContext(): boolean {
      return store.getters['moduleBlocks/getBlocks'].some((b: any) => b.content?.taskId === this.selectedTask.taskId);
    },
    isReadOnly: () => {
      return store.getters["process/getProject"].projectId !== 'TEMPLATE';
    },
  },
  watch: {
    computedHeadline() {
      this.adjustHeight();
    }
  },
  mounted() {
    this.adjustHeight();
  },
});
