
import { store } from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
    },
    data() {
        return {
            showCreateForm: false,
            creating: false,
            newClientName: null,
            selectedClient: "",
        };
    },
    computed: {
        clientList: function () {
            return store.getters["clients/getClients"];
        },
        canNext: function (): boolean {
            return this.selectedClient || this.newClientName;
        },
    },
    watch: {
        selectedClient: function () {
            if (this.selectedClient === 'add') return;

            store.dispatch("client/load", this.selectedClient);
            this.$emit('update', {
                clientId: this.selectedClient,
                name: this.newClientName,
            });
        }
    },
    methods: {
        createClient: async function () {
            if (!this.newClientName) return;

            this.creating = true;
            const newClient = await store.dispatch("clients/add", { name: this.newClientName });
            this.selectedClient = newClient.clientId;
            this.showCreateForm = false;
            this.creating = false;
            this.newClientName = "";
        },
        onChange: async function () {
            if (this.selectedClient === 'add') {
                this.showCreateForm = true;
            }
        },
        load: async function () {
            await store.dispatch("clients/load");
        },
    },
    async mounted() {
        this.load();
    },
});
